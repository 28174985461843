import PropTypes from 'prop-types';

const ToggleInput = ({
  classes,
  labelClass,
  disabledClass,
  label,
  name,
  value,
  disabled,
  changeHandler,
  isAddEdit,
  labelColor = false,
  icon = false, // New prop for the icon
  curDeviceStatus = false,
  transparent = false,
  ...props
}) => {
  return (
    <div className={`${transparent && 'toggle-field-wrap-transparent'} toggle-field-wrap ${classes ? classes : ''}`}>
      <div
        className={`toggle-field-container${isAddEdit ? ' add-edit-item' : ''}`}
      >
        {label && (
          <div className="toggle-field-label-container">
            <label
              className={`toggle-field-label ${
                labelColor ? 'firmware-label' : ''
              } ${labelClass ? labelClass : ''}`}
            >
              {label}
            </label>
          </div>
        )}
        {icon ? (
          <div className="toggle-input-with-icon">
            {icon && <span className="toggle-icon-edit">{icon}</span>}{' '}
            {/* Display the icon */}
            <label className="toggle-switch">
              <input
                type="checkbox"
                name={name}
                value={value}
                checked={!!value}
                onChange={changeHandler}
                disabled={disabled}
              />
              <span className={`${(curDeviceStatus && curDeviceStatus !== 'Online' && value) ? 'toggle-slider-disabled-working':'toggle-slider'} round ${disabledClass}`}></span>
            </label>
          </div>
        ) : (
          <label className="toggle-switch">
            <input
              type="checkbox"
              name={name}
              value={value}
              checked={!!value}
              onChange={changeHandler}
              disabled={disabled}
            />
            <span className={`${(curDeviceStatus && curDeviceStatus !== 'Online' && value) ? 'toggle-slider-disabled-working':'toggle-slider'} round ${disabledClass}`}></span>
          </label>
        )}
      </div>
    </div>
  );
};

ToggleInput.propTypes = {
  label: PropTypes.node,
  description: PropTypes.node,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.node, // Add prop types for the icon
};

export default ToggleInput;
