import { useState } from 'react';
import {Col, Row } from 'react-bootstrap';
import { constants, Utils } from '../../../../helpers';
import React from 'react';
import { PrimaryButton } from '../../../../components/common';
import publishDeviceSetting from '../shared/PublishDeviceSettings';
import {InputSlider} from './InputSlider';
import './FocusSettingBlock.scss';

const FocusSettingBlock = ({
  deviceInfos,
  devicePublishId,
  accountId,
  uuid,
  deviceId,
  hasManagePermission,
}) => {
  const [currentFocus, setCurrentFocus] = useState(1);
  const [currentZoom, setcurrentZoom] = useState(1);
  const capabilityList = deviceInfos?.capDetails?.capabilities;
  const deviceState = deviceInfos?.deviceStatus;
  const connectionState = deviceInfos?.connectionStatus;
  const curDeviceStatus = Utils.getDeviceStatus(deviceState, connectionState);
  const canChangeSettings =
    curDeviceStatus === constants.DEVICES_RETURN_ONLINE_STATUS &&
    hasManagePermission;
  const resourceList = Utils.getDeviceSettingResource(2);
  const capFocus = Utils.getCurrentTabProperty(capabilityList, resourceList[0]);

  const publishPayload = {
    deviceId: deviceId,
    devicePublishId: devicePublishId,
    uuid: uuid,
    accountId: accountId,
    hubId: deviceInfos.gatewayId,
  };
  
  return (
    <div
      className={`text-start audio-settings-block ${
        curDeviceStatus !== constants.DEVICES_RETURN_ONLINE_STATUS
          ? ' editable-off-offline'
          : ''
      }`}
    >
      <div className="tab-title focus-tab">
        <label>{constants.DEVICES_TAB_FOCUS_SETTINGS}</label>
      </div>

      {Utils.getPropertyShowStatus(
        curDeviceStatus,
        'focus-control',
        capFocus
      ) ||
      Utils.getPropertyShowStatus(curDeviceStatus, 'zoom-control', capFocus) ? (
        <div className="tab-sub-title">
          {constants.DEVICES_TAB_FOCUS_SETTINGS_SUB_TITLE}
        </div>
      ) : null}
      <Row className="settings-block">
        {Utils.getPropertyShowStatus(
          curDeviceStatus,
          'focus-control',
          capFocus
        ) && (
          <Col md={12} className='mt-3'>
            <InputSlider
              label={constants.DEVICES_TAB_FOCUS}
              fieldName="focus-control"
              maxValue={parseInt(capFocus?.['focus-control'][capFocus?.['focus-control'].length-1])}
              minValue={parseInt(capFocus?.['focus-control'][0])}
              minValueLabel={'Far'}
              maxValueLabel={'Near'}
              currentValue={parseInt(currentFocus)}
              isErrorMessage={false}
              changeHandler={(value) => {
                setCurrentFocus(value);
                publishDeviceSetting(
                  {'focus-control': value},
                  resourceList[0],
                  publishPayload
                );
              }}
              disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
              disabled={!canChangeSettings}
            />
          </Col>
        )}

        {Utils.getPropertyShowStatus(
          curDeviceStatus,
          'zoom-control',
          capFocus
        ) && (
          <Col md={12} className='mt-3'>
            <InputSlider
              label={constants.DEVICES_TAB_FOCUS_ZOOM_TITLE}
              fieldName="zoom-control"
              maxValue={parseInt(capFocus?.['zoom-control'][capFocus?.['zoom-control'].length-1])}
              minValue={parseInt(capFocus?.['zoom-control'][0])}
              minValueLabel={'Out'}
              maxValueLabel={'In'}
              currentValue={parseInt(currentZoom)}
              isErrorMessage={false}
              changeHandler={(value) => {
                setcurrentZoom(value);
                publishDeviceSetting(
                  {'zoom-control': value},
                  resourceList[0],
                  publishPayload
                );
              }}
              disabledClass={!canChangeSettings ? 'cursor-auto' : ''}
              disabled={!canChangeSettings}
            />
          </Col>
        )}
      </Row>
      {Utils.getPropertyShowStatus(
        curDeviceStatus,
        'simple-focus-enable',
        capFocus
      ) && (
        <PrimaryButton
          className="btn btn-primary mt-3"
          type="button"
          width="100%"
          height="56px"
          fontSize="18px"
          lineHeight="24px"
          disabledColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--hGrayscale_32')}
          disabledBackgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--hGreyscale_88')}
          onClick={() => {
            const settingFocus = {
              'simple-focus-enable': constants.PROPERTY_STATUS_TRUE,
            };
            publishDeviceSetting(settingFocus, resourceList[0], publishPayload);
          }}
          disabled={!canChangeSettings}
        >
          {constants.DEVICES_SIMPLE_FOCUS}
        </PrimaryButton>
      )}
      {Utils.getPropertyShowStatus(
        curDeviceStatus,
        'focus-enable',
        capFocus
      ) && (
        <PrimaryButton
          className="btn btn-primary-outline mt-3"
          type="button"
          width="100%"
          borderWidth="1.5px"
          hoverBorderWidth="1.5px"
          disabledColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--text_color_event')}
          disabledBorderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--hGreyscale_72')}
          disabledBackgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('#ffffff')}
          hoverBorderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary_40')}
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--primary_40'
          )}
          hoverColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--brand_white')}
          hoverBackgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary_40')}
          backgroundColor="transparent"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          onClick={() => {
            const settingFocus = {
              'focus-enable': constants.PROPERTY_STATUS_TRUE,
            };
            publishDeviceSetting(settingFocus, resourceList[0], publishPayload);
          }}
          disabled={!canChangeSettings}
        >
          {constants.DEVICES_SIMPLE_INITIALIZE}
        </PrimaryButton>
      )}
    </div>
  );
};

export default FocusSettingBlock;
