import { useEffect, useState, useRef, useCallback } from 'react';
import { Alert } from 'react-bootstrap';
import { useKeycloak } from '@react-keycloak/web';
import axios from 'axios';
import { Header, SiteSpinner } from '../../components/common/';
import { constants, Utils, roles, AppDefaults } from '../../helpers/';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPolicies,
  updatePolicies,
} from '../../store/reducers/PoliciesReducer';
import {
  updateUserData,
  setAccountId,
  getAccountId,
} from '../../store/reducers/AccountReducer';
import PageWrapper from '../../pages/PageWrapper';
import { useOrganizations } from '../../store/OrganizationsStore';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { usePoliciesStore } from '../../store/policiesStore';
import DefaultPolicies from '../../store/reducers/DefaultPolicies';
import { CustomerLandingPage, PartnerLandingPage } from './';
import { GoInfo } from 'react-icons/go';
import './ManageOrganizations.scss';
import {
  checkMQTTConnection,
  connectWithMQTT,
  subscribeWithOrgIds,
} from '../../utils/connection/mqttConnection';
import {
  resetCDNInfo,
  setPlatformInfo,
} from '../../store/reducers/StreamingReducer';
import { resetAccount } from '../../store/reducers/AccountReducer';
import {
  resetStreaming,
  resetPlatformInfo,
} from '../../store/reducers/StreamingReducer';
import { resetPolicies } from '../../store/reducers/PoliciesReducer';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import { useCustomerOrgUsers } from '../../store/CustomerOrgUsersStore';
import { usePartnerOrgUsers } from '../../store/PartnerOrgUsersStore';
// import { useCustomerOrgDevices } from '../../store/CustomerOrgDevicesStore';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { useDeviceSnapshots } from '../../store/DeviceSnapshotsStore';
import {
  AmplitudeEventEnums,
  AmplitudeEventPropertyEnums,
} from '../../helpers/amplitudeEventEnums';
import { resetCameraWallDevices } from '../../store/CustomerOrgDevicesStoreIDB';
import {
  setLoggedInUserRole,
  setPartnerOrgData,
  setSelfAccounData,
  setSelfStatus,
  setOrgInfo
} from '../../store/reducers/OrganizationsReducer';
import {
  getCustomerOrgData,
  getOrganizations,
  setCustomerOrgData,
} from '../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import { resetViewsList } from '../../store/reducers/ViewsReducer';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';

function ManageOrganizations() {
  const resetAllUserPolicies = usePoliciesStore(
    (state) => state.resetAllUserPolicies
  );
  const resetCustomerOrgLocations = useCustomerOrgLocations(
    (state) => state.resetCustomerOrgLocations
  );
  const resetDeviceSnapshots = useDeviceSnapshots(
    (state) => state.resetDeviceSnapshots
  );
  const resetCustomerOrgUsers = useCustomerOrgUsers(
    (state) => state.resetCustomerOrgUsers
  );
  // const resetAllCustomerOrgDevices = useCustomerOrgDevices(
  //   (state) => state.resetAllCustomerOrgDevices
  // );
  const resetPartnerOrgUsers = usePartnerOrgUsers(
    (state) => state.resetPartnerOrgUsers
  );
  const resetLoggedInUserData = useLoggedInUserData(
    (state) => state.resetLoggedInUserData
  );
  const resetOrganizationsData = useOrganizations(
    (state) => state.resetOrganizationsData
  );
  const setCustomerOrgPolicies = usePoliciesStore(
    (state) => state.setCustomerOrgPolicies
  );
  const { keycloak, initialized } = useKeycloak();
  const token = keycloak.token;
  // TODO: delete later
  // const tokenParsed = keycloak.tokenParsed;
  const [tokenData, setTokenData] = useState(null);
  // TODO: delete later
  // const allPolicies = useSelector(getPolicies);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const partnerInvite = localStorage.getItem('partnerInvite');
  const incidentExport = localStorage.getItem('incidentExport');

  const partnerInviteData =
    partnerInvite != null && partnerInvite != undefined
      ? JSON.parse(partnerInvite)
      : false;
  const incidentExportData =
    incidentExport != null && incidentExport != undefined
      ? JSON.parse(incidentExport)
      : false;

  //=== Store get/set actions
  // const setPartnerOrgData = useOrganizations(
  //   (state) => state.setPartnerOrgData
  // );
  // const custOrgData = useOrganizations((state) => state.getCustomerOrgData);
  const [custOrgData, setCustOrgData] = useState([]);
  // const setCustomerOrgData = useOrganizations(
  //   (state) => state.setCustomerOrgData
  // );
  // const setSelfStatus = useOrganizations((state) => state.setSelfStatus);
  // const setLoggedInUserRole = useOrganizations(
  //   (state) => state.setLoggedInUserRole
  // );
  // const setSelfAccounData = useOrganizations(
  //   (state) => state.setSelfAccounData
  // );
  // const resetCameraWallDevices = useCustomerOrgDevices(
  //   (state) => state.resetCameraWallDevices
  // );
  // const loggedInUserRole = useOrganizations((state) => state.loggedInUserRole);
  // const getOrganizations = useOrganizations((state) => state.getOrganizations);
  const setLoggedInUserData = useLoggedInUserData(
    (state) => state.setLoggedInUserData
  );
  const setLoggedInUserPolicies = usePoliciesStore(
    (state) => state.setLoggedInUserPolicies
  );
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData
  );
  const setLoggedInUserUUID = useLoggedInUserData(
    (state) => state.setLoggedInUserUUID
  );
  const accountId = useSelector(getAccountId);
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setCustOrgData(orgs || []);
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  //=== Set token and load the partner account requests list
  useEffect(() => {
    if (token && !tokenData && custOrgData?.length <= 0) {
      localStorage.setItem('vmsAuthToken', token);
      setTokenData(token);
      fetchData(token); //=== Fetch the partner account list
    } else if (token) {
      localStorage.setItem('vmsAuthToken', token);
    }
  }, [token, tokenData, custOrgData]);

  const [partnerAccountsData, setPartnerAccountsData] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [showLoader, setShowLoader] = useState(
    !keycloak?.idTokenParsed?.email || custOrgData?.length <= 0
  );
  const [userMsg, setUserMsg] = useState('');
  const [fetchOrgsStatus, setFetchOrgsStatus] = useState(true);
  const [showInactiveUser, setShowInactiveUser] = useState(true);

  const clearStoreData = async () => {
    dispatch(resetAccount());
    dispatch(resetStreaming());
    dispatch(resetPolicies());
    dispatch(resetViewsList());
    dispatch(resetCDNInfo());
    dispatch(resetPlatformInfo());
    //=== Remove local storage data from the zustand store data
    resetOrganizationsData();
    resetLoggedInUserData();
    resetPartnerOrgUsers();
    resetCustomerOrgLocations();
    await resetCameraWallDevices();
    resetCustomerOrgUsers();
    resetAllUserPolicies();
    // resetAllCustomerOrgDevices();
    resetDeviceSnapshots();
  };

  //=== Fetch organizations in background once the token is avialable
  useEffect(() => {
    if (fetchOrgsStatus && token && !tokenData && custOrgData?.length > 0) {
      fetchOrganizations(token);
    }
  }, [token, tokenData, custOrgData]);

  //=== Get logged in user account details and organizations list using API
  const fetchData = async (token) => {
    if (token) {
      custOrgData?.length <= 0 && setShowLoader(true);
      try {
        if (!axios.defaults.headers.common['Authorization']) {
          axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
        }
        await axios
          .get(`/partner/account/self`, Utils.requestHeader())
          .then(async (response) => {
            let resultData = response.data;
            if (resultData) {
              let responseMeta = resultData.meta;
              let responseData = resultData.data;
              let loggedInUserData = responseData?.account;
              if (loggedInUserData?.accountStatus !== 'ACTIVE') {
                dispatch(setSelfStatus('1'));
              }
              if (
                responseMeta &&
                (responseMeta.code === 200 || responseMeta.code === '200')
              ) {
                if (
                  process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON
                ) {
                  if (
                    !JSON.parse(localStorage.getItem('isAmplitudeSingleCall'))
                  ) {
                    localStorage.setItem('isAmplitudeSingleCall', true);
                    Utils.amplitudeInit(responseData?.account?.accountId);
                    const eventPropertiesName = {
                      [AmplitudeEventPropertyEnums.EMAIL_ID]:
                        responseData?.account?.email,
                    };
                    Utils.amplitudeTrack(
                      AmplitudeEventEnums.SIGN_IN,
                      eventPropertiesName
                    );
                  }
                }
                dispatch(setAccountId(responseData?.account?.accountId));
                // getMQTT(responseData?.account?.accountId);
                let vmsLocalData = {
                  netDucloVms: {
                    customers: null,
                    loggedInUserData: null,
                  },
                };

                if (
                  loggedInUserData && // 👈 null and undefined check and set the logged in user data
                  Object.keys(loggedInUserData).length >= 1 &&
                  Object.getPrototypeOf(loggedInUserData) === Object.prototype
                ) {
                  setLoggedInUserData(loggedInUserData);
                  dispatch(setSelfAccounData(loggedInUserData));
                  setLoggedInUserUUID(uuidv4());
                  setPartnerAccountsData(loggedInUserData);
                  dispatch(setLoggedInUserRole(loggedInUserData?.role));
                }

                let organizationsListData = responseData?.organizations;
                const orgList = organizationsListData.map(({ devices, ...rest }) => rest);
                if (organizationsListData) {

                  // dispath userdata List
                  dispatch(updateUserData(orgList));

                  // TODO : delete later
                  // subscribeWithOrgIds(organizationsListData);
                  let loggedInUserRole = Utils.getLoggedInUserRole(
                    organizationsListData
                  );
                  dispatch(setLoggedInUserRole(loggedInUserRole));
                  setUserRole(loggedInUserRole); //=== Set logged in user role

                  if (
                    loggedInUserRole === roles.ROLE4VMS ||
                    loggedInUserRole === roles.ROLE5VMS
                  ) {
                    dispatch(setSelfStatus('1'));
                    let primaryOrg = organizationsListData.filter((e) => {
                      return e.orgType === 'INSTALLER_ORG';
                    });
                    if (primaryOrg.length === 1) {
                      let primaryOrgData = primaryOrg[0];
                      vmsLocalData.netDucloVms.customers = primaryOrgData;
                      dispatch(setPartnerOrgData(primaryOrgData)); //=== add primary org data to the org store
                      let updatedPoliciesData = Utils.mapUserPolicies(
                        DefaultPolicies,
                        primaryOrgData.policies
                      );
                      dispatch(updatePolicies(updatedPoliciesData));
                      setLoggedInUserPolicies(updatedPoliciesData);
                    }

                    let custOrgs = organizationsListData.filter((e) => {
                      return e.orgType !== 'INSTALLER_ORG';
                    });
                    await setCustomerOrgData(custOrgs); //=== add customer org data to the org store
                  } else {
                    let primaryOrgData = organizationsListData[0];
                    dispatch(setOrgInfo({
                      orgId: primaryOrgData?.orgId,
                      orgName: primaryOrgData?.orgName,
                      shortName: primaryOrgData?.shortName,
                    }));
                    let updatedPoliciesData = Utils.mapUserPolicies(
                      DefaultPolicies,
                      primaryOrgData?.policies
                    );
                    dispatch(updatePolicies(updatedPoliciesData));
                    setLoggedInUserPolicies(updatedPoliciesData);
                    setCustomerOrgPolicies(updatedPoliciesData)
                    await setCustomerOrgData(responseData.organizations); //=== add customer org data to the org store
                  }
                }

                localStorage.setItem(
                  'vmsLocalData',
                  JSON.stringify(vmsLocalData)
                );
              } else {
                setUserMsg(responseData.userMsg);
              }
            }
            setShowLoader(false);
          });
      } catch (error) {
        setUserMsg(error);
        setShowLoader(false);
      }
    }
  };

  //=== Get organizations data
  const fetchOrganizations = async (token) => {
    if (token) {
      setFetchOrgsStatus(false);
      //=== Subscribe the topics for all added customer organizations
      if (
        custOrgData?.length > 0 &&
        keycloak?.idTokenParsed?.email &&
        getLoggedInUserData()?.email !== keycloak?.idTokenParsed?.email
      ) {
        clearStoreData();
      } else {
        keycloak?.idTokenParsed?.email && setShowLoader(false);
      }
      // customerOrgData().length > 0 && fetchData(token);
      fetchData(token);
      subscribeWithOrgIds(custOrgData);
    }
  };

  const getMQTT = (accountIdRes) => {
    axios
      .get(`/partner/platform/v2`, {
        withCredentials: true,
        ...Utils.requestHeader(),
      })
      .then((response) => {
        if (response?.data?.data) {
          dispatch(setPlatformInfo(response?.data?.data));
          if (!checkMQTTConnection() && accountIdRes) {
            connectWithMQTT(accountIdRes);
          }
        }
      });
  };

  // TODO: Below code is in observation
  // useEffect(() => {
  //   if (token != null) {
  //     Utils.vmsLogger().log('token------', accountId);
  //     getMQTT();
  //   }
  // }, []);

  return (
    <div className="App">
      <Header
        userRole={userRole}
        accountData={partnerAccountsData}
        showCart={false}
      />
      <PageWrapper className="container-top-offset mw-100">
        {getLoggedInUserData()?.accountStatus === 'INACTIVE' && (
          <Alert
            className="text-start inactive-user-alert"
            variant="warning"
            show={showInactiveUser}
            onClose={() => setShowInactiveUser(false)}
            dismissible
          >
            <div className="d-flex">
              <GoInfo size={15} className="info-icon" />
              <div>
                <Alert.Heading>
                  {constants.MANAGE_ORG_INACTIVE_USER_ALERT_TITLE}
                </Alert.Heading>
                <p>{constants.MANAGE_ORG_INACTIVE_USER_ALERT_CONTENT}</p>
              </div>
            </div>
          </Alert>
        )}
        {showLoader || !getLoggedInUserData()?.email ? (
          <div className="position-absolute top-50" style={{ left: '47%' }}>
            <SiteSpinner height="50px" width="50px" />
            <div className="mt-2 text-dark">{constants.LOADING}</div>
          </div>
        ) : (
          <>
            {getLoggedInUserData()?.orgType === 'INSTALLER_ORG' ? (
              partnerInviteData ? (
                navigate(
                  `/partnerinvite?invitationId=${
                    partnerInviteData?.invitationId
                  }&code=${partnerInviteData?.code}&isAccepted=${
                    partnerInviteData?.isAccepted == 'true' ? true : false
                  }`
                )
              ) : (
                <PartnerLandingPage />
              )
            ) : incidentExportData ? (
              navigate(
                `/incidentBundle?bundleId=${incidentExportData?.bundleId}&orgId=${incidentExportData?.orgId}&incidentId=${incidentExportData?.incidentId}`
              )
            ) : (
              <CustomerLandingPage />
            )}
          </>
        )}
      </PageWrapper>
    </div>
  );
}

export default ManageOrganizations;
