import { Utils } from '.';
import { getAllDevicesData, setAllDevicesData } from '../store/AccountStoreIDB';
import {
  setCameraWallDevices,
  setCustomerOrgDevices,
} from '../store/CustomerOrgDevicesStoreIDB';
import {
  getDoNotRefreshDeviceList,
  setDeviceInformation,
} from '../store/reducers/AccountReducer';
import Store from '../store/Store';
import debounce from 'lodash/debounce';

const pendingUpdates = {
  connectionStatus: {},
  deviceStatus: {},
};

// Debounced function to update store in batches
const applyPendingUpdates = debounce(async () => {
  const deviceList = await getAllDevicesData();
  const doNotRefreshDeviceList = Store.getState(getDoNotRefreshDeviceList)
    ?.accounts?.doNotRefreshDeviceList;

  const updatedData = deviceList.map((device) => {
    const deviceId = device.deviceId;
    return {
      ...device,
      connectionStatus:
        pendingUpdates.connectionStatus[deviceId] || device.connectionStatus,
      deviceStatus:
        pendingUpdates.deviceStatus[deviceId] || device.deviceStatus,
    };
  });

  if (!doNotRefreshDeviceList) {
    const cameraWallChildDevices = Utils.getTotalChildDevices(updatedData);
    await Promise.all([
      setAllDevicesData(updatedData),
      setCustomerOrgDevices(updatedData),
      setCameraWallDevices(cameraWallChildDevices),
    ]);
  }

  pendingUpdates.connectionStatus = {};
  pendingUpdates.deviceStatus = {};
}, 1000);

/**
 *
 * @param {*} MQTT data, device data, and updateType (custOrgDevices & devices)
 * @returns updated device data
 */

const updateDeviceDataByMqtt = async (data, currentDeviceId) => {
  const resource = data?.msg?.resource;
  const connectionStatus = data?.msg?.properties?.connectionStatus;
  const deviceStatus = data?.msg?.properties?.deviceStatus;
  if (resource?.includes('device/') || resource?.includes('devices/')) {
    const deviceAttributes = resource.split('/');
    if (Array.isArray(deviceAttributes)) {
      const deviceId = deviceAttributes[deviceAttributes.length - 1];
      if (deviceId && (deviceStatus || connectionStatus)) {
        if (deviceStatus) {
          pendingUpdates.deviceStatus[deviceId] = deviceStatus;
        }
        if (connectionStatus) {
          pendingUpdates.connectionStatus[deviceId] = connectionStatus;
        }
        // Update current device details immediately, if applicable
        if (currentDeviceId) {
          let selectedDevice = JSON.parse(
            JSON.stringify(Store.getState()?.accounts?.deviceInformation)
          );
          if (selectedDevice?.deviceId === deviceId) {
            if (deviceStatus) selectedDevice.deviceStatus = deviceStatus;
            if (connectionStatus)
              selectedDevice.connectionStatus = connectionStatus;
            Store.dispatch(setDeviceInformation(selectedDevice));
          }
        }

        // Trigger debounced batch update
        applyPendingUpdates();
      }
    }
  }
};

const updateCustomerOrgDevicesDataByMqtt = (orgData, data) => {
  if (orgData === null) {
    return null;
  }

  const resource = data?.msg?.resource;
  const connectionStatus = data?.msg?.properties?.connectionStatus;
  const deviceStatus = data?.msg?.properties?.deviceStatus;
  if (resource?.includes('device/') || resource?.includes('devices/')) {
    const deviceAttributes = resource.split('/');
    if (Array.isArray(deviceAttributes)) {
      const deviceId = deviceAttributes[deviceAttributes.length - 1];
      if (deviceId && deviceStatus) {
        let updatedData = updateCustomerOrgDevicesData(
          'deviceStatus',
          deviceStatus,
          deviceId,
          orgData
        );
        return updatedData;
      } else if (deviceId && connectionStatus) {
        let updatedData = updateCustomerOrgDevicesData(
          'connectionStatus',
          connectionStatus,
          deviceId,
          orgData
        );
        return updatedData;
      }
    }
  }
  return null;
};

const updateCustomerOrgDevicesData = (
  fieldName,
  fieldValue,
  deviceId,
  orgData
) => {
  let updatedOrgData = orgData?.map((curOrg) => {
    let deviceData = curOrg?.devices?.map((curDevice) => {
      if (deviceId === curDevice?.deviceId) {
        if (fieldName === 'connectionStatus') {
          return {
            ...curDevice,
            connectionStatus: fieldValue,
          };
        } else if (fieldName === 'deviceStatus') {
          return {
            ...curDevice,
            deviceStatus: fieldValue,
          };
        }
      }
      return curDevice;
    });
    return {
      ...curOrg,
      devices: deviceData,
    };
  });
  return updatedOrgData;
};

export { updateDeviceDataByMqtt, updateCustomerOrgDevicesDataByMqtt };
