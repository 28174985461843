import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimeField, TimePicker } from '@mui/x-date-pickers';
import DatePicker from 'react-multi-date-picker';
import InputIcon from 'react-multi-date-picker/components/input_icon';

import axios from 'axios';
import moment from 'moment';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import timezonePlugin from 'dayjs/plugin/timezone';

import {
  AccordionList,
  MaterialSelectList,
  Tags,
} from '../../../components/common';

import { constants, Utils } from '../../../helpers';
import timezones from '../../../data/support/timezone.json';

import { useCustomerOrgLocations } from '../../../store/CustomerOrgLocationsStore';

import './NewIncident.scss';
import { getCustomerOrgData } from '../../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../../store/indexDB/observer';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import { TextField } from '@mui/material';

const defaultFormData = {
  summary: '',
  description: '',
  locationId: '',
  areaId: '',
  timezone: '',
  incidentDateTime: 0,
  tags: [],
};

// Extend dayjs
dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);

const MAX_COMMENT_NUM_CHARACTERS = 500;

const NewIncident = ({
  shouldSubmitForm = false,
  openModal,
  enableButtonLoader = false,
  setFormComplete,
  newClipId = null,
  submitCallback,
  setUserMsg,
  setShowToast,
  errorHandle,
  setSubmitNewIncidentForm,
  ...props
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  let currentDate = new Date(),
    currentTime = moment().format('YYYY-MM-DDTHH:mm:ss'),
    currentLocationId,
    currentAreaId;

  const [currentLocationAreas, setCurrentLocationAreas] = useState([]);
  const [incidentTags, setIncidentTags] = useState([]);
  const [incidentSummary, setIncidentSummary] = useState(null);
  const [incidentDescription, setIncidentDescription] = useState(null);
  const [incidentDate, setIncidentDate] = useState(currentDate);
  // const [incidentDateTime, setIncidentDateTime] = useState(dayjs(currentTime));
  const [incidentDateTime, setIncidentDateTime] = useState(currentTime);

  const [incidentTimeZone, setIncidentTimeZone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [incidentTimeZoneLocation, setIncidentTimeZoneLocation] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [incidentLocationId, setIncidentLocationId] = useState(null);
  const [incidentAreaId, setIncidentAreaId] = useState(null);
  const [incidentClipId, setIncidentClipId] = useState(newClipId);
  const [clipStartTime, setClipStartTime] = useState(0);
  const [clipEndTime, setClipEndTime] = useState(0);
  const [disableFutureTime, setDisableFutureTime] = useState(true);
  const [currentTimezones, setCurrentTimezones] = useState([]);
  const [timePickerValue, setTimePickerValue] = useState(currentTime);
  const [datePickerValue, setDatePickerValue] = useState(currentDate);
  const [formData, setFormData] = useState(defaultFormData);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [numOfCharacters, setNumOfCharacters] = useState(0);
  const [placeholderVisible, setPlaceholderVisible] = useState(true);

  // const { getCustomerOrgData } = useOrganizations();
  // const { getCustomerOrgDevices } = useCustomerOrgDevices();
  const { getCustomerOrgLocationsData } = useCustomerOrgLocations();

  const timeZones = timezones?.data;
  // const orgDetails = getCustomerOrgData()[0];
  const [orgDetails, setOrgDetails] = useState();

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setOrgDetails(orgs?.[0] || {});
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  useEffect(() => {
    checkRequiredFields();
  }, [incidentSummary, numOfCharacters]);

  useEffect(() => {
    const fetchData = () => {
      try {
        fetchLocationAreas();
      } catch (error) {
        Utils.vmsLogger().error(error);
      }
    };

    setCurrentTimezones([
      ...timeZones.map((timezone) => {
        return {
          value: timezone?.location,
          label: timezone?.label,
        };
      }),
    ]);

    fetchData();
  }, []);

  useEffect(() => {
    const saveIncidentHandler = async () => {
      try {
        const reqBody = {
          ...formData,
          timezone: incidentTimeZone,
          incidentDateTime: combineDateTime(),
          locationId: incidentLocationId,
          areaId: incidentAreaId,
          tags: incidentTags,
        };

        // If there's a clip to associate with the new incident,
        // we include it in the request body.
        if (incidentClipId) {
          reqBody.clipId = incidentClipId;
        }

        // TODO: to be implemented later
        // if (!isNaN(clipStartTime) && clipStartTime > 0) {
        //   reqBody.clipStartTime = clipStartTime;
        // }

        // TODO: to be implemented later
        // if (!isNaN(clipEndTime) && clipEndTime > 0) {
        //   reqBody.clipEndTime = clipEndTime;
        // }

        let response = await axios.post(
          `/incident/orgs/${orgDetails?.orgId}/incidents`,
          reqBody,
          Utils.requestHeader()
        );

        let resData = response?.data;

        if (resData?.meta.code === 200) {
          if (!resData.data?.incident?.incidentId) {
            throw new Error(
              `ERROR: missing incident ID for new incident - ${formData?.summary}`
            );
          }
          submitCallback && submitCallback(resData.data?.incident?.incidentId);
          enableButtonLoader && enableButtonLoader(false);
          clearFormData();
          openModal && openModal(false);
        } else {
          enableButtonLoader(false);
          setUserMsg(resData?.meta?.userMsg);
          setShowToast(true);
          errorHandle();
          Utils.vmsLogger().error(
            `ERROR: ${resData?.meta?.code} - ${resData?.meta?.desc}`
          );
        }
      } catch (err) {
        Utils.vmsLogger().error(err);
      }
    };
    if (shouldSubmitForm === true) {
      saveIncidentHandler();
    }
  }, [shouldSubmitForm, orgDetails?.orgId]);

  const fetchLocationAreas = useCallback(() => {
    const userOrgLocations = getCustomerOrgLocationsData();
    const locationAreas = [];

    getDefaultLocationArea(userOrgLocations);

    try {
      if (Array.isArray(userOrgLocations) && userOrgLocations.length > 0) {
        let i = 0,
          locationsLength = userOrgLocations.length,
          location,
          sublist,
          timezoneInOlson;

        // We're using a while-loop because it's more performant
        // than a for-loop, for-of-loop, .forEach)_ or .map()
        while (i < locationsLength) {
          location = userOrgLocations[i];
          sublist = location?.areas?.map((area) => {
            return {
              sublistItemId: area?.areaId,
              sublistItemName: area?.areaName,
            };
          });

          timezoneInOlson = Utils.getTimezone(location.timezone, 'OLSON');

          locationAreas.push({
            listItemId: location.locationId,
            listItemName: location.locationName,
            listItemTimezone: timezoneInOlson,
            sublist: [...sublist],
          });
          i++;
        }

        // Sort the locations by location name (listItemName) in ascending order
        locationAreas.sort((locA, locB) => {
          if (
            locA?.listItemName?.toLowerCase() <
            locB?.listItemName?.toLowerCase()
          ) {
            return -1;
          }

          if (
            locA?.listItemName?.toLowerCase() >
            locB?.listItemName?.toLowerCase()
          ) {
            return 1;
          }

          return 0;
        });
      } else {
        throw new Error('ERROR: no organization locations available');
      }
    } catch (err) {
      Utils.vmsLogger().error(err);
    } finally {
      setCurrentLocationAreas([...locationAreas]);
    }
  }, []);

  const getDefaultLocationArea = (organizationLocations) => {
    try {
      if (
        Array.isArray(organizationLocations) &&
        organizationLocations.length > 0
      ) {
        const locationsFromTimezone = organizationLocations.filter(
          (location) => location?.timezone === incidentTimeZone
        );

        if (locationsFromTimezone.length > 0) {
          currentLocationId = locationsFromTimezone[0].locationId;
          currentAreaId = locationsFromTimezone[0]?.areas[0]?.areaId;

          setIncidentLocationId(currentLocationId);
          setIncidentAreaId(currentAreaId);
        }
      } else {
        throw new Error('ERROR: no organization devices found');
      }
    } catch (err) {
      Utils.vmsLogger().error(err);
    }
  };

  const checkRequiredFields = () => {
    setFormComplete &&
      setFormComplete(
        incidentSummary &&
          numOfCharacters &&
          incidentDateTime &&
          (incidentLocationId || currentLocationId) &&
          (incidentAreaId || currentAreaId)
      );
  };

  const updateTimeZoneFromLocation = (locationId) => {
    let selectedLocation;

    if (!locationId || currentLocationAreas?.length < 1) return;

    selectedLocation = currentLocationAreas.find(
      (location) => location.listItemId === locationId
    );

    if (selectedLocation) {
      setIncidentTimeZone(selectedLocation.listItemTimezone);
      // Adkist the current incident date and time to the new timezone
      adjustDateTimeToTimezone(selectedLocation.listItemTimezone);
    }
  };

  const combineDateTime = () => {
    let combinedDateTime;

    if (!incidentDate || !incidentDateTime) return;

    try {
      const date = moment
        .utc(incidentDate.valueOf())
        .tz(incidentTimeZone)
        .format('YYYY-MM-DD');

      const time = moment
        .tz(incidentDateTime, incidentTimeZone)
        .format('hh:mm:ss A');

      combinedDateTime = moment.tz(
        `${date} ${time}`,
        'YYYY-MM-DD hh:mm:ss A',
        incidentTimeZone
      );
    } catch (err) {
      Utils.vmsLogger().error(err);
    }

    return moment.utc(combinedDateTime).valueOf();
  };

  const convertTimezone = (toTimezone) => {
    if (!toTimezone) return;

    let dateTime = combineDateTime();

    const convertedDateTime = moment
      .tz(dateTime, toTimezone)
      .format('YYYY-MM-DDTHH:mm:ss');

    return convertedDateTime;
  };

  const adjustDateTimeToTimezone = (newTimezone) => {
    if (!newTimezone) return;

    const newLocation = getTimezoneLocationByValue(newTimezone);

    // Get the Moment object for the converted date/time
    const convertedDateTime = convertTimezone(newTimezone);

    if (convertedDateTime) {
      // Set Date
      currentDate = moment.tz(convertedDateTime, newLocation).valueOf();
      setIncidentDate(currentDate);

      // Set Date Time
      currentTime = combineDateTime();
      setIncidentDateTime(currentTime);
      setIncidentTimeZoneLocation(newLocation);
    }
  };

  const getTimezoneLocationByValue = (timezoneValue) => {
    if (!timezoneValue) return;

    return Utils.getTimezone(timezoneValue, 'olson');
  };

  const handleInputChange = (e, field) => {
    if (!e) return;

    const { name, value } = e?.target;

    if (!name || !field) return;

    switch (field?.toUpperCase()) {
      case 'SUMMARY':
        setIncidentSummary(value);
        break;

      case 'DETAILS':
        setIncidentDescription(value);
        break;

      default:
    }

    setFormData((prev) => {
      let newFormData = {
        ...prev,
        [name]: value,
      };

      return newFormData;
    });
  };

  const handleDateChange = (value) => {
    if (!value) return;

    setDatePickerValue(value);
    setIncidentDate(value);
    checkRequiredFields();
  };

  const handleAddTag = (tagsArray) => {
    let updatedTags = incidentTags;

    if (!Array.isArray(tagsArray)) return;

    setIncidentTags([...updatedTags.concat(tagsArray)]);
  };

  const handleRemoveTag = (tagId) => {
    const currentIncidentTagIds = incidentTags;

    if (!tagId) return;

    const tagIndex = currentIncidentTagIds.findIndex(
      (currentTagId) => currentTagId === tagId
    );

    if (tagIndex !== -1) {
      currentIncidentTagIds.splice(tagIndex, 1);

      setIncidentTags([...currentIncidentTagIds]);
    }
  };

  const handleFormSubmit = () => {
    // Do nothing
    return;
  };

  // Clear Form Data
  const clearFormData = () => {
    setIncidentTags([]);
    setIncidentTimeZone(null);
    setIncidentDate(null);
    setIncidentDateTime(null);
    setFormComplete(false);
    setFormSubmitted(false);
    setIncidentLocationId(null);
    setIncidentAreaId(null);
    setFormData(defaultFormData);
    setSubmitNewIncidentForm(false);
  };

  useEffect(() => {
    const isTimeConvertion = moment
      .tz(new Date(), incidentTimeZone)
      .format('YYYY-MM-DDTHH:mm:ss');

    const newTime = new Date(isTimeConvertion);
    setDatePickerValue(newTime);
  }, [incidentTimeZone]);

  return (
    <div className="new-incident-wrapper">
      {!formSubmitted && (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Container className="new-incident-container">
            {/* Blue Horizontal Line */}
            <Row>
              <Col>
                <div className="mb-3 new-incident-horizontal-line"></div>
              </Col>
            </Row>
            {/* Summary */}
            <Row>
              <Col>
                <div className="mb-3 summary-input-wrapper">
                  <div className="placeholder-wrap">
                    <input
                      {...register('summary', { required: true })}
                      value={incidentSummary}
                      name="summary"
                      maxLength={120}
                      onChange={(e) => handleInputChange(e, 'summary')}
                      onBlur={(e) => {
                        checkRequiredFields();
                      }}
                      className="incident-input summary-input"
                    />
                    {!incidentSummary && (
                      <div className="custom-placeholder">
                        {
                          constants.INCIDENTS_MODAL_NEW_INCIDENT_SUMMARY_PLACEHOLDER
                        }
                        <span className="required">*</span>
                      </div>
                    )}
                  </div>
                  {errors.summary && (
                    <div>
                      {
                        constants.INCIDENTS_MODAL_NEW_INCIDENT_SPECIFY_SUMMARY_ERROR
                      }
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            {/* Details/Description */}
            <Row>
              <Col>
                <div className="mb-1">
                  <div className=" mb-1 incident-comment">
                    <TextField
                      id="incidentComment"
                      className="incident-comment"
                      multiline
                      rows={5}
                      name="description"
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        setNumOfCharacters(inputValue?.length);
                        handleInputChange(e, 'details');
                      }}
                      disabled={false}
                      fullWidth
                      required
                      inputProps={{
                        ...register('description', { required: true }),
                        maxLength: MAX_COMMENT_NUM_CHARACTERS,
                      }}
                      onFocus={() => setPlaceholderVisible(false)}
                      onBlur={(e) => {
                        if (!e.target.value) {
                          setPlaceholderVisible(true);
                        }
                        checkRequiredFields();
                      }}
                    />
                    {!numOfCharacters && placeholderVisible && (
                      <div
                        className="custom-placeholder"
                        onClick={() => {
                          setPlaceholderVisible(false);
                          document.getElementById('incidentComment').focus();
                        }}
                      >
                        {
                          constants.INCIDENTS_MODAL_NEW_INCIDENT_DETAILS_PLACEHOLDER
                        }
                        <span className="required">*</span>
                      </div>
                    )}
                  </div>
                  {errors.details && (
                    <div>
                      {
                        constants.INCIDENTS_MODAL_NEW_INCIDENT_SPECIFY_DETAILS_ERROR
                      }
                    </div>
                  )}
                  <div className="char-count">
                    {numOfCharacters} / {MAX_COMMENT_NUM_CHARACTERS}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3 incident-label new-incident-tags">
                  {constants.INCIDENTS_TAGS_TITLE}
                </div>
              </Col>
            </Row>
            {/* Tags */}
            <Row>
              <div className="new-incident-pill-wrapper">
                <Tags
                  tagCategory="IM"
                  systemTagsOnly={false}
                  fullWidth={true}
                  addTagsCallback={(val) => {
                    handleAddTag(val);
                    checkRequiredFields();
                  }}
                  removeTagCallback={(val) => {
                    handleRemoveTag(val);
                    checkRequiredFields();
                  }}
                />
              </div>
            </Row>
            <Row>
              <Col>
                <div className="new-incident-tag-list-wrapper"></div>
              </Col>
            </Row>
            {/* Locations */}
            <Row>
              <Col>
                <div className="mt-3 mb-3 incident-label new-incident-location">
                  {
                    constants.INCIDENTS_MODAL_NEW_INCIDENT_DETAILS_INCIDENT_LOCATION
                  }
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-4 new-incident-location-list">
                  <AccordionList
                    containerClass="new-incident-locations"
                    listItems={currentLocationAreas}
                    chevronColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--greyscale_56')}
                    onChangeCallback={async (listItemId, sublistItemId) => {
                      if (!listItemId || !sublistItemId) return;

                      updateTimeZoneFromLocation(listItemId);
                      currentLocationId = listItemId;
                      currentAreaId = sublistItemId;

                      setIncidentLocationId(currentLocationId);
                      setIncidentAreaId(currentAreaId);
                      checkRequiredFields();
                    }}
                    selectedListItemId={incidentLocationId}
                    selectedSublistId={incidentAreaId}
                  />
                </div>
              </Col>
            </Row>
            {/* Timezone */}
            <Row>
              <Col>
                <div className="mb-3 incident-label new-incident-location-timezone">
                  {
                    constants.INCIDENTS_MODAL_NEW_INCIDENT_DETAILS_LOCATION_TIMEZONE
                  }
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-4 timezone-input-wrapper">
                  <MaterialSelectList
                    forwardProps={{
                      ...register('timezone', { required: false }),
                    }}
                    options={[...currentTimezones]}
                    iconComponent={() => null}
                    placeholder={
                      constants.INCIDENTS_MODAL_NEW_INCIDENT_DETAILS_LOCATION_TIMEZONE_LABEL
                    }
                    label={
                      constants.INCIDENTS_MODAL_NEW_INCIDENT_DETAILS_LOCATION_TIMEZONE_LABEL
                    }
                    disabled={true}
                    defaultValue={incidentTimeZone}
                    updatedValue={incidentTimeZone}
                    callback={(value) => {
                      if (!value) return;
                      setIncidentTimeZone(value);
                      checkRequiredFields();
                    }}
                  />
                  {errors.timezone && (
                    <div>
                      {
                        constants.INCIDENTS_MODAL_NEW_INCIDENT_SPECIFY_DETAILS_ERROR
                      }
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            {/* Incident Date */}
            <Row>
              <Col>
                <div className="mb-3 incident-label new-incident-date">
                  {constants.INCIDENTS_MODAL_NEW_INCIDENT_DETAILS_INCIDENT_DATE}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3 new-incident-date-wrapper">
                  <DatePicker
                    className="new-incident-date customized-datepicker"
                    containerClassName="new-incident-date-container"
                    format={Utils.getDateFormatStringByLocale(
                      navigator.language
                    )}
                    placeholder={Utils.getDateFormatStringByLocale(
                      navigator.language
                    )}
                    maxDate={new Date()}
                    value={datePickerValue}
                    onChange={handleDateChange}
                    onBlur={() => {
                      checkRequiredFields();
                    }}
                    PopperProps={{
                      placement: 'bottom-end',
                    }}
                    render={<InputIcon className="new-incident-cal-input" />}
                    renderButton={(direction, handleClick, disabled) => (
                      <span
                        onClick={handleClick}
                        className={`rdmp-arrow-container ${
                          direction === 'left' ? 'rmdp-left' : 'rmdp-right'
                        }  
                          ${disabled ? 'disabled' : 'active'}
                        `}
                      >
                        <i className="rmdp-arrow"></i>
                      </span>
                    )}
                  />
                  {/* {errors.incidentDate && (
                  <div>
                    {
                      constants.INCIDENTS_MODAL_NEW_INCIDENT_SPECIFY_DETAILS_ERROR
                    }
                  </div>
                )} */}
                </div>
              </Col>
            </Row>
            {/* Incident Time */}
            <Row>
              <Col>
                <div className="mt-2 mb-3 incident-label new-incident-time">
                  {constants.INCIDENTS_MODAL_NEW_INCIDENT_DETAILS_INCIDENT_TIME}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3 text-center  new-incident-time-container">
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                      {...props}
                      className="new-incident-time-picker"
                      slotProps={{
                        textField: {
                          variant: 'outlined',
                        },
                      }}
                      renderInput={(inputProps) => (
                        <TimeField
                          helperText="Enter Time"
                          fullWidth={true}
                          hiddenLabel={true}
                        />
                      )}
                      defaultValue={moment(incidentDateTime)}
                      referenceDate={datePickerValue}
                      value={moment(timePickerValue)}
                      timezone={incidentTimeZoneLocation}
                      disableFuture={
                        new Date(datePickerValue)?.toDateString() ===
                        new Date().toDateString()
                      }
                      timeSteps={{ hours: 1, minutes: 1 }}
                      onChange={(newValue) => {
                        if (!newValue) return;

                        currentTime = moment(newValue._d).valueOf();

                        setIncidentDateTime(currentTime);
                        setTimePickerValue(newValue);
                        checkRequiredFields();
                      }}
                      onBlur={() => {
                        checkRequiredFields();
                      }}
                      closeOnSelect={false}
                    />
                  </LocalizationProvider>
                </div>
              </Col>
            </Row>
          </Container>
        </form>
      )}
    </div>
  );
};

export default NewIncident;
