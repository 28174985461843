import { useState, useEffect, useRef, useCallback } from 'react';
import {
  useLocation,
  useNavigate,
  useSearchParams,
  NavLink,
} from 'react-router-dom';
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Row,
  Col,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import axios from 'axios';
import {
  constants,
  Utils,
  ManageMqttData,
  AppDefaults,
  roles,
} from '../../helpers';
import { ReactComponent as BrandLogoSvg } from '../../assets/images/BrandLogo.svg';
import { IconContext } from 'react-icons';
import {
  HiOutlineOfficeBuilding,
  HiOutlineBell,
  HiOutlineUser,
  HiOutlineLogout,
  HiOutlineShoppingCart,
  HiOutlineLockClosed,
} from 'react-icons/hi';
import { TbSettings } from 'react-icons/tb';
import { useKeycloak } from '@react-keycloak/web';
import { useCart } from '../../store/CartStore';
import { useAppsStore } from '../../store/AppsStore';
import { useOrganizations } from '../../store/OrganizationsStore';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { usePoliciesStore } from '../../store/policiesStore';
import DefaultPolicies from '../../store/reducers/DefaultPolicies';
import greaterThanIcon from '../../assets/images/icons/greaterThanIcon.svg';
import { BsFillGrid3X3GapFill } from 'react-icons/bs';

import {
  clearLiveStreams,
  clearMQTTPeerConnections,
  clearRTCPeerConnections,
  clearRemoteStreams,
  clearRemoteStreamsAudio,
  clearWSSConnections,
  getCDNInfo,
  getMQTTConnection,
  resetCDNInfo,
  resetPlatformInfo,
  setPlatformInfo,
  getPlatformInfo,
  setCDNInfo,
} from '../../store/reducers/StreamingReducer';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAccountId,
  getMqttUpdateStatus,
  getAllMqttDataFromResponse,
  setMqttUpdateStatusFromResponse,
  getAccountId,
  getAllAreas,
  setAllAreas,
  setSubscribeAreasRequest,
  setKeepAliveRequest,
  // getSelectedOrganization,
  setNotificationFlag,
  getNotificationFlag,
  setIncidentExportNotification,
} from '../../store/reducers/AccountReducer';
import {
  checkMQTTConnection,
  connectWithMQTT,
  mqttDisconnectRequest,
  mqttSubscribe,
  subscribeWithAccountId,
  subscribeWithOrgIds,
} from '../../utils/connection/mqttConnection';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { devicesMQTTStore } from '../../store/DevicesMQTTStore';
import { v4 as uuidv4 } from 'uuid';
import { useNotificationStore } from '../../store/CreateNotificationStore';
import useEventsStore from '../../store/EventsStore';
// import { useCustomerOrgDevices } from '../../store/CustomerOrgDevicesStore';
import { useModuleStore } from '../../store/ModuleStatusStore';
import { ReactComponent as MegatronLogoSvg } from '../../assets/images/MegatronLogo.svg';
import { IncidentTypeEnum, ClipTypeEnum } from '../../helpers/enums';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import { useCustOrgLocationArea } from '../../store/CustOrgLocationAreaStore';
import useEventSelectionStore from '../../store/EventSelectionStore';
import { app_version } from '../../helpers/commonUtils';
import TermConditionPolicyModal from './TermConditionPolicyModal';
import { clearInterval, setInterval } from 'worker-timers';
import { PosStore } from '../../store/PosStore';
import {
  resetCameraWallDevices,
  resetLiveViewDevices,
} from '../../store/CustomerOrgDevicesStoreIDB';
import {
  // getAllMqttDataFromResponse,
  getSelectedOrganization,
  setAllDevicesDataNotification,
} from '../../store/AccountStoreIDB';
import {
  getLoggedInUserRole,
  getOrgInfo,
  getSelfAccountData,
  getSelfStatus,
  resetSelfAccountData,
  setSelfStatus,
} from '../../store/reducers/OrganizationsReducer';
import {
  getCustomerOrgData,
  resetCustomerOrgData,
  setCustomerOrgData,
} from '../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import { resetPolicies } from '../../store/reducers/PoliciesReducer';
import { resetViewsList } from '../../store/reducers/ViewsReducer';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import { useDeviceSnapshots } from '../../store/DeviceSnapshotsStore';

function Header({
  backgroundColor,
  numOfICartItems,
  showCart = true,
  showNavigation = true,
  isAccountScreen = false,
  cartFetch = true,
  breadcrumbData = [],
  currentDeviceId = false,
  showHeader = true,
  onPushReceive,
  isFromVideoWall = false,
  isFromVerifyAccount = false,
  interRef = false,
}) {
  const orgInfo = useSelector(getOrgInfo);
  const { keycloak } = useKeycloak();
  const location = useLocation();
  const [dynamicApp, setDynamicApp] = useState(null);
  const [searchParams] = useSearchParams();
  const details = navigator.userAgent;
  const regexp = /android|iphone|kindle|ipad/i;
  const isMobileDevice = regexp.test(details);
  const getModuleStatus = useModuleStore((state) => state.getModuleStatus);
  const setModuleStatus = useModuleStore((state) => state.setModuleStatus);
  setModuleStatus(Utils.getModuleStatus());
  const moduleStatus = getModuleStatus();
  const dispatch = useDispatch();
  const setPingApiCallTime = useLoggedInUserData(
    (state) => state.setPingApiCallTime
  );
  // TODO: read these two from the store that has them
  let orgId = searchParams.get('orgId');
  let orgName = searchParams.get('orgName');
  // let cdnAPIInterval;
  const cdnAPIInterval = useRef();
  const [url, setUrl] = useState(null);
  const [hasNotification, setHasNotification] = useState(false);
  const [numOfCartItems, setNumOfCartItems] = useState(numOfICartItems);
  const navigate = useNavigate();
  const accountId = useSelector(getAccountId);
  // const { resetLiveViewDevices } = useCustomerOrgDevices();
  //=== Store get/set actions
  const cart = useCart((state) => state.cart);
  let pendingOrder = useCart((state) => state.isOrderPending);
  const getCartTotalQuantity = useCart((state) => state.getCartTotalQuantity);
  const deleteCart = useCart((state) => state.deleteCart);
  const deleteOrder = useCart((state) => state.deleteOrder);
  const deleteOrgId = useCart((state) => state.deleteOrgId);
  const setOrderPending = useCart((state) => state.setOrderPending);
  const setOrder = useCart((state) => state.setOrder);
  const setOrgId = useCart((state) => state.setOrgId);

  //=== Store get/set actions
  const mqttUpdateStatus = useSelector(getMqttUpdateStatus);
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  // const [allMqttData, setAllMqttData] = useState(null);
  const getMQTTConnectionStatus = useSelector(getMQTTConnection);
  const allAreasStore = useSelector(getAllAreas);
  const platformDetails = useSelector(getPlatformInfo);
  const notificationFlag = useSelector(getNotificationFlag);
  const managePartnerUrl = '/managepartnerusers.html';
  const { setNavigatedFromCameraDetail } = useEventSelectionStore();
  const authToken = localStorage.getItem('vmsAuthToken');

  // const getCustomerOrgData = useOrganizations(
  //   (state) => state.getCustomerOrgData
  // );
  const [customerOrgDetails, setCustomerOrgDetails] = useState();
  // const setCustomerOrgData = useOrganizations(
  //   (state) => state.setCustomerOrgData
  // );
  // const resetCustomerOrgData = useOrganizations(
  //   (state) => state.resetCustomerOrgData
  // );
  // const resetSelfAccount = useOrganizations((state) => state.resetSelfAccount);
  const resetCustomerOrgLocations = useCustomerOrgLocations(
    (state) => state.resetCustomerOrgLocations
  );
  const resetDeviceWallSelectedArea = useCustOrgLocationArea(
    (state) => state.resetDeviceWallSelectedArea
  );
  const resetPosStore = PosStore((state) => state.resetPosDataList);
  const resetDeviceWallSelectedLocation = useCustOrgLocationArea(
    (state) => state.resetDeviceWallSelectedLocation
  );
  const resetLoggedInUserData = useLoggedInUserData(
    (state) => state.resetLoggedInUserData
  );
  const setLoggedInUserData = useLoggedInUserData(
    (state) => state.setLoggedInUserData
  );
  // const resetCameraWallDevices = useCustomerOrgDevices(
  //   (state) => state.resetCameraWallDevices
  // );
  // const setSelfStatus = useOrganizations((state) => state.setSelfStatus);
  // const getSelfStatus = useOrganizations((state) => state.getSelfStatus);
  const selfStatus = useSelector(getSelfStatus);
  // const loggedInUserRole = useOrganizations((state) => state.loggedInUserRole);
  const loggedInUserRole = useSelector(getLoggedInUserRole);
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const cdnInfo = useSelector(getCDNInfo);
  const custOrgId =
    loggedInUserData?.orgType === 'INSTALLER_ORG'
      ? orgId
      : loggedInUserData?.orgId;
  const getLoggedInUserPolicies = usePoliciesStore(
    (state) => state.getLoggedInUserPolicies
  );
  const setCustomerOrgPolicies = usePoliciesStore(
    (state) => state.setCustomerOrgPolicies
  );
  const setLoggedInUserPolicies = usePoliciesStore(
    (state) => state.setLoggedInUserPolicies
  );
  const resetNotificationData = useNotificationStore(
    (state) => state.resetStepData
  );
  const { resetDeviceSnapshots } = useDeviceSnapshots();

  // const getSelfAccountData = useOrganizations(
  //   (state) => state.getSelfAccountData
  // );
  const selfAccountData = useSelector(getSelfAccountData);
  const setUserPushData = useOrganizations((state) => state.setUserPushData);

  const intervalRef = useRef();
  const getPingApiCallTime = useLoggedInUserData(
    (state) => state.getPingApiCallTime
  );
  let sessionTimeoutValue =
    loggedInUserData && loggedInUserData?.sessionTimeout
      ? loggedInUserData?.sessionTimeout == -1
        ? AppDefaults.DEFAULT_TIMER_TIME
        : parseInt(loggedInUserData?.sessionTimeout) * 60000
      : AppDefaults.DEFAULT_TIMER_TIME;

  const intervalInMillis = Math.ceil(sessionTimeoutValue / 3);
  let lastActivityTimestamp = new Date().getTime();

  const { getState } = devicesMQTTStore;
  const state = getState();
  const { setSelectedEventStore, setEventFromSearch, setEventCVRMode } =
    useEventsStore();
  // const [selfStatus, setSelfStatusFlag] = useState(getSelfStatus());
  // const orgDetails = useSelector(getSelectedOrganization);
  const [orgDetails, setOrgDetails] = useState();
  const getApps = useAppsStore((state) => state.getApps);
  let appList = useAppsStore((state) => state.appList);
  let helpData = useAppsStore((state) => state.helpData);

  const resetAllUserPolicies = usePoliciesStore(
    (state) => state.resetAllUserPolicies
  );
  const loadSelectedOrgData = useCallback(async () => {
    const org = await getSelectedOrganization();
    setOrgDetails(org || {});
  }, []);

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setCustomerOrgDetails(orgs || []);
  }, []);

  // const loadAllMqttData = useCallback(async () => {
  //   const mqttResp = await getAllMqttDataFromResponse();
  //   setAllMqttData(mqttResp || []);
  // }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );
  const debouncedLoadSelectedOrgData = useDebouncedCallback(
    loadSelectedOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
      if (data.key === 'selectedOrganization') {
        await debouncedLoadSelectedOrgData();
      }
      // if (data.key === 'allMqttDataFromResponse') {
      //   await loadAllMqttData();
      // }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();
    debouncedLoadSelectedOrgData();
    // loadAllMqttData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [
    debouncedLoadCustomerOrgData,
    debouncedLoadSelectedOrgData,
    // loadAllMqttData
  ]);

  useEffect(() => {
    if (
      customerOrgDetails?.length &&
      loggedInUserData.orgType === AppDefaults.ORG_TYPE_CUSTOMER
    ) {
      let policies = customerOrgDetails?.[0]?.policies;
      let updatedPoliciesData = Utils.mapUserPolicies(
        DefaultPolicies,
        policies
      );
      setCustomerOrgPolicies(updatedPoliciesData);
      setLoggedInUserPolicies(updatedPoliciesData);
    }
  }, [customerOrgDetails, loggedInUserData]);

  useEffect(() => {
    const status = localStorage.getItem('isSession');
    sessionTimeoutValue =
      loggedInUserData && loggedInUserData?.sessionTimeout
        ? loggedInUserData?.sessionTimeout == -1
          ? AppDefaults.DEFAULT_TIMER_TIME
          : parseInt(loggedInUserData?.sessionTimeout) * 60000
        : AppDefaults.DEFAULT_TIMER_TIME;
    const token = localStorage.getItem('vmsAuthToken');
    if (status != 'undefined' && status === '0') {
      if (token != 'null' && token != 'undefined' && token != '') {
        if (
          !loggedInUserData?.email ||
          (loggedInUserData && loggedInUserData?.sessionTimeout == -1)
        ) {
          return;
        }
        localStorage.setItem('isSession', 1);
        localStorage.setItem('uploadStatus', 0);
        setPingApiCallTime(lastActivityTimestamp);
        setTimeout(() => {
          OnIdleTrack(lastActivityTimestamp);
        }, 2000);
      }
    }
  }, [loggedInUserData]);

  const handleMouse = () => {
    const token = localStorage.getItem('vmsAuthToken');
    if (token == 'null' || token == 'undefined' || token == '') {
      return;
    }
    lastActivityTimestamp = new Date().getTime();
    const lastPingDurationInMins =
      (lastActivityTimestamp - getPingApiCallTime()) / 60000;
    if (lastPingDurationInMins >= intervalInMillis / 60000) {
      // Invoke API
      //TODO Delete Later
      Utils.vmsLogger().log('KeepAlive: Handle Mouse ', lastActivityTimestamp);
      setPingApiCallTime(lastActivityTimestamp);
      OnIdleTrack(lastActivityTimestamp);
    }
  };

  useEffect(() => {
    const idPing = setInterval(() => {
      const lastActiveDurationInMins =
        (new Date().getTime() - lastActivityTimestamp) / 1000 / 60;
      // Check if last activity was done in last 10 mins or not
      //TODO DELETE LATER
      Utils.vmsLogger().log('KeepAlive: called => ', lastActiveDurationInMins);
      if (lastActiveDurationInMins <= intervalInMillis / 60000) {
        // Invoke API
        //TODO DELETE LATER
        Utils.vmsLogger().log(
          'KeepAlive: activeTimer => ',
          lastActivityTimestamp
        );
        setPingApiCallTime(lastActivityTimestamp);
        OnIdleTrack(lastActivityTimestamp);
      }
    }, intervalInMillis - 60000);

    intervalRef.current = idPing;
    document.addEventListener('mousemove', handleMouse);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      document.removeEventListener('mousemove', handleMouse);
    };
  }, [loggedInUserData]);

  const OnIdleTrack = async (timestamp) => {
    const token = localStorage.getItem('vmsAuthToken');

    if (token == 'null' || token == 'undefined' || token == '') {
      return;
    } else if (
      !loggedInUserData?.email ||
      (loggedInUserData && loggedInUserData?.sessionTimeout == -1)
    ) {
      return;
    }
    await axios
      .get('partner/ping', {
        params: {
          lastActivityTime: Math.ceil(timestamp / 1000),
        },
        ...Utils.requestHeader(),
      })
      .then((res) => {
        const responseData = res?.data;
        if (responseData?.meta?.code === 401) {
          setPingApiCallTime(0);
          clearInterval(intervalRef?.current);
          invalidateSessionApi();
        }
      });
  };

  useEffect(() => {
    const sharedParams = JSON.parse(localStorage.getItem('sharedClipParams'));

    dispatch(setKeepAliveRequest(false));
    if (Utils.detectBrowser() && !isAccountScreen) {
      localStorage.setItem('vmsAuthToken', null);
      if (process.env.REACT_APP_HIDE_LOGIN === 'true') {
        navigate(`/login`);
      } else {
        navigate(`/`);
      }
    }

    if (sharedParams?.o && sharedParams?.clipId) {
      dispatch(setSelfStatus('1'));
    }

    //TODO: This commented code is under inspection. Do not remove.
    // const tokenVms = localStorage.getItem('vmsAuthToken');
    // setTimeout(() => {
    //   if (
    //     (tokenVms == 'null' || tokenVms == 'undefined') &&
    //     !isFromVerifyAccount &&
    //     !isAccountScreen
    //   ) {
    //     if (process.env.REACT_APP_HIDE_LOGIN === 'true') {
    //       navigate(`/login`);
    //     } else {
    //       navigate(`/`);
    //     }
    //   }
    // }, 3000);

    if (!isMobileDevice && moduleStatus?.NOTIFICATION) {
      const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
      };
      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      const messaging = getMessaging(app);

      onMessage(messaging, (payload) => {
        dispatch(setNotificationFlag(true));
        Utils.vmsLogger().log('payload', payload);
        if (payload?.data?.['pinpoint.notification.title']) {
          const notificationTitle =
            payload?.data?.['pinpoint.notification.title'];
          const notificationData = JSON.parse(
            payload?.data?.['pinpoint.jsonBody']
          );
          Utils.vmsLogger().log('notificationData', notificationData);

          const notificationOptions = {
            body: payload?.data?.['pinpoint.notification.body'],
            tag: payload?.data?.id,
            url: payload?.data?.url,
            icon: notificationData?.imgURL,
          };
          var notification = new Notification(
            notificationTitle,
            notificationOptions
          );
          if (
            notificationData?.nType === IncidentTypeEnum?.NOTIFICATION_TYPE &&
            notificationData?.incidentId
          ) {
            dispatch(setIncidentExportNotification(notificationData));
          }
          notification.onclick = function (event) {
            event?.preventDefault();
            if (
              notificationData?.nType ===
                AppDefaults?.POS_NOTIFICATION_STATUS_SUCCESS ||
              notificationData?.nType ===
                AppDefaults?.POS_NOTIFICATION_STATUS_PARTIALLY ||
              notificationData?.nType ===
                AppDefaults?.POS_NOTIFICATION_STATUS_FAILED ||
              notificationData?.nType === IncidentTypeEnum?.INCIDENT_ZIP_CLIP
            ) {
              navigate(`/notificationdashboard.html`);
            } else if (
              notificationData?.nType === IncidentTypeEnum?.NOTIFICATION_TYPE ||
              notificationData?.nType === IncidentTypeEnum?.INCIDENT_VIDEO_CLIP
            ) {
              if (notificationData?.incidentId) {
                navigate(
                  `/incidents/details.html?incidentId=${notificationData?.incidentId}&activeTab=1`
                );
              } else {
                navigate(`/notificationdashboard.html`);
              }
            } else if (
              notificationData?.nType === ClipTypeEnum?.NOTIFICATION_CLIP
            ) {
              navigate(
                `/clips/details.html?o=notifications&clipId=${notificationData?.clipId}`
              );
            } else if (localStorage.getItem('tokenSend')) {
              setUserPushData({});
              if (window.location.pathname === '/cameras/dashboard.html') {
                setSelectedEventStore(
                  parseFloat(notificationData?.eventTimestamp)
                );
                setEventFromSearch(true);
                setEventCVRMode(true);
                isFromVideoWall && onPushReceive(notificationData);
              } else {
                navigate(`/cameras/dashboard.html`, {
                  state: {
                    id: notificationData?.deviceId,
                  },
                });
              }
            } else {
              setUserPushData(notificationData);
            }
            window?.focus();
            notification?.close();
          };
        } else {
          const notificationTitle = payload.notification.title;
          const notificationOptions = {
            body: payload.notification.body,
            icon: payload.notification.icon,
          };
          new Notification(notificationTitle, notificationOptions);
        }
      });
      setDynamicApp(app);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('vmsAuthToken');
    if (token == 'null' || token == 'undefined' || token == '') {
      return;
    }
    if (
      dynamicApp &&
      localStorage.getItem('tokenSend') === null &&
      !isMobileDevice &&
      moduleStatus?.NOTIFICATION &&
      customerOrgDetails
    ) {
      Notification.requestPermission().then((permission) => {
        //TODO DELETE LATER
        Utils.vmsLogger().log('Notification permission: ', permission);
        if (permission?.toUpperCase() === 'GRANTED') {
          try {
            const messaging = getMessaging(dynamicApp);
            setTimeout(() => {
              getToken(messaging, {
                vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
              })
                .then((currentToken) => {
                  if (
                    localStorage.getItem('deviceToken') === null ||
                    localStorage.getItem('deviceToken') === undefined
                  ) {
                    localStorage.setItem('deviceToken', Utils.generateUUID());
                  }
                  if (
                    currentToken &&
                    customerOrgDetails?.[0]?.orgId !== undefined
                  ) {
                    sendToken(currentToken);
                  }
                })
                .catch((err) => {});
            }, 5000);
          } catch (error) {
            Utils.vmsLogger().error(error);
          }
        }
      });
    }
  }, [dynamicApp, JSON.stringify(customerOrgDetails)]);

  const sendToken = async (token) => {
    const tokenWeb = localStorage.getItem('vmsAuthToken');
    if (!tokenWeb || tokenWeb === 'undefined') {
      return;
    }
    const params = {
      enabled: true,
      name: navigator?.userAgentData?.platform
        ? navigator?.userAgentData?.platform
        : navigator?.platform,
      osVersion: navigator?.platform,
      platform: 'WEB',
      regToken: token,
      udid: `${localStorage.getItem('deviceToken') + '#vms'}`,
    };
    axios
      .put(
        `/partner/orgs/${customerOrgDetails?.[0]?.orgId}/userDevices`,
        params,
        Utils.requestHeader()
      )
      .then((res) => {
        localStorage.setItem('tokenSend', true);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const getPOCartItems = async () => {
      if (!cartFetch) {
        // Clear the cart and order
        deleteCart();
        deleteOrder();
        deleteOrgId();
        setOrderPending(false);
        setNumOfCartItems(0);
        return;
      }
      // Get current purchase order
      if (
        (orgId &&
          loggedInUserData?.orgType !== 'INSTALLER_ORG' &&
          orgId !== 'undefined') ||
        (orgDetails &&
          orgId === orgDetails?.orgId &&
          loggedInUserData?.orgType === 'INSTALLER_ORG' &&
          Object.hasOwn(orgDetails, 'role'))
      ) {
        const resp = await axios.get(
          `/user/orgs/${orgId}/orders`,
          Utils.requestHeader()
        );
        const response = resp?.data;

        if (parseInt(response?.meta?.code) === 200) {
          const orderStatus = response?.data?.orderStatus;

          if (
            orderStatus === 'PENDING_FULFILLMENT' ||
            orderStatus === 'FULFILLED'
          ) {
            // Clear the cart and order
            deleteCart();
            deleteOrder();
            deleteOrgId();
            setOrderPending(false);
            setNumOfCartItems(0);
          } else {
            setOrderPending(true);
          }
        }
      }
    };

    if (orgId && showCart) {
      getPOCartItems();
    }

    if (
      (custOrgId && loggedInUserData?.orgType !== 'INSTALLER_ORG') ||
      (orgDetails &&
        custOrgId === orgDetails?.orgId &&
        loggedInUserData?.orgType === 'INSTALLER_ORG' &&
        Object.hasOwn(orgDetails, 'role'))
    ) {
      if (
        !cdnInfo ||
        cdnInfo?.orgId !== custOrgId ||
        cdnInfo['signed-cookies']['CloudFront-Expires'] - new Date().getTime() <
          900000
      ) {
        if (authToken !== 'undefined') {
          callCDNApi();
        }
      }

      cdnAPIInterval.current = setInterval(() => {
        callCDNApi();
      }, 1000 * 60 * 50);
    }

    return () => {
      // if (cdnInfo?.orgId !== custOrgId && authToken !== 'undefined') {
      // }
      if (cdnAPIInterval.current) {
        clearInterval(cdnAPIInterval.current);
      }
    };
  }, [orgId, orgDetails?.orgId]);

  useEffect(() => {
    if (
      Array.isArray(customerOrgDetails) &&
      customerOrgDetails?.length > 0 &&
      getMQTTConnectionStatus
    ) {
      subscribeWithOrgIds(customerOrgDetails);

      const deviceSubscription = {
        topic: `d/notify/${accountId}/${state.getSessionId()}`,
        qos: 0,
      };
      Utils.vmsLogger().log(
        'TOPICSSUB subscribe topic header js',
        deviceSubscription?.topic
      );
      // Subscribe  to the device topic
      mqttSubscribe(deviceSubscription);
      const devices = customerOrgDetails?.[0]?.devices;

      const areas = [...new Set(devices?.map((obj) => obj.areaId))];
      dispatch(setAllAreas(areas));
      if (!state.getSessionId()) {
        state.setSessionId(uuidv4());
      }
    }
    if (loggedInUserData) {
      subscribeWithAccountId(loggedInUserData?.accountId);
      dispatch(setAccountId(loggedInUserData?.accountId));
    }
  }, [getMQTTConnectionStatus, loggedInUserData]);

  // TODO: Below code is in observation
  useEffect(() => {
    const tokenWeb = localStorage.getItem('vmsAuthToken');
    if (!tokenWeb || tokenWeb === 'undefined') {
      return;
    }
    const isMqttTokenExpired =
      platformDetails?.mqtt?.expiry * 1000 < new Date().getTime();

    if (
      !checkMQTTConnection() &&
      accountId &&
      platformDetails &&
      platformDetails?.mqtt?.token &&
      !isMqttTokenExpired
    ) {
      if (!checkMQTTConnection() && accountId) {
        connectWithMQTT(accountId);
      }
    } else if (
      accountId &&
      ((platformDetails &&
        (isMqttTokenExpired || !platformDetails?.mqtt?.token)) ||
        !platformDetails)
    ) {
      getLatestPlatformData();
    }
  }, [accountId, checkMQTTConnection(), platformDetails]);

  const getLatestPlatformData = () => {
    axios
      .get(`/partner/platform/v2`, {
        withCredentials: true,
        ...Utils.requestHeader(),
      })
      .then((response) => {
        if (response?.data?.data) {
          dispatch(setPlatformInfo(response?.data?.data));
        }
      });
  };

  const callCDNApi = async () => {
    try {
      await axios
        .get(`/partner/orgs/${custOrgId}/cdn`, {
          params: {
            requestTime: Date.now(),
            requestId: uuidv4(),
          },
          withCredentials: true,
          ...Utils.requestHeader(),
        })
        .then((response) => {
          if (response?.data?.data) {
            let info = response.data?.data?.cdn;
            info['orgId'] = custOrgId;
            dispatch(setCDNInfo(info));
          }
        })
        .catch((err) => {
          Utils.vmsLogger().log('cdn api error', err);
        });
    } catch (error) {}
  };

  useEffect(() => {
    if (numOfICartItems === null || numOfICartItems === undefined) {
      let currentNumOfCartItems = getCartTotalQuantity();

      if (currentNumOfCartItems > 0) {
        setNumOfCartItems(currentNumOfCartItems);
      }
    } else {
      setNumOfCartItems(numOfICartItems);
    }
  }, [getCartTotalQuantity, numOfICartItems, orgId]);

  useEffect(() => {
    let requestUrl = location.pathname.replace(/\/+$/, '');
    setUrl(requestUrl);
  }, [location]);

  useEffect(() => {
    if (selfAccountData?.orgType) {
      setLoggedInUserData(selfAccountData);
    }
  }, [selfAccountData]);

  //=== Update the device data based on MQTT response data
  useEffect(() => {
    const handleMqttData = async () => {
      let modalStatus = parseInt(localStorage.getItem('modalOpen'));
      if (allMqttData !== null && mqttUpdateStatus && modalStatus !== 1) {
        //=== Update Customer Org Page Devices Data
        if (customerOrgDetails?.length > 0) {
          let updateCustData =
            ManageMqttData.updateCustomerOrgDevicesDataByMqtt(
              customerOrgDetails,
              allMqttData
            );
          await setCustomerOrgData(updateCustData || customerOrgDetails);
        }
        //=== Update Devices Data
        ManageMqttData.updateDeviceDataByMqtt(allMqttData, currentDeviceId);
        dispatch(setMqttUpdateStatusFromResponse(false));
      }
    };
    handleMqttData();
  }, [allMqttData, mqttUpdateStatus]);

  const invalidateSessionApi = () => {
    const reqBody = {
      refresh_token: keycloak?.refreshToken,
    };
    axios
      .post('/user/token/invalidate/app', reqBody, Utils.requestHeader())
      .then(() => {
        handleSiteLogout();
      });
  };

  /* On click submit button - send request to approve the customer accounts API */
  const handleSiteLogout = async (e) => {
    await setAllDevicesDataNotification([]);
    dispatch(setSelfStatus('0'));
    dispatch(clearWSSConnections());
    dispatch(clearRTCPeerConnections());
    dispatch(clearLiveStreams());
    dispatch(clearMQTTPeerConnections());
    dispatch(clearRemoteStreams());
    dispatch(clearRemoteStreamsAudio());
    dispatch(resetCDNInfo());
    dispatch(resetPlatformInfo());
    await resetLiveViewDevices();
    localStorage.setItem('authenticated', false);
    localStorage.setItem('vmsAuthToken', null);
    localStorage.removeItem('tokenSend');
    localStorage.setItem('isAmplitudeSingleCall', false);
    localStorage.setItem('uploadStatus', '0');
    await resetCustomerOrgData();
    resetNotificationData();
    resetDeviceSnapshots();
    resetCustomerOrgLocations();
    await resetCameraWallDevices();
    resetLoggedInUserData();
    resetDeviceWallSelectedLocation();
    resetDeviceWallSelectedArea();
    resetPosStore();
    dispatch(resetSelfAccountData());
    resetAllUserPolicies();
    dispatch(resetPolicies());
    dispatch(resetViewsList());
    if (cdnAPIInterval.current) {
      clearInterval(cdnAPIInterval.current);
    }
    keycloak.logout({
      redirectUri: process.env.REACT_APP_KEYCLOAK_LOGOUT_REDIRECT_URL,
    });
    setPingApiCallTime(0);
    if (allAreasStore) {
      dispatch(setAllAreas([]));
    }

    dispatch(setSubscribeAreasRequest(false));
    dispatch(setKeepAliveRequest(false));
    mqttDisconnectRequest();
  };

  // TODO: move this in the component that calls this Header component
  // and define it as a prop for Header
  const createPurchaseOrder = async () => {
    const token = localStorage.getItem('vmsAuthToken');
    if (!token) {
      return;
    }
    try {
      let order = [];
      let poRes, response;

      cart.forEach((license) => {
        order.push({
          productSku: license.sku,
          price: license.discountedPrice,
          quantity: license.quantity,
        });
      });

      const reqBody = {
        currency: 'usd',
        orderDetails: [...order],
      };

      // Check
      if (pendingOrder) {
        poRes = await axios.put(
          `/user/orgs/${orgId}/orders`,
          reqBody,
          Utils.requestHeader()
        );
      } else {
        // create purchase order
        poRes = await axios.post(
          `/user/orgs/${orgId}/orders`,
          reqBody,
          Utils.requestHeader()
        );
      }

      response = poRes.data;

      if (parseInt(response?.meta?.code) === 200) {
        setOrgId(orgId);
        setOrder(response.data);
        navigate(`/purchase/summary.html?orgId=${orgId}&orgName=${orgName}`);
      }
    } catch (error) {
      /*
       * TODO: figure out what to do with the error because the Header
       * component should not issue a toaster component
       */
      Utils.vmsLogger().log(error);
    }
  };

  //=== Check if Customers exists in the breadcrumb then make the customer nav link active
  const checkBreadcrumbForCustomers = () => {
    let breadExists = false;
    if (Array.isArray(breadcrumbData) && breadcrumbData.length > 0) {
      breadExists = !!breadcrumbData.find(
        (breadData) => breadData.title === constants.MANAGE_ORG_PAGE_TITLE
      );
    }
    return breadExists;
  };

  // useEffect(() => {
  //   setSelfStatusFlag(getSelfStatus());
  // }, [getSelfStatus()]);

  const setNavLinkClasses = (isActive) => {
    let classes = '';
    if (isActive) {
      classes = 'active nav-link';
    } else {
      classes = 'left-link nav-link';
    }
    if (loggedInUserData?.accountStatus === 'INACTIVE') {
      classes = `${classes} disabled`;
    }
    return classes;
  };

  const createSession = (redirectUrl) => {
    const currentTime = Math.floor(new Date().getTime() / 1000);
    const expireIn = keycloak?.tokenParsed?.exp - currentTime;
    const refreshExpireIn = keycloak?.refreshTokenParsed?.exp - currentTime;
    const reqBody = {
      expires_in: expireIn,
      refresh_expires_in: refreshExpireIn,
      refresh_token: keycloak?.refreshToken,
    };
    const config = {
      credentials: 'include',
      withCredentials: true,
      ...Utils.requestHeader(),
    };
    axios.post('user/session', reqBody, config).then((res) => {
      if (res.status === 200) {
        window.open(redirectUrl, '_blank');
      }
    });
  };

  const handleNavigation = async (appData) => {
    if (appData) {
      let redirectUrl = '';
      if (appData.id === constants.DEVICES_LIST_APPLICATION_APP_TYPE_PORTAL) {
        if (loggedInUserData?.orgType === AppDefaults.ORG_TYPE_INSTALLER) {
          redirectUrl = `${appData?.url}/customers/dashboard.html?orgId=${orgDetails?.orgId}&orgName=${orgDetails?.orgName}`;
        } else {
          redirectUrl = `${appData?.url}/customers/dashboard.html?orgId=${customerOrgDetails[0]?.orgId}&orgName=${customerOrgDetails[0]?.orgName}`;
        }
      } else {
        if (loggedInUserData?.orgType === AppDefaults.ORG_TYPE_INSTALLER) {
          redirectUrl = `${appData?.url}?orgId=${orgDetails?.orgId}`;
        } else {
          redirectUrl = `${appData?.url}?orgId=${customerOrgDetails[0]?.orgId}`;
        }
      }
      await createSession(redirectUrl);
    }
  };
  const isAppsIcon = () => {
    if (
      url !== '/customers/manage.html' &&
      url !== managePartnerUrl &&
      url !== '/organizationsettings.html' &&
      url !== '/accountsettings.html' &&
      url !== '/updatepassword.html'
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    showHeader && (
      <>
        <TermConditionPolicyModal callback={handleSiteLogout} />
        <Navbar
          collapseOnSelect
          sticky="top"
          expand="lg"
          className={`site-top-bar ${selfStatus !== '1' && 'disabled'}`}
          style={{ backgroundColor: backgroundColor }}
        >
          <Container className="site-top-bar-container" fluid>
            <Navbar.Brand
              href={`${
                process.env.REACT_APP_PROJECT !== AppDefaults.PROJECT_MEGATRON
                  ? '/customers/manage.html'
                  : ''
              }`}
            >
              {process.env.REACT_APP_PROJECT ===
              AppDefaults.PROJECT_MEGATRON ? (
                <MegatronLogoSvg />
              ) : (
                <BrandLogoSvg />
              )}
            </Navbar.Brand>
            {showNavigation && (
              <>
                <Nav className="me-auto site-start-links">
                  {loggedInUserData?.orgType !==
                    AppDefaults.ORG_TYPE_CUSTOMER && (
                    <>
                      <NavLink
                        to={'/customers/manage.html'}
                        className={({ isActive }) =>
                          setNavLinkClasses(
                            isActive ||
                              location.pathname.startsWith('/customers/')
                          )
                        }
                      >
                        {constants.TOP_HEADER_CUSTOMERS_NAV_TITLE}
                      </NavLink>
                    </>
                  )}
                  {loggedInUserRole === roles.ROLE4VMS &&
                    getLoggedInUserPolicies()?.create_account && (
                      <NavLink
                        to={'/managepartnerusers.html'}
                        className={({ isActive }) =>
                          setNavLinkClasses(isActive)
                        }
                      >
                        {constants.TOP_HEADER_TECHS_NAV_TITLE}
                      </NavLink>
                    )}
                  {loggedInUserData?.orgType ===
                    AppDefaults.ORG_TYPE_CUSTOMER && (
                    <>
                      <NavLink
                        to={`/cameras/devices.html`}
                        className={({ isActive }) =>
                          setNavLinkClasses(
                            isActive ||
                              location.pathname.startsWith(
                                '/cameras/devices.html'
                              )
                          )
                        }
                      >
                        {constants.TOP_HEADER_DEVICE_WALL_NAV_TITLE}
                      </NavLink>
                      {moduleStatus?.EVENTS && (
                        <Nav.Link
                          onClick={() => {
                            setNavigatedFromCameraDetail(false);
                            navigate('/events.html');
                          }}
                          className={`${
                            url === '/events.html' ? 'active' : 'left-link'
                          } ${
                            loggedInUserData?.accountStatus === 'INACTIVE' &&
                            'disabled'
                          }`}
                        >
                          {constants.TOP_HEADER_EVENTS_NAV_TITLE}
                        </Nav.Link>
                      )}
                    </>
                  )}
                  {loggedInUserData?.orgType ===
                    AppDefaults.ORG_TYPE_CUSTOMER &&
                    moduleStatus?.INCIDENT_MANAGEMENT && (
                      <NavLink
                        to={'/clips/list.html'}
                        className={({ isActive }) =>
                          setNavLinkClasses(
                            isActive || location.pathname.startsWith('/clips/')
                          )
                        }
                      >
                        {constants.TOP_HEADER_CLIPS_NAV_TITLE}
                      </NavLink>
                    )}
                  {(getLoggedInUserPolicies()?.view_all_incident ||
                    getLoggedInUserPolicies()?.view_created_incident ||
                    getLoggedInUserPolicies()?.view_shared_incident) &&
                    moduleStatus?.INCIDENT_MANAGEMENT && (
                      <NavLink
                        to={'/incidents/manage.html'}
                        className={({ isActive }) =>
                          setNavLinkClasses(
                            isActive ||
                              location.pathname.startsWith('/incidents/')
                          )
                        }
                      >
                        {constants.TOP_HEADER_INCIDENTS_NAV_TITLE}
                      </NavLink>
                    )}

                  {loggedInUserData?.orgType ===
                    AppDefaults.ORG_TYPE_CUSTOMER &&
                    getLoggedInUserPolicies()?.view_metrics &&
                    moduleStatus?.OPERATION_METRICES && (
                      <NavLink
                        to={'/reports.html'}
                        className={({ isActive }) =>
                          setNavLinkClasses(isActive)
                        }
                      >
                        {constants.TOP_HEADER_ANALYTICS_NAV_TITLE}
                      </NavLink>
                    )}
                </Nav>
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={
                    <Popover id="popover-basic">
                      <Popover.Body className="px-0 pt-2">
                        <Row className="g-2">
                          {appList?.map((item, index) => (
                            <Col
                              key={`${index}-${item.name}`}
                              xs={4}
                              className="text-center"
                            >
                              <NavDropdown.Item
                                onClick={() => handleNavigation(item)}
                              >
                                <div>
                                  <img
                                    className="appIconImgHeight"
                                    src={item.wicon}
                                  />
                                </div>
                                <div className="appsMenuNameStyle">
                                  <span>{item.name}</span>
                                </div>
                              </NavDropdown.Item>
                            </Col>
                          ))}
                        </Row>
                        <NavDropdown.Divider />
                        <Row className="nav-row-top-border mx-0 pt-3">
                          <Col xs={12} className="px-0">
                            <NavDropdown.Item
                              className="helpStyle"
                              onClick={() => {
                                window.open(helpData?.common, '_blank');
                              }}
                            >
                              <span>{constants.HELP}</span>
                              <span>
                                <img src={greaterThanIcon} />
                              </span>
                            </NavDropdown.Item>
                          </Col>
                        </Row>
                      </Popover.Body>
                    </Popover>
                  }
                  rootClose={true}
                  className="profile-nav right-link"
                >
                  <Nav.Link
                    onClick={() => getApps(`partner/apps`)}
                    className={`profile-nav-icon-2 ${
                      isAppsIcon() ? 'd-block' : 'd-none'
                    }`}
                  >
                    {loggedInUserData?.accountStatus !== 'INACTIVE' && (
                      <BsFillGrid3X3GapFill />
                    )}
                  </Nav.Link>
                </OverlayTrigger>
                <Nav className={`site-end-links`}>
                  {/*TODO DELETE LATER {(loggedInUserRole === roles.ROLE2VMS ||
                  loggedInUserRole === roles.ROLE3VMS) && <LocationSelector />} */}
                  {showCart &&
                  moduleStatus?.PRODUCT_LISTING &&
                  !isNaN(numOfCartItems) &&
                  numOfCartItems > 0 ? (
                    <Nav.Link
                      href="#"
                      className={numOfCartItems < 1 ? 'hidden' : 'right-link'}
                      onClick={() => createPurchaseOrder()}
                    >
                      <div className="site-end-links-cart">
                        <IconContext.Provider
                          value={{
                            size: '0.5',
                            className: 'site-end-links-cart-icon',
                          }}
                        >
                          <HiOutlineShoppingCart size={0.5} />
                        </IconContext.Provider>
                        <div className="site-end-links-cart-items">
                          ({numOfCartItems})
                        </div>
                      </div>
                    </Nav.Link>
                  ) : null}
                  {loggedInUserData?.orgType ===
                    AppDefaults.ORG_TYPE_CUSTOMER && (
                    <NavLink
                      className={({ isActive }) =>
                        `${setNavLinkClasses(
                          isActive ||
                            location.pathname.startsWith('/notification')
                        )} right-link`
                      }
                      to={'/notificationdashboard.html'}
                      onClick={() => {
                        dispatch(setNotificationFlag(false));
                      }}
                    >
                      <div className="profile-nav-icon notification-background">
                        {notificationFlag && (
                          <div className="has-notification"></div>
                        )}
                        <HiOutlineBell size={18} />
                      </div>
                    </NavLink>
                  )}
                  {getLoggedInUserPolicies()?.view_cust_org_dashboard &&
                    loggedInUserData?.orgType ===
                      AppDefaults.ORG_TYPE_CUSTOMER && (
                      <Nav.Link
                        onClick={() => {
                          if (
                            Array.isArray(customerOrgDetails) &&
                            customerOrgDetails?.[0]
                          ) {
                            let policies = customerOrgDetails?.[0]?.policies;
                            let updatedPoliciesData = Utils.mapUserPolicies(
                              DefaultPolicies,
                              policies
                            );
                            setCustomerOrgPolicies(updatedPoliciesData);
                          }
                          Utils.vmsLogger().log('orgInfoHeader', orgInfo);
                          orgInfo &&
                            navigate(
                              `/customers/dashboard.html?orgId=${orgInfo?.orgId}&orgName=${orgInfo?.orgName}`
                            );
                        }}
                        className={`right-link ${
                          location.pathname.startsWith('/customers/') &&
                          'active'
                        } ${
                          loggedInUserData?.accountStatus === 'INACTIVE' &&
                          'disabled'
                        }`}
                      >
                        <div className={`profile-nav-icon`}>
                          <HiOutlineOfficeBuilding size={18} />
                        </div>
                      </Nav.Link>
                    )}

                  <NavDropdown
                    className="profile-nav right-link"
                    title={
                      <div className="profile-nav-icon">
                        <TbSettings size={18} />
                      </div>
                    }
                    id="collasible-nav-dropdown"
                  >
                    <NavDropdown.Item
                      href="#"
                      className="logged-in-user right-link"
                    >
                      <div className="loggedin-user-as">
                        Signed in as
                        <div className="loggedin-user-title">
                          {`${
                            loggedInUserData?.email
                              ? loggedInUserData?.email
                              : ''
                          }`}
                        </div>
                      </div>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className={`${
                        location.pathname.startsWith('/accountsettings') &&
                        'active'
                      } ${
                        loggedInUserData?.accountStatus === 'INACTIVE' &&
                        'inactive-user-setting'
                      }`}
                      onClick={() => navigate('/accountsettings.html')}
                    >
                      <span className="icon">
                        <HiOutlineUser />
                      </span>
                      {constants.TOP_HEADER_ACCOUNT_SETTINGS_NAV_TITLE}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className={`${
                        location.pathname.startsWith('/organizationsettings') ||
                        location.pathname.startsWith('/editOrganization')
                          ? 'active'
                          : ''
                      } ${
                        loggedInUserData?.accountStatus === 'INACTIVE' &&
                        'inactive-user-setting'
                      }`}
                      onClick={() => {
                        if (loggedInUserData?.orgType === 'CUSTOMER_ORG') {
                          navigate(
                            `/editOrganization.html?orgId=${loggedInUserData?.orgId}`
                          );
                        } else {
                          navigate('/organizationsettings.html');
                        }
                      }}
                    >
                      <span className="icon">
                        <TbSettings />
                      </span>
                      {constants.TOP_HEADER_ORG_SETTINGS_NAV_TITLE}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className={`${
                        location.pathname.startsWith('/updatepassword')
                          ? 'active'
                          : ''
                      } ${
                        loggedInUserData?.accountStatus === 'INACTIVE' &&
                        'inactive-user-setting'
                      }`}
                      onClick={() => navigate('/updatepassword.html')}
                    >
                      <span className="icon">
                        <HiOutlineLockClosed />
                      </span>
                      {constants.TOP_HEADER_UPDATE_PASSWORD_NAV_TITLE}
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={invalidateSessionApi}>
                      <span className="icon">
                        <HiOutlineLogout />
                      </span>
                      {constants.TOP_HEADER_LOGOUT_SETTINGS_NAV_TITLE}
                    </NavDropdown.Item>
                    <p className="version-info">
                      <span className="title">
                        {constants.RELEASE_VERSION_TITLE}
                      </span>
                      <span>{app_version}</span>
                    </p>
                  </NavDropdown>
                </Nav>
              </>
            )}
          </Container>
        </Navbar>
      </>
    )
  );
}

Header.defaultProps = {
  backgroundColor: getComputedStyle(document.documentElement).getPropertyValue(
    '--brand_white'
  ),
};

export default Header;
