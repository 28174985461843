import { Card } from 'react-bootstrap';
import './MetricCard.scss';
import { constants, Utils } from '../../../src/helpers';
import Avatar from './Avatar';
import { nameInitials } from '../../helpers/commonUtils';

const MetricCard = ({
  analyticsTitle,
  metricHighlight,
  metricsArray,
  isFromUserLogin = false,
}) => {
  const getAvatar = (metric) => {
    const isValidURL =
      metric?.image && Utils.checkURLValidity(metric?.image?.url);
    const avatarValueType = isValidURL ? 'icon' : 'text';
    const avatarValue = isValidURL
      ? metric?.image?.url
      : nameInitials(metric?.text);

    return (
      <Avatar
        valueType={avatarValueType}
        value={avatarValue}
        size="small"
        avatarStyle="roundedCircle"
      />
    );
  };

  return (
    <Card className="metric-container">
      <Card.Body>
        <Card.Title>
          <div className="title-wrapper">
            <div className="title-data">
              {!Number.isNaN(metricHighlight.value) ? metricHighlight.value : 0}
            </div>
            <div className="title-description">
              {metricHighlight.description}
            </div>
          </div>
        </Card.Title>
        <div className="metric-body">
          {Array.isArray(metricsArray) &&
            metricsArray.map((metric, metricIndex) => (
              <div key={`metric-${metricIndex}`} className="body-wrapper">
                <div className="metric-source">
                  <div className="metric-container">
                    {isFromUserLogin && getAvatar(metric)}
                    <div className="source-text">{metric.text}</div>
                  </div>
                  <div className="source-subtext">{metric.subtext}</div>
                </div>
                {analyticsTitle ===
                  constants.ANALYTICS_DASHBOARD_CAMERA_AVAILABILITY_SECTION_TITLE ||
                analyticsTitle ===
                  constants.ANALYTICS_DASHBOARD_CAMERA_MINUTES_UNAVAILABLE_SECTION_TITLE ? (
                  <div className={`metric-data cameraAvailability`}>
                    {!Number.isNaN(metric.value) ? metric.value : 0}
                  </div>
                ) : (
                  <div className={`metric-data ${metric.color}`}>
                    {!Number.isNaN(metric.value) ? metric.value : 0}
                  </div>
                )}
              </div>
            ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export default MetricCard;
