import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimeField, TimePicker } from '@mui/x-date-pickers';
import DatePicker from 'react-multi-date-picker';
import InputIcon from 'react-multi-date-picker/components/input_icon';

import moment from 'moment-timezone';

import {
  AccordionList,
  MaterialSelectList,
  Tags,
} from '../../../components/common';

import { constants, Utils } from '../../../helpers';
import incidentStatusMap from '../shared/IncidentStatusMap';

const DetailsTab = ({
  selectedIncident,
  statusClosed,
  formData,
  availableTags,
  tags,
  setTags,
  currentLocationAreas,
  incidentTimezones,
  disableFutureTime,
  tabChangeHandler,
  fetchTimezoneLocation,
  handleInputChange,
  setShowEditTagsModal,
  setShowStatusLogModal,
  setIncidentTimeZone,
  setIncidentDate,
  setIncidentDateTime,
  combineDateTime,
  setLocationId,
  setAreaId,
  setHasPendingChanges,
  areAllChangesReverted,
  ...props
}) => {
  const {
    register,
    formState: { errors },
  } = useForm();
  const [currentLocationId, setCurrentLocationId] = useState(
    selectedIncident.locationId
  );
  const [currentAreaId, setCurrentAreaId] = useState(selectedIncident.areaId);
  const [currentTimezone, setCurrentTimezone] = useState(
    Utils.getTimezone(selectedIncident?.timezone, 'olson')
  );
  const [currentTimezoneLocation, setCurrentTimezoneLocation] = useState(
    Utils.getTimezone(selectedIncident?.timezone, 'olson')
  );
  const [incidentTags, setIncidentTags] = useState([...selectedIncident?.tags]);

  const [currentDate, setCurrentDate] = useState(
    Utils.convertTimezoneDateToLocalDate(
      selectedIncident?.incidentDateTime,
      selectedIncident?.timezone
    )
  );
  const [currentTime, setCurrentTime] = useState(
    moment(
      Utils.convertTimezoneDateToLocalDate(
        selectedIncident?.incidentDateTime,
        selectedIncident?.timezone
      )
    )
  );
  const incidentTimezoneLocation = Utils.getTimezone(
    selectedIncident?.timezone,
    'olson'
  );

  const convertTimezone = (toTimezone) => {
    if (!toTimezone) return;

    let dateTime = combineDateTime(currentDate, currentTime);

    const convertedDateTime = moment
      .tz(dateTime, toTimezone)
      .format('YYYY-MM-DDTHH:mm:ss');

    return convertedDateTime;
  };

  const adjustDateTimeToTimezone = (newTimezone) => {
    if (!newTimezone) return;

    const newLocation = getTimezoneLocationByValue(newTimezone);

    // Get the Moment object for the converted date/time
    const convertedDateTime = convertTimezone(newTimezone);

    if (convertedDateTime) {
      // Set Date
      setCurrentDate(moment.tz(convertedDateTime, newLocation).valueOf());
      setIncidentDate(currentDate);

      // Set Date Time
      setCurrentTime(convertedDateTime);
      setIncidentDateTime(convertedDateTime);
      setCurrentTimezone(newTimezone);
      setCurrentTimezoneLocation(newLocation);
    }
  };

  const getTimezoneLocationByValue = (timezoneValue) => {
    if (!timezoneValue) return;

    return Utils.getTimezone(timezoneValue, 'olson');
  };

  const updateTimeZoneFromLocation = (locationId) => {
    let selectedLocation, selectedTimezone;

    if (!locationId || currentLocationAreas?.length < 1) return;

    selectedLocation = currentLocationAreas.find(
      (location) => location.listItemId === locationId
    );

    if (selectedLocation) {
      selectedTimezone = selectedLocation?.listItemTimezone;

      setCurrentTimezone(selectedTimezone);
      setCurrentTimezoneLocation(selectedTimezone);
      adjustDateTimeToTimezone(selectedTimezone);
      setIncidentTimeZone && setIncidentTimeZone(selectedTimezone);
      if (formData) {
        formData.timezone = selectedTimezone;
      }
    }
  };

  const handleDateChange = (newDate) => {
    if (!newDate) return;

    setIncidentDate(newDate);

    const incidentDateTime = moment.tz(currentDate, currentTimezone);

    if (formData) {
      formData.incidentDateTime = combineDateTime(
        new Date(newDate),
        new Date(incidentDateTime)
      );
    }
    setCurrentDate(new Date(newDate).getTime());

    setHasPendingChanges && setHasPendingChanges(true);
  };

  const handleTimeChange = (newTime) => {
    if (!newTime) return;

    const newTimeMoment = moment(newTime._d);

    const incidentDateTime = new Date(newTimeMoment);

    if (formData) {
      formData.incidentDateTime = combineDateTime(
        currentDate,
        incidentDateTime?.valueOf()
      );
    }

    setCurrentTime(newTimeMoment?.valueOf());
    setCurrentDate(newTimeMoment?.valueOf());
    setIncidentDateTime(newTimeMoment?.valueOf());

    setHasPendingChanges && setHasPendingChanges(true);
  };

  const handleAddTag = (tagsArray) => {
    let updatedTags = incidentTags;
    let newTags;

    if (!Array.isArray(tagsArray)) return;

    newTags = [...updatedTags.concat(tagsArray)];
    setIncidentTags([...newTags]);
    setTags && setTags([...newTags]);

    if (formData) {
      formData.tags = [...newTags];
    }
    setHasPendingChanges && setHasPendingChanges(true);
  };

  const handleRemoveTag = (tagId) => {
    const currentIncidentTagIds = incidentTags;

    if (!tagId) return;

    const tagIndex = currentIncidentTagIds.findIndex(
      (currentTagId) => currentTagId === tagId
    );

    if (tagIndex !== -1) {
      currentIncidentTagIds.splice(tagIndex, 1);
      setIncidentTags([...currentIncidentTagIds]);
      setTags && setTags([...currentIncidentTagIds]);

      if (formData) {
        formData.tags = [...currentIncidentTagIds];
      }

      setHasPendingChanges && setHasPendingChanges(true);
    }
  };

  return (
    <>
      {/* Incident Creator Info */}
      <Row>
        <Col>
          <div className="d-flex justify-content-start mt-4 mb-4">
            <span className="incident-details-short-summary">
              {Utils.replaceStringValues(
                constants.INCIDENTS_DETAILS_CREATED_BY_TEXT,
                '$fullName',
                selectedIncident.actorName
                  ? selectedIncident.actorName
                  : constants.INCIDENT_DETAIL_REMOVE_USER_TEXT
              )}{' '}
              {Utils.replaceStringValues(
                constants.INCIDENTS_DETAILS_CREATED_ON_TEXT,
                '$dateTimeZone',
                moment
                  .tz(selectedIncident?.createdDate, currentTimezoneLocation)
                  .format('MMM DD, YYYY h:mm:ss A')
              )}{' '}
              {moment
                .tz(selectedIncident?.createdDate, currentTimezoneLocation)
                .zoneAbbr()}
            </span>
          </div>
        </Col>
      </Row>

      {/* Sumnary */}
      <Row className="">
        <Col>
          <div className="mb-2 summary-input-wrapper">
            <input
              {...register('summary', {
                required: true,
              })}
              name="summary"
              maxLength={120}
              onChange={(e) => {
                handleInputChange && handleInputChange(e);
                setHasPendingChanges && setHasPendingChanges(true);
              }}
              placeholder={selectedIncident?.summary}
              value={formData?.summary}
              className="incident-input summary-input"
              readOnly={statusClosed}
            />
            {errors.summary && (
              <div>
                {constants.INCIDENTS_MODAL_NEW_INCIDENT_SPECIFY_SUMMARY_ERROR}
              </div>
            )}
          </div>
        </Col>
      </Row>
      {/* Description */}
      <Row>
        <Col>
          <div className="mb-3 description-input-wrapper">
            <input
              {...register('details', {
                required: true,
              })}
              name="description"
              onChange={(e) => {
                handleInputChange && handleInputChange(e);
                setHasPendingChanges && setHasPendingChanges(true);
              }}
              placeholder={selectedIncident?.description}
              value={formData?.description}
              className="incident-input details-input"
              readOnly={statusClosed}
            />
            {errors.details && (
              <div>
                {constants.INCIDENTS_MODAL_NEW_INCIDENT_SPECIFY_DETAILS_ERROR}
              </div>
            )}
          </div>
        </Col>
      </Row>
      {/* Status */}
      <Row>
        <Col>
          <div className="mb-1 text-left incident-details-form-label incident-details-status">
            {constants.INCIDENTS_DETAILS_STATUS_TITLE}
          </div>
          <div className="mb-2 incident-details-status-wrapper">
            {!selectedIncident?.resolution
              ? incidentStatusMap[selectedIncident?.status]
              : incidentStatusMap[
                  `${selectedIncident?.status.toUpperCase()}_${
                    selectedIncident?.resolution
                  }`
                ]}
            <div className="incident-details-status-detail incident-details-status-date">
              {moment
                .tz(
                  selectedIncident?.incidentDateTime,
                  selectedIncident?.timezone
                )
                .format('lll')}
              &nbsp;
              <span>
                {selectedIncident?.timezone &&
                  moment
                    .tz(
                      selectedIncident?.incidentDateTime,
                      incidentTimezoneLocation
                    )
                    .zoneAbbr()}
              </span>
            </div>
            <div className="incident-details-status-detail incident-details-status-actor">
              By{' '}
              {selectedIncident?.updateActorName || selectedIncident?.actorName
                ? selectedIncident.actorName
                : constants.INCIDENT_DETAIL_REMOVE_USER_TEXT}{' '}
              {selectedIncident?.actorName && (
                <>
                  {' '}
                  {selectedIncident?.updateActorEmail ||
                    selectedIncident?.actorEmail}
                </>
              )}
            </div>
          </div>
        </Col>
        <Col className="d-flex justify-content-end">
          <span
            className="incident-details-label-action"
            onClick={() => setShowStatusLogModal(true)}
          >
            {constants.INCIDENTS_VIEW_STATUS_LOG_LINK_LABEL}
          </span>
        </Col>
      </Row>
      {selectedIncident?.statusNote ? (
        <div className="mb-2 incident-details-status-note">
          {selectedIncident?.statusNote}
        </div>
      ) : null}
      {/* Tags */}
      <Row>
        <Col>
          <div className="mb-3 text-left incident-details-form-label incident-details-tags">
            {constants.INCIDENTS_TAGS_TITLE}
          </div>
          <div className="incident-details-tags-wrapper">
            <Tags
              tagCategory="IM"
              fullWidth={true}
              systemTagsOnly={false}
              preSelectedTags={selectedIncident?.tags}
              addTagsCallback={handleAddTag}
              removeTagCallback={handleRemoveTag}
              isReadOnly={statusClosed}
              areAllChangesReverted={areAllChangesReverted}
            />
          </div>
        </Col>
      </Row>
      {/* Location */}
      <Row>
        <Col>
          <div className="mb-2 incident-details-form-label incident-details-location">
            {constants.INCIDENTS_DETAILS_LOCATION_TITLE}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mb-4 incident-details-location-list">
            <AccordionList
              containerClass="incident-details-locations"
              listItems={currentLocationAreas}
              selectedListItemId={currentLocationId}
              selectedSublistId={currentAreaId}
              chevronColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--greyscale_56')}
              disabled={statusClosed}
              onChangeCallback={(listItemId, sublistItemId) => {
                if (!listItemId || !sublistItemId) return;

                updateTimeZoneFromLocation(listItemId);
                setCurrentLocationId(listItemId);
                setCurrentAreaId(sublistItemId);

                setLocationId(listItemId);
                setAreaId(sublistItemId);

                if (formData) {
                  formData.locationId = listItemId;
                  formData.areaId = sublistItemId;
                }

                setHasPendingChanges && setHasPendingChanges(true);
              }}
            />
          </div>
        </Col>
      </Row>
      {/* Incident Location Timezone */}
      <Row>
        <Col>
          <div className="mb-2 incident-details-form-label incident-details-timezone">
            {constants.INCIDENTS_DETAILS_INCIDENT_LOCATION_TIMEZONE_TITLE}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mb-4 timezone-input-wrapper">
            <MaterialSelectList
              {...register('timezone', {
                required: false,
              })}
              options={[...incidentTimezones]}
              placeholder="Time Zone GMT"
              label="Time Zone GMT"
              defaultValue={currentTimezone}
              updatedValue={currentTimezone}
              disabled={true}
              iconComponent={() => null}
              callback={(value) => {
                if (!value) return;

                setCurrentTimezone(value);
                setIncidentTimeZone && setIncidentTimeZone(value);

                if (formData) {
                  formData.timezone = value;
                }

                setHasPendingChanges(true);
              }}
              areAllChangesReverted
            />
            {errors.timezone && (
              <div>
                {constants.INCIDENTS_MODAL_NEW_INCIDENT_SPECIFY_DETAILS_ERROR}
              </div>
            )}
          </div>
        </Col>
      </Row>
      {/* Incident Date */}
      <Row>
        <Col>
          <div className="mb-2 incident-details-form-label incident-details-date">
            {constants.INCIDENTS_DETAILS_INCIDENT_DATE_TITLE}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mb-3 incident-details-date-wrapper">
            <DatePicker
              className="incident-details-date customized-datepicker"
              containerClassName="incident-details-date-container"
              format="MM/DD/YYYY"
              placeholder="MM/DD/YYYY"
              maxDate={new Date().setHours(23, 59, 59, 59)}
              value={currentDate}
              onChange={handleDateChange}
              PopperProps={{
                placement: 'bottom-end',
              }}
              render={<InputIcon className="incident-details-cal-input" />}
              editable={!statusClosed}
              readOnly={statusClosed}
              disabled={statusClosed}
            />
          </div>
        </Col>
      </Row>
      {/* Incident Time */}
      <Row>
        <Col>
          <div className="mb-2 incident-details-form-label incident-details-time">
            {constants.INCIDENTS_DETAILS_INCIDENT_TIME_TITLE}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mb-4 incident-details-time-container">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                {...props}
                className="incident-details--time-picker"
                slotProps={{
                  textField: {
                    variant: 'outlined',
                  },
                }}
                renderInput={(inputProps) => (
                  <TimeField
                    helperText="Enter Time"
                    fullWidth={true}
                    hiddenLabel={true}
                  />
                )}
                defaultValue={currentTime}
                timeSteps={{ hours: 1, minutes: 1 }}
                readOnly={statusClosed}
                onChange={handleTimeChange}
                closeOnSelect={false}
              />
            </LocalizationProvider>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DetailsTab;
