import {
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
  useEffect,
} from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useCustomerOrgDevices } from '../../../store/CustomerOrgDevicesStore';
import { Row } from 'react-bootstrap';
import DatePicker from 'react-multi-date-picker';
import useEventsStore from '../../../store/EventsStore';
import {
  DevicesFilter,
  DropdownList,
  SiteSpinner,
  SiteModal, 
  DateTimePicker
} from '../../../components/common';
import { constants, Utils } from '../../../helpers';
import { Section, SingleColumnLayout, TwoColumnLayout } from '../components/';

import BaseChartConfig from '../shared/BaseChartConfig';
import '../CameraAveStreamingBitrate.scss';
import CamAvailableListTable from './camAvailableListTable';
import moment from 'moment';
import { getSelectedDeviceFromFillter, setSelectedDeviceFromFillter } from '../../../store/CustomerOrgDevicesStoreIDB';

const CameraBitrateDetails = ({ custOrgData, devices, columnsData,selectedPastRange,selectedPastRangeLabel,selectedDuration,selectedPastDuration }) => {
  // itemValue is set in number of days, except
  // the one for the Custom Range
  const pastTimeSeries = [
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_24_HOURS,
      itemValue: 1,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_48_HOURS,
      itemValue: 2,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_7_DAYS,
      itemValue: 7,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_14_DAYS,
      itemValue: 14,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_30_DAYS,
      itemValue: 30,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_YEAR,
      itemValue: 365,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_2_YEARS,
      itemValue: 730,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_CUSTOM_RANGE,
      itemValue: 'custom',
    },
  ];

  const frequencies = [
    {
      itemName: constants.ANALYTICS_DASHBOARD_DURATION_ITEM_HOUR,
      itemValue: 'hourly',
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_DURATION_ITEM_DAY,
      itemValue: 'daily',
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_DURATION_ITEM_WEEK,
      itemValue: 'weekly',
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_DURATION_ITEM_MONTH,
      itemValue: 'monthly',
    },
  ];

  const frequencyGetter = {
    hourly: Utils.getHourFromEpoch,
    daily: Utils.getDateFromEpoch,
    weekly: Utils.getWeekFromEpoch,
    monthly: Utils.getMonthFromEpoch,
  };

  const START_TIME = 1680307200000;
  const END_TIME = 1685343600000;

  const [hasData, setHasData] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [rangeYear, setRangeYear] = useState(false);
  const [rangeDay, setRangeDay] = useState(false);
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const deviceTimezone = useEventsStore((state) => state.deviceTimezone);
  const calendarRef = useRef();
  const [filterDate, setFilterDate] = useState([]);
  const [timezone, setTimezone] = useState(null);
  const [showCustomRange, setShowCustomRange] = useState(false);

  const [options, setOptions] = useState({
    ...BaseChartConfig,
    chart: {
      id: 'camera-ave-streaming-bitrate-line-chart',
      ...BaseChartConfig.chart,
    },
    xaxis: {
      categories: ['12/29', '12/30', '12/31', '1/1', '1/2', '1/3', '1/4'],
    },
    yaxis: {
      opposite: true,
      min: 0,
      tickAmount: 5,
      labels: {
        formatter: (value) => {
          let calculatedValue = value/2;
          return `${calculatedValue?.toFixed(2)} Mbps`;
        },
      },
    },
    colors: [
      getComputedStyle(document.documentElement).getPropertyValue(
        '--primary_56'
      ),
    ]
  });
  const [series, setSeries] = useState([
    {
      name: constants.ANALYTICS_DASHBOARD_CAMERA_AVAILABILITY_SERIES_LABEL,
      data: [100, 100, 80, 100, 100, 100],
    },
  ]);
  const [highlight, setHighlight] = useState({
    value: '3 Mbps',
    description:
      constants.ANALYTICS_DASHBOARD_CAMERA_AVERAGE_STREAMING_BITRATE_HIGHLIGHT_DESCRIPTION,
  });
  const [metricArray, setMetricArray] = useState([]);
  const [pastRange, setPastRange] = useState(selectedPastRange);
  const [pastRangeLabel, setPassRangeLabel] = useState(
    selectedPastRangeLabel
  );
  const [duration, setDuration] = useState(selectedDuration);
  const [durationRange, setSelectedDuration] = useState(selectedPastDuration);
  const custOrgDataRef = useRef(custOrgData);

  useEffect(() => {
    if (custOrgData?.orgId) {
      custOrgDataRef.current = custOrgData;
      fetchData(pastRange, duration, devices);
    }
  }, [custOrgData?.orgId]);

  // const {
  //   getCustomerOrgDevices,
  //   setSelectedDeviceFromFillter,
  //   getSelectedDeviceFromFillter,
  // } = useCustomerOrgDevices();
  // const cameraDevices = getCustomerOrgDevices();

  const defaultValue = (
    <div className="d-flex justify-content-around align-content-center no-content">
      {constants.ANALYTICS_DASHBOARD_NO_DATA_AVAILABLE_TEXT}
    </div>
  );

  useEffect(() => {
    if (showCustomRange) {
      calendarRef?.current?.openCalendar();
      // setPastRange(0);
    }
  }, [showCustomRange]);

  const CustomRangePicker = ({ openCalendar, value }) => {
    if (value === new Date()) {
      return <div onClick={openCalendar}>Custom Range Picker</div>;
    } else {
      return <div onClick={openCalendar}></div>;
    }
  };

  const [showDateTimeModal, setShowDateTimeModal] = useState(false);

  useEffect(() => {
    if (filterDate[0] && filterDate[1]) {
      setShowCustomRange(false);
      const startDate = new Date(filterDate[0]);
      const endDate = new Date(filterDate[1]);
      startDate?.setHours(0, 0, 0, 0);
      endDate?.setHours(23, 59, 59, 0);
      fetchData(0, duration, devices, startDate?.getTime(), endDate?.getTime());
    }
  }, [filterDate]);

  useEffect(() => {
    setTimezone(deviceTimezone);
  }, [deviceTimezone]);

  const updateList = async (deviceDataList, status) => {
    if (deviceDataList?.length > 0) {
      if (status) {
        await setSelectedDeviceFromFillter([]);
        fetchData(pastRange, duration, devices);
      } else {
        if (deviceDataList?.length !== 0) {
          let deviceData = [];
          devices?.map((deviceItem, index) => {
            const deviIdExist = deviceDataList?.find(
              (item) => item === deviceItem?.deviceId
            );
            if (deviIdExist) {
              deviceData.push(deviceItem);
            }
          });
          await setSelectedDeviceFromFillter(deviceDataList);
          fetchData(pastRange, duration, deviceData);
        }
      }
    } else {
      let deviceData = [];
      devices?.map((deviceItem, index) => {
        deviceData.push(deviceItem?.deviceId);
      });
      await setSelectedDeviceFromFillter(deviceData);
      fetchData(pastRange, duration, devices);
    }
  };

  const filterContent = (
    <>
      <DevicesFilter
        devices={devices}
        filterDescription={
          constants.CAMERAS_VIDEO_WALL_FILTER_MODAL_DESCRIPTION
        }
        applyButtonLabel={
          constants.CAMERAS_VIDEO_WALL_FILTER_MODAL_APPLY_FILTER_LABEL
        }
        disableBackgroundColor={true}
        clearFilterPlacement="bottom"
        callback={(deviceData, status) => {
          updateList(deviceData, status);
          setShow(false);
        }}
      />
    </>
  );

  const dynamicWidth = series[0]?.data?.length * 45;
  const chartWidth = dynamicWidth < window.innerWidth ? '100%' : dynamicWidth;

  const chartContent = (
    <div className="scrollable-chart-container">
      <Chart
        options={options}
        series={series}
        type="line"
        height={200}
        width={chartWidth}
      />
    </div>
  );

  const topRightLinks = (
    <div className="filter-group">
      {/* Filter */}
      <DropdownList
        defaultLabel={constants.ANALYTICS_DASHBOARD_ALL_CAMERAS_LINK}
        hasSecondaryDropdown={true}
        items={filterContent}
        show={show}
        setShow={(status) => {
          setShow(status);
        }}
      />
      {/* Past Range */}
      <DropdownList
        defaultLabel={pastRangeLabel}
        items={pastTimeSeries}
        onSelectHandler={(selectedKey) => {
          if (selectedKey === 'custom') {
            setShowCustomRange(true);
            setShowDateTimeModal(true);
          } else {
            setFilterDate(new Date());
            setShowCustomRange(false);
            setShowDateTimeModal(false);
            setPastRange(-Math.abs(selectedKey));
          }
        }}
        show={rangeYear}
        setShow={(status) => {
          setRangeYear(status);
        }}
      />
      {/* Duration */}
      <DropdownList
        defaultLabel={durationRange}
        items={frequencies}
        show={rangeDay}
        setShow={(status) => {
          setRangeDay(status);
        }}
        onSelectHandler={(selectedKey) => {
          setDuration(selectedKey);
          const data = frequencies?.find(
            (item) => item?.itemValue == selectedKey
          );
          setSelectedDuration(data?.itemName)
        }}
      />
    </div>
  );

  const getDeviceIdsString = async(devicList) => {
    const deviceSelected = await getSelectedDeviceFromFillter();
    let deviceIdsString = '';

    devicList?.forEach((device, index) => {
      if (deviceSelected?.length !== 0) {
        const deviIdExist = deviceSelected?.find(
          (item) => item === device?.deviceId
        );
        if (deviIdExist) {
          deviceIdsString += `${device?.deviceId},`;
        }
      } else {
        deviceIdsString += `${device?.deviceId},`;
      }
    });
    return deviceIdsString?.substring(0, deviceIdsString.length - 1);
  };

  const getDeviceLocationName = (device, isForDevice) => {
    const currentCustOrgData = custOrgDataRef.current;
    let deviceLocation = '--';
    const deviceData = currentCustOrgData?.devices.find(
      (item) => item.deviceId === device?.deviceId
    );
    if (deviceData) {
      if (isForDevice) {
        deviceLocation = deviceData?.deviceName;
      } else {
        const location = currentCustOrgData?.locations.find(
          (item) => item.locationId === deviceData?.locationId
        );
        if (location) {
          const areas = location?.areas.find(
            (item) => item.locationId === location?.locationId
          );
          if (areas) {
            deviceLocation = `${location?.locationName} - ${areas?.areaName}`;
          }
        }
      }
    }
    return deviceLocation;
  };

  const fetchData = useCallback(
    async (newPastRange, newDuration, devicesList, startTime, endTime) => {
      if (devicesList?.length === 0) return;
      try {
        const currentCustOrgData = custOrgDataRef.current;
        if (!currentCustOrgData?.orgId) return;
        setShowLoader(true);
        const baseUrl = `metrics/orgs/${currentCustOrgData?.orgId}/trend/device-bit-rate`;
        let date = new Date();
        const deviceIdsList = await getDeviceIdsString(devicesList);
        date.setDate(date.getDate() + newPastRange);
        const fetchResult = await axios.get(baseUrl, {
          params: {
            orgId: currentCustOrgData?.orgId,
            startTime: newPastRange === 0 ? startTime : date.getTime(),
            endTime: newPastRange === 0 ? endTime : new Date().getTime(),
            rangeBy: (newPastRange == -365 && newDuration == "hourly") ? "weekly" : (newPastRange == -730 && newDuration == "hourly") ? "monthly" :  newDuration,
            deviceIds: deviceIdsList,
          },
          timeout: 60000,
          credentials: 'include',
          withCredentials: true,
          ...Utils.requestHeader(),
        });
        const xAxis = [];
        const yValues = [];
        let respData,
          timestampGetter,
          streamingBitrateAverage,
          streamingBitrateTotal,
          timeSeriesData,
          timestamp;

        if (fetchResult?.data?.meta?.code === 200) {
          setShowLoader(false);
          respData = fetchResult.data.data?.timeSeries;

          if (Array.isArray(respData) && respData?.length > 0) {
            // Get x axis values for past 7 days
            switch (newPastRange) {
              case -1:
                timeSeriesData = respData
                timestampGetter = frequencyGetter['hourly'];
                break;

              case -2:
                timeSeriesData = respData
                timestampGetter = frequencyGetter['hourly'];
                break;

              case -365:
                timeSeriesData = respData;
                timestampGetter = frequencyGetter['weekly'];
                break;

              case -730:
                timeSeriesData = respData;
                timestampGetter = frequencyGetter['monthly'];
                break;

              default:
                timeSeriesData = respData
                timestampGetter = frequencyGetter[newDuration];
            }
            const tableList = [];
          //  timeSeriesData?.sort((a, b) => a.timestamp - b.timestamp)
            timeSeriesData.forEach((time) => {
              streamingBitrateAverage = 0;
              streamingBitrateTotal = 0;
              timestamp = timestampGetter(time.timestamp);

              xAxis.push(timestamp);

              if (Array.isArray(time.devices) && time.devices.length > 0) {
                time.devices.forEach(
                  (device) => (streamingBitrateTotal += device.avg)
                );
                time?.devices.forEach((device, index) => {
                  streamingBitrateTotal += device.avg;
                  streamingBitrateAverage = (
                    streamingBitrateTotal /
                    time.devices.length /
                    1000
                  ).toFixed(2);
                  const value = parseFloat(
                    device.avg / 1000
                  );

                  tableList.push({
                    monthYear: moment(new Date(time?.timestamp)).format(
                      'DD MMM yyyy hh:mm '
                    ),
                    deviceName: getDeviceLocationName(device, true),
                    locationArea: getDeviceLocationName(device, false),
                    percentageOnline: value.toFixed(2) + `${' Mbps '}`,
                  });
                });
                yValues.push(streamingBitrateAverage);
              }
            });
            setTimeSeriesData(tableList);

            // Set the y value of each tick on
            // x axis.

            Utils.vmsLogger().log('yValues', yValues);
            setSeries([
              {
                name: '',
                data: yValues,
              },
            ]);
            // Update the chart options with new data
            setOptions({
              ...options,
              xaxis: {
                categories: [...xAxis],
              },
            });

            setHasData(true);
          }
          else{
            setSeries([]);
            setHasData(false);
            setTimeSeriesData([])
            return;
          }
        } else {
          setHasData(false);
          setShowLoader(false);
        }
      } catch (err) {
        setHasData(false);
        setShowLoader(false);
      }
    },
    []
  );

  useLayoutEffect(() => {
    fetchData(pastRange, duration, devices);

    new Promise((resolve) => {
      setHighlight((highlight) => ({
        value: '3 Mbps',
        description: highlight?.description,
      }));
      resolve();
    });
  }, [pastRange, duration, devices]);

  const handleDateChange = (data) => {
    setShowDateTimeModal(false);
    const date = data.selectedDate;
    setFilterDate(date);
  };

  return (
    <div>
      <Row className="analytics-row view-details">
        <Section
          analyticsTitle={
            constants.ANALYTICS_DASHBOARD_CAMERA_AVERAGE_STREAMING_BITRATE_SECTION_TITLE
          }
          analyticsDescription={
            constants.ANALYTICS_DASHBOARD_CAMERA_AVERAGE_STREAMING_BITRATE_SECTION_DESCRIPTION
          }
          topRightLinks={topRightLinks}
        >
          {showLoader ? (
            <div className="no-data-available">
              <SiteSpinner height="50px" width="50px"></SiteSpinner>
            </div>
          ) : (
            <div>
              {hasData ? (
                <SingleColumnLayout chart={chartContent} />
              ) : (
                defaultValue
              )}
            </div>
          )}
          {/* <div className="filterDate-image">
            <DatePicker
              ref={calendarRef}
              range
              minDate={new Date(moment(new Date()).subtract({ days: 30 }))}
              maxDate={new Date()}
              className="rmdp-mobile customized-datepicker"
              value={filterDate}
              onChange={setFilterDate}
              //onClose={calendarRef?.current?.closeCalendar()}
              mobileButtons={[
                {
                  type: 'button',
                  className: 'rmdp-button rmdp-action-button',
                  onClick: () => setFilterDate({}),
                },
              ]}
              render={<CustomRangePicker openCalendar value />}
            />
          </div> */}
          <SiteModal
            modalTitle="Select Date Range"
            showModal={showDateTimeModal}
            hideModal={() => {
              setShowDateTimeModal(false);
            }}
            classes="date-time-picker-modal-range"
            size="sm"
          >
            <DateTimePicker
              rangeShow={true}
              date={filterDate} 
              onSubmit={handleDateChange}
            />
          </SiteModal>
        </Section>
      </Row>
      <Row className="analytics-row">
        <div className={`section-wrapper default-bg`}>
          <CamAvailableListTable columns={columnsData} data={timeSeriesData} />
        </div>
      </Row>
    </div>
  );
};

export default CameraBitrateDetails;
