import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import Timeline from 'react-visjs-timeline';
import { Utils, constants } from '../../../helpers';
import {
  play,
  pause,
  calender,
  fullscreen,
  minimize,
  camera,
} from '../../../assets/images';
import {
  disconnectWithMQTT,
  multiStreamSnapDownload,
  mqttPublish,
  mqttSubscribe,
  mqttUnsubscribe,
} from '../../../utils/connection/mqttConnection';
import {
  getEventsScubberData,
  getTimelineData,
  updatedTimelineData,
} from '../../../helpers/timelineData';
import {
  sendPlayCVR,
  sendPauseCVR,
  checkEOSDevices,
  sendSyncCommand,
} from '../../multilive/components/playback/wssConnection/wssConnection';
import {
  setMetaData,
  setMultiLiveStreamLoader,
  setPlaybackActiveTime,
} from '../../../store/reducers/StreamingReducer';
import LiveGridStructure from './LiveGridStructure';
import PlaybackGridStructure from './PlaybackGridStructure';
import ImageGridStructure from './ImageGridStructure';
import { SiteModal } from '../../../components/common';
import DateTimePicker from '../../../components/common/DateTimePicker';
import { findTimeZoneFromSelectedValue } from '../../../helpers/commonUtils';
import { setSnapshotImage } from '../../../store/StreamingStoreIDB';
import { devicesMQTTStore } from '../../../store/DevicesMQTTStore';
import { setKeepAliveRequest } from '../../../store/reducers/AccountReducer';
import { resetEvents } from '../../../store/reducers/EventsReducer';
import { getSentOfferDevice } from '../../multilive/components/live/webRTCHandler/webRTCmqttHandler';
import { useDeviceSnapshots } from '../../../store/DeviceSnapshotsStore';

class DeviceWallTimeline extends React.Component {
  constructor(props) {
    super(props);
    this.timeline = React.createRef();
    const currentTime = new Date();
    const deviceStart = Utils.getDate(props?.startDate / 1000);
    this.state = {
      minsOptions: {
        width: '100%',
        height: '50px',
        zoomKey: 'ctrlKey',
        stack: false,
        stackSubgroups: false,
        showMajorLabels: true,
        showMinorLabels: false,
        showCurrentTime: false,
        orientation: 'top',
        timeAxis: { scale: 'second', step: 6 },
        format: {
          majorLabels: {
            second: 'hh:mm',
          },
        },
        zoomable: false,
        horizontalScroll: true,
        start: moment().subtract({ minutes: 4, seconds: 20 }).toDate(),
        end: moment(new Date(currentTime))
          .add({ minutes: 4, seconds: 20 })
          .toDate(),
        min: deviceStart,
        max: moment(new Date(currentTime))
          .add({ minutes: 4, seconds: 20 })
          .toDate(),
        selectable: false,
      },
      daysOptions: {
        width: '100%',
        height: '50px',
        zoomKey: 'ctrlKey',
        stack: true,
        stackSubgroups: false,
        showMajorLabels: true,
        showMinorLabels: false,
        showCurrentTime: false,
        orientation: 'top',
        timeAxis: { scale: 'hour', step: 2 },
        format: {
          majorLabels: {
            hour: 'D MMM',
          },
        },
        zoomable: false,
        horizontalScroll: true,
        start: moment(new Date(currentTime))
          .subtract({ days: 1, hours: 6 })
          .toDate(),
        end: moment(new Date(currentTime)).add({ days: 1, hours: 6 }).toDate(),
        min: deviceStart,
        max: moment(new Date(currentTime)).add({ days: 1, hours: 6 }).toDate(),
        selectable: false,
      },
      secsOptions: {
        width: '100%',
        height: '50px',
        zoomKey: 'ctrlKey',
        stack: true,
        stackSubgroups: false,
        showMajorLabels: true,
        showMinorLabels: false,
        showCurrentTime: false,
        orientation: 'top',
        timeAxis: { scale: 'second', step: 1 },
        format: {
          majorLabels: {
            second: 'mm:ss',
          },
          minorLabels: {
            second: 's',
          },
        },
        zoomable: false,
        horizontalScroll: true,
        start: moment(new Date(currentTime)).subtract({ seconds: 40 }).toDate(),
        end: moment(new Date(currentTime)).add({ seconds: 40 }).toDate(),
        min: deviceStart,
        max: moment(new Date(currentTime)).add({ seconds: 40 }).toDate(),
        selectable: false,
      },
      liveStreamMode: true,
      activeTime: new Date(currentTime),
      timeZone: 'America/New_York',
      timezoneValue: moment.tz.guess(),
      offset: '-5',
      manualClicked: false,
      minsView: true,
      secsView: false,
      daysView: false,
      selectedOption: 'Mins',
      clickAction: false,
      cvrMode: false,
      CVRSnapMode: false,
      metaDataHere: [],
      minsMetaData: [],
      daysMetaData: [],
      secsMetaData: [],
      fullscreen: false,
      fullscreenBackup: false,
      muteAudio: false,
      viewId: null,
      startDate: null,
      eventsDataCheck: [],
      eventTimestamp: null,
      liveSnapshot: false,
      moveTimelineImage: false,
      internalEventFromSearch: false,
      eventCVRMode: null,
      fakeTime: true,
      customeDeviceMetaData: null,
      customEventsDotsData: null,
      holdEventsDotsData: [],
      showDateTimeModal: false,
      selectedDate: new Date(),
      selectedTime: new Date().setHours(0, 0, 0),
      rawMetaData: [],
      filterdate: '',
      metadataByDeviceId: [],
      noContentDeviceIds: [],
      metaDataControllersRef: [],
      selectedView: [],
      subscribeForEvents: [],
      MQTTConnectionStatus: false,
      selectedEventTime: '',
      oneIceSend: false,
      keepAlive: false,
    };
    this.isWindowHidden = false;
    this.isAbleToCallAPI = false;
    this.isAPIcalled = false;
    this.pauseRequestSent = false;
    this.isRangeChanging = false;
    this.minOptionClicked = false;
    this.keepAliveTimer = null;
    this.snapshotURL = null;
    this.snapList = null;
    this.isTimerRunning = React.createRef(false);
    this.selectedViewTimerDeviceIds = React.createRef([]);
  }

  componentDidMount = () => {
    this.updateTimeZone();
    this.moveTimeline();
    this.updateEventCVRMode();
    this.dynamicTimeLineInterval = setInterval(() => {
      this.setDynamicTimelineMove();
    }, 1000);
    this.setState({
      CVRSnapMode: false,
    });
  };

  componentDidUpdate = (props) => {
    if (!this.isAbleToCallAPI && !this.isAPIcalled) {
      const offerDevice = getSentOfferDevice();
      const onlineDevices = this.props?.allDevices?.filter(
        (device) =>
          device?.connectionStatus === 'online' &&
          this.props?.currentViewDeviceList?.includes(device.deviceId)
      );
      if (
        onlineDevices?.length !== 0 &&
        offerDevice?.length !== 0 &&
        onlineDevices?.length === offerDevice?.length
      ) {
        this.isAbleToCallAPI = true;
      }
    }
    if (this.isAbleToCallAPI && !this.isAPIcalled) {
      this.callAllAPIs(new Date());
      this.isAPIcalled = true;
    }
    if (document.hidden && this.state.liveStreamMode && !this.isWindowHidden) {
      this.isWindowHidden = true;
    } else if (
      !document.hidden &&
      this.state.liveStreamMode &&
      this.isWindowHidden
    ) {
      clearInterval(this.MultiCVRAutoPlay);
      this.isWindowHidden = false;
      this.moveTimeline();
    }
    if (this.state.viewId !== props.viewId) {
      this.timeline?.current?.$el?.setItems([]);
      this.setState({
        minsMetaData: [],
        daysMetaData: [],
        secsMetaData: [],
      });
      this.isAPIcalled = false;
      if (this.state.metaDataControllersRef.length > 0) {
        this.state.metaDataControllersRef.forEach((controller) =>
          controller.abort()
        );
      }
      this.setState(
        {
          viewId: props?.viewId,
          metaDataControllersRef: [],
          oneIceSend: false,
        },
        () => {
          this.snapshotURL = null;
          this.handleRemoveSnapshotImage();
          this.updateTimeZone();
          if (
            this.state.internalEventFromSearch === props.internalEventFromSearch
          ) {
            this.onGoLive();
          }
        }
      );
    }

    if (this.state.startDate !== props.startDate && props?.startDate) {
      this.setState({
        startDate: props?.startDate,
      });
    }

    if (this.state.timezoneValue !== props.timezone && props?.timezone) {
      this.setState({
        timezoneValue: props?.timezone,
      });
      this.updateTimeZone();
    }
    if (this.state.eventCategory !== props.category) {
      this.setState({
        eventCategory: props?.category,
      });
      this.updateEventsPlotting();
    }
    if (this.state.eventTimestamp !== props.eventTimestamp) {
      if (this.state.cvrMode) {
        this.sendPauseCVR();
      }
      this.setState({
        eventTimestamp: props?.eventTimestamp,
      });
      if (this.MultiCVRAutoPlay) {
        clearInterval(this.MultiCVRAutoPlay);
      }
    }

    if (this.state.customEventsDotsData !== props.eventDotsData) {
      if (props.eventDotsData !== null && Array.isArray(props.eventDotsData)) {
        this.setState({
          customEventsDotsData: props.eventDotsData,
        });

        const output = props.eventDotsData?.map((item) => ({
          eventMeta: item.eventMeta,
          deviceId: item.src?.srcId,
          eventTimestamp: Number(item.t),
        }));

        const allData = [];

        output?.forEach((insideEvent, i) => {
          insideEvent?.eventMeta?.events?.forEach((actualEvent, ij) => {
            actualEvent?.event?.forEach((newEvent, index) => {
              if (this.props?.allDeviceIds.includes(insideEvent.deviceId)) {
                const eData = {
                  id: `${i}-${ij}-${index}`,
                  class: newEvent.eventClass.toLowerCase(),
                  timestamp: insideEvent?.eventTimestamp,
                };
                allData.push(eData);
              }
            });
          });
        });

        const eventData = allData.map(function (i) {
          return i.class;
        });
        const obj = {
          start: props?.eventDotsData[0]?.t,
          data: eventData,
          type: 'point',
        };

        this.setState({
          holdEventsDotsData: [...this.state.holdEventsDotsData, obj],
        });
      }
    }

    if (
      props.customMetaData &&
      this.state.customeDeviceMetaData !== props.customMetaData &&
      !this.isRangeChanging
    ) {
      if (props.customMetaData !== null) {
        const newObj = {
          start: props.customMetaData.start * 1000,
          end: props.customMetaData.end * 1000,
          deviceId: props.customMetaData?.deviceId,
        };
        this.setState({
          customeDeviceMetaData: props.customMetaData,
        });

        if (props.customMetaData?.end > this.state.customeDeviceMetaData?.end) {
          this.plotUpdatedSlot(newObj);
        }
      }
    }

    if (
      this.props?.streaming?.mqttConnection !== this.state.MQTTConnectionStatus
    ) {
      this.setState({
        MQTTConnectionStatus: this.props?.streaming?.mqttConnection,
      });
      if (
        this.props?.streaming?.mqttConnection &&
        !this.props?.account?.keepAliveRequest &&
        this.props?.views?.selectedView?.devices?.length
      ) {
        this.subscribeRTEventTopic();
      } else if (!this.props?.streaming?.mqttConnection) {
        this.props?.setKeepAliveRequest(false);
      }
    }
    if (
      this.props?.views?.selectedView?.viewId !==
      this.state.selectedView?.viewId
    ) {
      const oldDevices = this.state.selectedView?.devices?.length
        ? JSON.parse(JSON.stringify(this.state.selectedView?.devices))
        : [];
      if (this.state.selectedView?.devices?.length) {
        this.unscubscribeRTEventsTopic(oldDevices);
        this.cleanTimerForLiveEvents(oldDevices, true);
      }
      this.setState({
        selectedView: this.props?.views?.selectedView,
      });
      if (
        this.props?.streaming?.mqttConnection &&
        !this.props?.account?.keepAliveRequest &&
        this.props?.views?.selectedView?.devices?.length
      ) {
        this.subscribeRTEventTopic(oldDevices);
      }
    }
  };

  subscribeRTEventTopic = (oldDevices) => {
    let subscribeForEvents = {};
    const eventTopics = [];
    this.props?.views?.selectedView?.devices?.forEach((device) => {
      const subscribeForMetaData = {
        topic: `b/streams/${device}`,
        qos: 0,
      };
      // Subscribe to the app topic
      mqttSubscribe(subscribeForMetaData);

      const deviceDetail = this.props?.allDevices?.find(
        (d) => d.deviceId === device
      );
      if (deviceDetail) {
        eventTopics.push(`d/rt-events/${deviceDetail?.gatewayId}`);
      }
    });

    if (eventTopics.length) {
      subscribeForEvents = {
        topic: eventTopics,
        qos: 0,
      };
      this.setState({
        subscribeForEvents: subscribeForEvents,
      });
      // Subscribe  to the device topic
      mqttSubscribe(subscribeForEvents);
    }
    this.props?.views?.selectedView?.devices?.forEach((deviceId) => {
      let device = this.props?.allDevices?.find(
        (device) => device.deviceId === deviceId
      );
      if (device && !oldDevices?.includes(device.deviceId)) {
        device = JSON.parse(JSON.stringify(device));
        device.viewDeviceIds = JSON.stringify(
          this.props?.views?.selectedView?.devices
        );
        this.subscribeRequest(device);
      }
    });
    this.isTimerRunning.current = true;
    this.selectedViewTimerDeviceIds.current =
      this.props?.views?.selectedView?.devices;
  };

  subscribeRequest = (device) => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const mqttState = devicesMQTTStore.getState();
    // const sessionId = state.getSessionId();
    if (!this.props?.account?.accountId) {
      return;
    }
    this.props?.setKeepAliveRequest(true);

    // Send the request
    const context = {
      topic: `a/rt-events/${device.gatewayId}`,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${device.gatewayId}`,
        from: `${this.props?.account?.accountId}`,
        msg: {
          resource: `ch/${device.deviceId}/camera/events/live-events`,
          action: 'add',
          properties: {
            sessionId: mqttState.sessionId,
            events: [
              {
                detection: [
                  'motion',
                  'person',
                  'vehicle',
                  'lp',
                  'face',
                  'audio',
                  'SoundRecognition',
                ],
                notifications: [
                  'queue-threshold',
                  'tamper',
                  'defocus',
                  'shock',
                ],
                stats: ['queue-count', 'person-count', 'vehicle-count'],
              },
            ],
          },
        },
        publish: `d/notify/${this.props?.account?.accountId}/${mqttState.sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
    this.keepAliveSetTimer(device);
  };

  keepAliveSetTimer = (device) => {
    //Retrieve keep alive session every 90 seconds
    this.keepAliveTimer = setTimeout(() => {
      if (
        this.isTimerRunning.current &&
        this.selectedViewTimerDeviceIds?.current?.includes(device.deviceId) &&
        device.viewDeviceIds ===
          JSON.stringify(this.selectedViewTimerDeviceIds?.current)
      ) {
        this.keepAliveRequest(device);
      }
    }, 90000);
  };

  keepAliveRequest = (device) => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const mqttState = devicesMQTTStore.getState();

    // const sessionId = state.getSessionId();

    if (!this.props?.account?.accountId) {
      return;
    }

    // Send the request
    const context = {
      topic: `a/rt-events/${device.gatewayId}`,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${device.gatewayId}`,
        from: `${this.props?.account?.accountId}`,
        msg: {
          action: 'set',
          resource: `ch/${device.deviceId}/camera/events/live-events`,
          properties: {
            sessionId: mqttState.sessionId,
          },
        },
        publish: `d/notify/${this.props?.account?.accountId}/${mqttState.sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
    if (
      this.isTimerRunning.current &&
      this.selectedViewTimerDeviceIds?.current?.includes(device.deviceId) &&
      device.viewDeviceIds ===
        JSON.stringify(this.selectedViewTimerDeviceIds?.current)
    ) {
      this.keepAliveSetTimer(device);
    }
  };

  deleteRequest = (device) => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const mqttState = devicesMQTTStore.getState();
    // const sessionId = state.getSessionId();
    if (!this.props?.account?.accountId) {
      return;
    }

    // Send the request
    const context = {
      topic: `a/rt-events/${device.gatewayId}`,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${device.gatewayId}`,
        from: `${this.props?.account?.accountId}`,
        msg: {
          resource: `ch/${device.deviceId}/camera/events/live-events`,
          action: 'delete',
          properties: {
            sessionId: mqttState.sessionId,
          },
        },
        publish: `d/notify/${this.props?.account?.accountId}/${mqttState.sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
  };

  cleanTimerForLiveEvents = (deviceList, isViewChange) => {
    this.isTimerRunning.current = null;
    this.selectedViewTimerDeviceIds.current = [];
    deviceList?.forEach((deviceId) => {
      const device = this.props?.allDevices?.find(
        (d) => d.deviceId === deviceId
      );
      if (
        device &&
        (!this.props?.views?.selectedView?.devices?.includes(device.deviceId) ||
          !isViewChange)
      ) {
        this.deleteRequest(device);
      }
    });
    clearTimeout(this.keepAliveTimer);
    this.props?.setKeepAliveRequest(false);
  };

  unscubscribeRTEventsTopic = (deviceList) => {
    mqttUnsubscribe(this.state.subscribeForEvents);
    deviceList?.forEach((device) => {
      const subscribeForMetaData = {
        topic: `b/streams/${device}`,
        qos: 0,
      };
      // Unsubscribe to the app topic
      mqttUnsubscribe(subscribeForMetaData);
    });
    this.props?.resetEvents();
  };

  handleRemoveSnapshotImage = async () => {
    try {
      // Set the snapshot image to null
      await setSnapshotImage({});
      // Handle any state updates or UI changes here after successful update
    } catch (error) {}
  };

  getAgesWithGreaterDifference = (arr, endTime) => {
    // Sort the array based on age in ascending order
    arr.sort((a, b) => a.start - b.start);

    // Initialize the result array with the first object
    const result = [arr[0]];

    // Iterate over the remaining objects
    for (let i = 1; i < arr.length; i++) {
      const currentAge = arr[i].start;
      const previousAge = result[result.length - 1].start;

      // Check if the difference is greater than or equal to 3
      if (currentAge - previousAge >= 3000 && currentAge < endTime) {
        result.push(arr[i]);
      }
    }

    return result;
  };

  plotUpdatedSlot = (newObj) => {
    if (newObj?.end && this.state.holdEventsDotsData.length) {
      const newArray = this.getAgesWithGreaterDifference(
        this.state.holdEventsDotsData,
        newObj?.end
      );
      const minsAdjustment = { minutes: 0 };
      const minsData = updatedTimelineData([newObj], minsAdjustment);
      if (this.state.metadataByDeviceId.length > 0) {
        const findObj = this.state.metadataByDeviceId.find(
          (devMeta) => devMeta?.deviceId === minsData?.[0]?.deviceId
        );
        const findIndex = this.state.metadataByDeviceId.findIndex(
          (devMeta) => devMeta?.deviceId === minsData?.[0]?.deviceId
        );
        const newObj = {
          end: Utils.getUnixDate(minsData?.[0]?.end) * 1000,
          id: minsData?.[0]?.id,
          start: Utils.getUnixDate(minsData?.[0]?.start) * 1000,
        };
        findObj?.metadata.push(newObj);
        const updatedArray = [...this.state.metadataByDeviceId];
        if (findIndex > -1) {
          updatedArray.splice(findIndex, 1, findObj);
        }
        this.setState({
          metadataByDeviceId: updatedArray,
        });
      }
      const mergedItem = [...this.state.minsMetaData, ...minsData];
      this.setState({
        minsMetaData: mergedItem,
        rawMetaData: [...this.state.rawMetaData, ...minsData],
      });
      this.setState({
        holdEventsDotsData: [],
      });

      const massagedData = getEventsScubberData(newArray, minsAdjustment);
      const newData = [...this.state.eventsDataCheck, ...massagedData];
      this.setState({
        eventsDataCheck: newData,
      });
      setTimeout(() => {
        this.updateEventsPlotting(newData);
      }, 500);
    } else if (newObj?.end) {
      const minsAdjustment = { minutes: 0 };
      const minsData = updatedTimelineData([newObj], minsAdjustment);
      if (this.state.metadataByDeviceId.length > 0) {
        const findObj = this.state.metadataByDeviceId.find(
          (devMeta) => devMeta?.deviceId === minsData?.[0]?.deviceId
        );
        const findIndex = this.state.metadataByDeviceId.findIndex(
          (devMeta) => devMeta?.deviceId === minsData?.[0]?.deviceId
        );
        const newObj = {
          end: Utils.getUnixDate(minsData?.[0]?.end) * 1000,
          id: minsData?.[0]?.id,
          start: Utils.getUnixDate(minsData?.[0]?.start) * 1000,
        };
        findObj?.metadata.push(newObj);
        const updatedArray = [...this.state.metadataByDeviceId];
        if (findIndex > -1) {
          updatedArray.splice(findIndex, 1, findObj);
        }
        this.setState({
          metadataByDeviceId: updatedArray,
        });
      }
      this.setState({
        minsMetaData: [...this.state.minsMetaData, ...minsData],
      });
      this.updateEventsPlotting();
    }
  };

  updateEventCVRMode = () => {
    if (!this.props?.eventCVRMode) {
      this.setState({
        CVRSnapMode: true,
        liveStreamMode: false,
        cvrMode: false,
        manualClicked: true,
      });
    }
  };

  updateTimeZone = (selectedDate) => {
    const getLocationSelected = findTimeZoneFromSelectedValue(
      this?.props?.timezone
    );
    const zone = getLocationSelected?.location || moment.tz.guess();

    const UTCDate = moment(selectedDate ? selectedDate : new Date())
      .tz(zone)
      .format();

    if (zone) {
      this.setState({
        timeZone: zone,
        offset: UTCDate,
      });
    }
  };

  moveTimeline = () => {
    clearInterval(this.MultiCVRAutoPlay);
    if (this.state.selectedOption === 'Days') {
      this.MultiCVRAutoPlay = setInterval(() => {
        this.timeline?.current?.$el?.moveTo(
          moment(this.state.liveStreamMode ? new Date() : this.state.activeTime)
            .add({ seconds: 1 })
            .toDate()
        );
      }, 1000);
    } else {
      this.MultiCVRAutoPlay = setInterval(() => {
        this.timeline?.current?.$el?.moveTo(
          moment(this.state.liveStreamMode ? new Date() : this.state.activeTime)
            .add({ seconds: 1 })
            .toDate()
        );
      }, 1000);
    }
    setTimeout(() => {
      this.minOptionClicked = false;
    }, 1500);
  };

  componentWillUnmount() {
    if (this.state.metaDataControllersRef.length > 0) {
      this.state.metaDataControllersRef.forEach((controller) =>
        controller.abort()
      );
    }
    this.setState({
      internalEventFromSearch: false,
      metaDataControllersRef: [],
    });
    clearInterval(this.MultiCVRAutoPlay);
    clearInterval(this.dynamicTimeLineInterval);
    this.props?.setPlaybackActiveTime('');
    this.props?.setMultiLiveStreamLoader(null);
    disconnectWithMQTT();
    if (this.state.cvrMode) {
      this.sendPauseCVR();
    }
    if (this.state.selectedView?.devices) {
      this.unscubscribeRTEventsTopic(
        JSON.parse(JSON.stringify(this.state.selectedView?.devices))
      );
      this.cleanTimerForLiveEvents(
        JSON.parse(JSON.stringify(this.state.selectedView?.devices))
      );
    }
  }

  setDynamicTimelineMove = () => {
    const { offset } = this.state;
    this.timeline?.current?.$el.setOptions({
      moment: function (date) {
        return moment(date).utcOffset(offset);
      },
    });
    if (this.state.minsView) {
      this.timeline?.current?.$el.setOptions({
        max: moment(new Date()).add({ minutes: 4, seconds: 21 }).toDate(),
        min: moment(this.state.startDate)
          .subtract({ minutes: 4, seconds: 21 })
          .toDate(),
      });
    } else if (this.state.daysView) {
      this.timeline?.current?.$el.setOptions({
        max: moment(new Date()).add({ days: 1, hours: 6, seconds: 1 }).toDate(),
        min: moment(this.state.startDate)
          .subtract({ days: 1, hours: 6, seconds: 1 })
          .toDate(),
      });
    } else if (this.state.secsView) {
      this.timeline?.current?.$el.setOptions({
        max: moment(new Date()).add({ seconds: 41 }).toDate(),
        min: moment(this.state.startDate).subtract({ seconds: 41 }).toDate(),
      });
    }
  };

  fetchCVRMetaData = (timestamp) => {
    this.props.setMetaData([]);
    let minOptions;
    if (
      this.state.selectedOption === 'Mins' ||
      this.state.selectedOption === 'Secs'
    ) {
      minOptions = { minutes: 5 };
    } else if (this.state.selectedOption === 'Days') {
      minOptions = { days: 1 };
    }
    const startTime = Utils.getUnixDate(
      moment(timestamp || this.state.activeTime).subtract(minOptions)
    );
    const endTime = Utils.getUnixDate(
      moment(timestamp || this.state.activeTime).add(minOptions)
    );

    let metaData = [];
    const deviceMetadata = [];
    this.props?.allDeviceIds?.forEach((deviceId, index) => {
      console.log('Livestream meta api called ', deviceId);
      if (startTime && startTime.length !== 0 && endTime) {
        if (this.state.metaDataControllersRef?.[index]) {
          this.state.metaDataControllersRef[index].abort();
        }
        const controller = new AbortController();
        const { signal } = controller;
        const data = [...this.state.metaDataControllersRef, controller];
        this.setState({
          metaDataControllersRef: data,
        });
        axios
          .get(
            `timeline/device/${deviceId}/metadata?startTime=${startTime}000&endTime=${endTime}000`,
            {
              ...Utils.requestHeader(),
              signal: signal,
            }
          )
          .then((response) => {
            if (response?.data?.data?.length > 0) {
              if (metaData?.length > 0) {
                metaData = metaData.concat(response.data?.data);
              } else {
                metaData = response?.data?.data;
              }
              const deviceBaseMetadata = {
                deviceId: deviceId,
                metadata: response.data?.data,
              };
              deviceMetadata.push(deviceBaseMetadata);
              this.setState(
                {
                  rawMetaData: metaData,
                  metadataByDeviceId: deviceMetadata,
                },
                () => {
                  this.getItemsMetaData(metaData);
                }
              );
            }
          });
      }
    });
  };

  updateEventsPlotting = (eventsData) => {
    let data = [];
    if (eventsData) {
      data = eventsData;
    } else {
      data = this.state.eventsDataCheck;
    }

    const mergedItem = [...this.state.minsMetaData, ...data];
    const filteredArr = mergedItem.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    this.timeline?.current?.$el?.setItems(filteredArr);
  };

  onMuteIconClick = () => {
    this.setState({
      muteAudio: !this.state.muteAudio,
    });
  };

  onClickMins = () => {
    this.minOptionClicked = true;
    clearInterval(this.MultiCVRAutoPlay);
    switch (this.state.selectedOption) {
      case 'Mins':
        this.setState(
          {
            minsView: false,
            daysView: true,
            secsView: false,
            selectedOption: 'Days',
            clickAction: true,
          },
          () => {
            this.fetchCVRMetaData();
            this.fetchEventsDataInDays();
          }
        );
        break;
      case 'Days':
        this.setState(
          {
            minsView: false,
            daysView: false,
            secsView: true,
            selectedOption: 'Secs',
            clickAction: true,
          },
          () => {
            this.fetchEventsDataInSecs();
          }
        );
        break;
      case 'Secs':
        this.setState(
          {
            minsView: true,
            daysView: false,
            secsView: false,
            selectedOption: 'Mins',
            clickAction: true,
          },
          () => {
            this.fetchEventsData();
          }
        );
        break;
      default:
        break;
    }

    setTimeout(() => {
      this.setState(
        {
          clickAction: false,
        },
        () => {
          this.moveTimeline();
        }
      );
    }, 300);
  };

  onClickSync = () => {
    const { allDeviceIds } = this.props;
    const { activeTime } = this.state;
    allDeviceIds?.forEach((deviceId) => {
      const findMetaByDevice = this.state.metadataByDeviceId?.find(
        (devMeta) => devMeta.deviceId === deviceId
      );
      if (findMetaByDevice && findMetaByDevice?.metadata?.length > 0) {
        const isInRange = this.checkMetaInRange(
          findMetaByDevice?.metadata,
          activeTime
        );
        if (isInRange) {
          sendSyncCommand(deviceId, activeTime);
        }
      }
    });
  };

  onClickSyncPassPlayCommand = () => {
    this.props?.allDeviceIds?.forEach((deviceId) => {
      const loaderObj = {
        deviceId,
        isLoading: true,
      };
      this.props?.setMultiLiveStreamLoader(loaderObj);
    });
    this.sendPauseCVR();
    this.sendPlayCVR();
  };

  onGoLive = () => {
    this.isRangeChanging = false;
    clearInterval(this.MultiCVRAutoPlay);
    this.updateTimeZone();
    this.setState(
      {
        liveStreamMode: true,
        activeTime: new Date(),
        manualClicked: false,
        filterdate: null,
        selectedDate: new Date(),
        selectedTime: new Date().setHours(0, 0, 0),
      },
      () => {
        if (this.state.cvrMode) {
          this.setState(
            {
              cvrMode: false,
            },
            () => {
              this.sendPauseCVR();
            }
          );
        }
        this.props?.allDeviceIds?.forEach((deviceId) => {
          const loaderObj = {
            deviceId,
            isLoading: true,
          };
          this.props?.setMultiLiveStreamLoader(loaderObj);
        });
        if (
          this.state.CVRSnapMode ||
          this.state.liveStreamMode ||
          this.state.cvrMode
        ) {
          this.setState(
            {
              CVRSnapMode: false,
            },
            () => {
              this.moveTimeline();
            }
          );
        }
      }
    );
  };

  callAllAPIs = (timeStamp) => {
    switch (this.state.selectedOption) {
      case 'Mins':
        this.fetchEventsDataInRange(timeStamp || this.state.activeTime);
        this.fetchCVRMetaData(timeStamp || this.state.activeTime);
        break;
      case 'Days':
        this.fetchCVRMetaData(timeStamp || this.state.activeTime);
        this.fetchEventsDataInDays();
        break;
      case 'Secs':
        this.fetchCVRMetaData(timeStamp || this.state.activeTime);
        this.fetchEventsDataInSecs();
        break;
      default:
        break;
    }
    if (this.isAbleToCallAPI) {
      this.isAbleToCallAPI = false;
    }
  };

  fetchSnapshotCanvasById = (typeCanvas, typeVideo, deviceId) => {
    let canvas = document.getElementById(typeCanvas);
    let video = document.getElementById(typeVideo);
    const context = canvas?.getContext('2d');
    const bitmapObjects = this.props?.streaming?.bitmapObjects;
    const bitmapObject = bitmapObjects ? bitmapObjects[deviceId] : null;
    const bitmapObjectHeight = bitmapObject ? bitmapObject?.StreamHeight : 450;
    const bitmapObjectWidth = bitmapObject ? bitmapObject?.StreamWidth : 764;
    if (context?.canvas) {
      context.canvas.width = bitmapObjectWidth;
      context.canvas.height = bitmapObjectHeight;
    }
    context?.drawImage(video, 0, 0, bitmapObjectWidth, bitmapObjectHeight);
    const data = canvas?.toDataURL('image/png');
    this.snapList = {
      ...this.snapList,
      [deviceId]: this.props?.streaming?.multiLiveStreamLoader?.[deviceId]
        ? useDeviceSnapshots.getState()?.deviceSnapshots?.[deviceId]
        : data,
    };
  };

  onPauseCVR = async () => {
    if (this.state.cvrMode) {
      this.props?.allDeviceIds?.forEach((deviceId) => {
        const canvasId = `canvas${deviceId}`;
        const videoId = `video${deviceId}`;
        this.fetchPlaybackSnapshot(canvasId, videoId, deviceId);
      });

      this.setState(
        {
          cvrMode: false,
          CVRSnapMode: true,
        },
        () => {
          this.sendPauseCVR();
        }
      );
    }
    if (this.state.liveStreamMode) {
      this.props?.allDeviceIds?.forEach((liveDeviceId) => {
        const id = `${liveDeviceId}${liveDeviceId}`;
        this.fetchSnapshotCanvasById(`canvas${id}`, `video${id}`, liveDeviceId);
      });
      disconnectWithMQTT();
      this.setState(
        {
          liveStreamMode: false,
          liveSnapshot: true,
          CVRSnapMode: true,
        },
        () => {}
      );
    }
    this.setState({
      manualClicked: true,
    });
    clearInterval(this.MultiCVRAutoPlay);
    this.callAllAPIs();
  };

  onPlayCVR = () => {
    this.isRangeChanging = false;
    this.setState(
      {
        cvrMode: true,
        liveStreamMode: false,
        CVRSnapMode: false,
        eventCVRMode: true,
        selectedEventTime: Utils.getUnixDate(this.state.activeTime) * 1000,
      },
      () => {
        this.props?.allDeviceIds?.forEach((deviceId) => {
          const loaderObj = {
            deviceId,
            isLoading: true,
          };
          this.props?.setMultiLiveStreamLoader(loaderObj);
        });
        this.sendPlayCVR();
        this.moveTimeline();
      }
    );
  };

  getOptions = () => {
    if (this.state.minsView) {
      return this.state.minsOptions;
    } else if (this.state.daysView) {
      return this.state.daysOptions;
    } else if (this.state.secsView) {
      return this.state.secsOptions;
    }
  };

  updateEventsPlottingInRange = (eventsData) => {
    let newData = [];
    if (eventsData) {
      newData = eventsData;
    }
    this.timeline?.current?.$el?.setItems([
      ...this.state.minsMetaData,
      ...newData,
    ]);
  };

  sendPlayCVR = (newTime) => {
    const { allDeviceIds } = this.props;
    const { activeTime } = this.state;
    allDeviceIds?.forEach((deviceId) => {
      this.sendPlayCVRByDeviceId(
        deviceId,
        newTime ? newTime : activeTime,
        deviceId
      );
    });
  };

  sendPlayCVRByDeviceId = (deviceId, newTime) => {
    const findMetaByDevice = this.state.metadataByDeviceId?.find(
      (devMeta) => devMeta.deviceId === deviceId
    );
    if (findMetaByDevice && findMetaByDevice?.metadata?.length > 0) {
      const isInRange = this.checkMetaInRange(
        findMetaByDevice?.metadata,
        newTime
      );
      if (isInRange) {
        const findNoContentDevice = this.state.noContentDeviceIds?.findIndex(
          (device) => device === deviceId
        );
        const updatedArray = [...this.state.noContentDeviceIds];
        if (findNoContentDevice > -1) {
          updatedArray.splice(findNoContentDevice, 1);
          this.setState({
            noContentDeviceIds: updatedArray,
          });
        }
        sendPlayCVR(newTime, deviceId, this.state.oneIceSend);
      } else {
        const loaderObj = {
          deviceId,
          isLoading: false,
        };
        this.props?.setMultiLiveStreamLoader(loaderObj);
        const findNoContentDevice = this.state.noContentDeviceIds?.findIndex(
          (device) => device === deviceId
        );
        if (findNoContentDevice === -1) {
          this.setState({
            noContentDeviceIds: [...this.state.noContentDeviceIds, deviceId],
          });
        }
      }
    }
  };

  checkMetaInRange = (rawMetaData, newTime) => {
    let status = false;
    for (let range of rawMetaData) {
      if (
        Math.trunc(range?.start / 1000) <=
          Math.trunc(newTime?.getTime() / 1000) &&
        Math.trunc(newTime?.getTime() / 1000) <= Math.trunc(range?.end / 1000)
      ) {
        status = true;
        break;
      }

      if (
        Math.trunc(newTime?.getTime() / 1000) ===
        Math.trunc(range?.start / 1000)
      ) {
        status = true;
        break;
      }
    }
    return status;
  };

  sendPauseCVR = () => {
    const { allDeviceIds } = this.props;
    allDeviceIds?.forEach((deviceId) => {
      sendPauseCVR(deviceId);
    });
  };

  fetchEventsData = () => {
    const endTime = Utils.getUnixDate(
      moment(this.state.activeTime).add({
        minutes: 3,
      })
    );
    const startTime = Utils.getUnixDate(
      moment(this.state.activeTime).subtract({
        minutes: 3,
      })
    );
    let personFilter = '';
    let vehicleFilter = '';
    let faceFilter = '';
    let licensePlateFilter = '';
    let soundFilter = '';
    if (this.props.appliedFilter !== '') {
      personFilter = this.props.appliedFilter.includes('person')
        ? 'person'
        : '';
      vehicleFilter = this.props.appliedFilter.includes('vehicle')
        ? 'vehicle'
        : '';
      faceFilter = this.props.appliedFilter.includes('face') ? 'Face' : '';
      licensePlateFilter = this.props.appliedFilter.includes('licensePlate')
        ? 'LicensePlate'
        : '';
      soundFilter = this.props.appliedFilter.includes('sound')
        ? 'scream, explosion, gun-shot, glass-breaking'
        : '';
    } else {
      personFilter = 'person';
      vehicleFilter = 'vehicle';
      faceFilter = 'Face';
      licensePlateFilter = 'LicensePlate';
      soundFilter = 'scream, explosion, gun-shot, glass-breaking';
    }
    if (
      startTime &&
      endTime &&
      this.props?.account?.userdata[0]?.orgId &&
      this.props?.views?.selectedView?.devices?.length
    ) {
      axios
        .get(
          `timeline/orgs/${
            this.props?.account?.userdata[0]?.orgId
          }/events/agg/count?endTime=${endTime}000&startTime=${startTime}000&deviceIds=${this.props?.views?.selectedView?.devices?.join(
            ','
          )}&ascOrder=false&eventClasses=${personFilter ?? ''}&eventClasses=${
            vehicleFilter ?? ''
          }&eventClasses=${licensePlateFilter ?? ''}&eventClasses=${
            soundFilter ?? ''
          }&eventClasses=${faceFilter ?? ''}&aggBy=minute`,
          Utils.requestHeader()
        )
        .then((response) => {
          if (response?.data?.data) {
            const scrubberEvents = Object.entries(response?.data?.data?.result);
            const finalData =
              scrubberEvents?.map((event) => ({
                start: event[0],
                data: Object.keys(event[1]),
                type: 'point',
              })) || [];
            const minsAdjustment = { minutes: 0 };
            const massagedData = getEventsScubberData(
              finalData,
              minsAdjustment
            );
            this.setState({
              eventsDataCheck: massagedData,
            });
            this.updateEventsPlotting(massagedData);
          }
        });
    }
  };

  fetchEventsDataInRange = (timestamp) => {
    const endTime = Utils.getUnixDate(
      moment(timestamp || this.state.activeTime).add({
        minutes: 3,
      })
    );
    const startTime = Utils.getUnixDate(
      moment(timestamp || this.state.activeTime).subtract({
        minutes: 3,
      })
    );
    let personFilter = '';
    let vehicleFilter = '';
    let faceFilter = '';
    let licensePlateFilter = '';
    let soundFilter = '';

    if (this.props.appliedFilter !== '') {
      personFilter = this.props.appliedFilter?.includes('person')
        ? 'person'
        : '';
      vehicleFilter = this.props.appliedFilter?.includes('vehicle')
        ? 'vehicle'
        : '';
      faceFilter = this.props.appliedFilter?.includes('face') ? 'Face' : '';
      licensePlateFilter = this.props.appliedFilter?.includes('licensePlate')
        ? 'LicensePlate'
        : '';
      soundFilter = this.props.appliedFilter?.includes('sound')
        ? 'scream, explosion, gun-shot, glass-breaking'
        : '';
    } else {
      personFilter = 'person';
      vehicleFilter = 'vehicle';
      faceFilter = 'Face';
      licensePlateFilter = 'LicensePlate';
      soundFilter = 'scream, explosion, gun-shot, glass-breaking';
    }
    if (
      startTime &&
      endTime &&
      this.props?.account?.userdata[0]?.orgId &&
      this.props?.views?.selectedView?.devices?.length
    ) {
      console.log('Livestream count api called');
      axios
        .get(
          `timeline/orgs/${
            this.props?.account?.userdata[0]?.orgId
          }/events/agg/count?endTime=${endTime}000&startTime=${startTime}000&deviceIds=${this.props?.views?.selectedView?.devices?.join(
            ','
          )}&ascOrder=false&eventClasses=${personFilter ?? ''}&eventClasses=${
            vehicleFilter ?? ''
          }&eventClasses=${licensePlateFilter ?? ''}&eventClasses=${
            soundFilter ?? ''
          }&eventClasses=${faceFilter ?? ''}&aggBy=minute`,
          Utils.requestHeader()
        )
        .then((response) => {
          if (response?.data?.data) {
            const scrubberEvents = Object.entries(response?.data?.data?.result);
            const finalData =
              scrubberEvents?.map((event) => ({
                start: event[0],
                data: Object.keys(event[1]),
                type: 'point',
              })) || [];
            const minsAdjustment = { minutes: 0 };
            const massagedData = getEventsScubberData(
              finalData,
              minsAdjustment
            );
            this.setState({
              eventsDataCheck: massagedData,
              holdEventsDotsData: [],
            });
            this.updateEventsPlottingInRange(massagedData);
          }
        });
    }
  };

  fetchEventsDataInDays = (timestamp) => {
    const endTime = Utils.getUnixDate(
      moment(timestamp || this.state.activeTime).add({
        days: 1,
      })
    );
    const startTime = Utils.getUnixDate(
      moment(timestamp || this.state.activeTime).subtract({
        days: 1,
      })
    );
    let personFilter = '';
    let vehicleFilter = '';
    let faceFilter = '';
    let licensePlateFilter = '';
    let soundFilter = '';
    if (this.props.appliedFilter !== '') {
      personFilter = this.props.appliedFilter.includes('person')
        ? 'person'
        : '';
      vehicleFilter = this.props.appliedFilter.includes('vehicle')
        ? 'vehicle'
        : '';
      faceFilter = this.props.appliedFilter.includes('face') ? 'Face' : '';
      licensePlateFilter = this.props.appliedFilter.includes('licensePlate')
        ? 'LicensePlate'
        : '';
      soundFilter = this.props.appliedFilter.includes('sound')
        ? 'scream, explosion, gun-shot, glass-breaking'
        : '';
    } else {
      personFilter = 'person';
      vehicleFilter = 'vehicle';
      faceFilter = 'Face';
      licensePlateFilter = 'LicensePlate';
      soundFilter = 'scream, explosion, gun-shot, glass-breaking';
    }
    if (
      startTime &&
      endTime &&
      this.props?.account?.userdata[0]?.orgId &&
      this.props?.views?.selectedView?.devices?.length
    ) {
      axios
        .get(
          `timeline/orgs/${
            this.props?.account?.userdata[0]?.orgId
          }/events/agg/count?endTime=${endTime}000&startTime=${startTime}000&deviceIds=${this.props?.views?.selectedView?.devices?.join(
            ','
          )}&ascOrder=false&eventClasses=${personFilter ?? ''}&eventClasses=${
            vehicleFilter ?? ''
          }&eventClasses=${licensePlateFilter ?? ''}&eventClasses=${
            soundFilter ?? ''
          }&eventClasses=${faceFilter ?? ''}&aggBy=day`,
          Utils.requestHeader()
        )
        .then((response) => {
          if (response?.data?.data) {
            const scrubberEvents = Object.entries(response?.data?.data?.result);
            const finalData =
              scrubberEvents?.map((event) => ({
                start: event[0],
                data: Object.keys(event[1]),
                type: 'point',
              })) || [];
            const daysAdjustment = { days: 0 };
            const massagedData = getEventsScubberData(
              finalData,
              daysAdjustment
            );
            this.setState(
              {
                eventsDataCheck: massagedData,
              },
              () => this.updateEventsPlottingInRangeDays(massagedData)
            );
          }
        });
    }
  };

  updateEventsPlottingInRangeDays = (eventsData) => {
    let data = [];
    if (eventsData) {
      data = eventsData;
    } else {
      data = this.state.eventsDataCheck;
    }

    const mergedItem = [...this.state.daysMetaData, ...data];
    const filteredArr = mergedItem.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    this.timeline?.current?.$el?.setItems(filteredArr);
    this.updateEventsPlotting(eventsData);
  };

  fetchEventsDataInSecs = (timestamp) => {
    const endTime = Utils.getUnixDate(
      moment(timestamp || this.state.activeTime).add({ seconds: 60 })
    );
    const startTime = Utils.getUnixDate(
      moment(timestamp || this.state.activeTime).subtract({
        seconds: 60,
      })
    );
    let personFilter = '';
    let vehicleFilter = '';
    let faceFilter = '';
    let licensePlateFilter = '';
    let soundFilter = '';
    if (this.props.appliedFilter !== '') {
      personFilter = this.props.appliedFilter.includes('person')
        ? 'person'
        : '';
      vehicleFilter = this.props.appliedFilter.includes('vehicle')
        ? 'vehicle'
        : '';
      faceFilter = this.props.appliedFilter.includes('face') ? 'Face' : '';
      licensePlateFilter = this.props.appliedFilter.includes('licensePlate')
        ? 'LicensePlate'
        : '';
      soundFilter = this.props.appliedFilter.includes('sound')
        ? 'scream, explosion, gun-shot, glass-breaking'
        : '';
    } else {
      personFilter = 'person';
      vehicleFilter = 'vehicle';
      faceFilter = 'Face';
      licensePlateFilter = 'LicensePlate';
      soundFilter = 'scream, explosion, gun-shot, glass-breaking';
    }
    if (
      startTime &&
      endTime &&
      this.props?.account?.userdata[0]?.orgId &&
      this.props?.views?.selectedView?.devices?.length
    ) {
      axios
        .get(
          `timeline/orgs/${
            this.props?.account?.userdata[0]?.orgId
          }/events/agg/count?endTime=${endTime}000&startTime=${startTime}000&deviceIds=${this.props?.views?.selectedView?.devices?.join(
            ','
          )}&ascOrder=false&eventClasses=${personFilter ?? ''}&eventClasses=${
            vehicleFilter ?? ''
          }&eventClasses=${licensePlateFilter ?? ''}&eventClasses=${
            soundFilter ?? ''
          }&eventClasses=${faceFilter ?? ''}&aggBy=second`,
          Utils.requestHeader()
        )
        .then((response) => {
          if (response?.data?.data) {
            const scrubberEvents = Object.entries(response?.data?.data?.result);
            const finalData =
              scrubberEvents?.map((event) => ({
                start: event[0],
                data: Object.keys(event[1]),
                type: 'point',
              })) || [];
            const secsAdjustment = { seconds: 0 };
            const massagedData = getEventsScubberData(
              finalData,
              secsAdjustment
            );
            this.setState(
              {
                eventsDataCheck: massagedData,
              },
              () => this.updateEventsPlottingInRangeSecs(massagedData)
            );
          }
        });
    }
  };

  updateEventsPlottingInRangeSecs = (eventsData) => {
    let data = [];
    if (eventsData) {
      data = eventsData;
    } else {
      data = this.state.eventsDataCheck;
    }

    const mergedItem = [...this.state.secsMetaData, ...data];
    const filteredArr = mergedItem.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    this.timeline?.current?.$el?.setItems(filteredArr);
  };

  rangeChangeHandler = (event) => {
    let timeInMin = moment(event.start)
      .add({ minutes: 4, seconds: 20 })
      .toDate();
    let timeInDay = moment(event.start).add({ days: 1, hours: 6 }).toDate();
    let timeInSec = moment(event.start).add({ seconds: 40 }).toDate();
    if (event.byUser && this.state.fakeTime) {
      if (!this.isRangeChanging) {
        this.isRangeChanging = true;
      }
      if (this.snapshotURL) {
        this.snapshotURL = null;
      }
      if (this.snapList) {
        this.snapList = null;
      }
      clearInterval(this.MultiCVRAutoPlay);
      if (
        this.state.selectedOption === 'Mins' &&
        Utils.getUnixDate(timeInMin) !==
          Utils.getUnixDate(this.state.activeTime)
      ) {
        this.setState({
          activeTime: timeInMin,
        });
      } else if (
        this.state.selectedOption === 'Days' &&
        timeInDay !== this.state.activeTime
      ) {
        this.setState({
          activeTime: timeInDay,
        });
      } else if (
        this.state.selectedOption === 'Secs' &&
        timeInSec !== this.state.activeTime
      ) {
        this.setState({
          activeTime: timeInSec,
        });
      }
      if (
        moment(this.state.activeTime).unix() !==
          moment(this.currentTime).unix() &&
        !this.state.clickAction
      ) {
        this.currentTime = this.state.activeTime;
      }
      this.setState({
        CVRSnapMode: true,
        liveSnapshot: false,
        moveTimelineImage: true,
      });
      if (this.state.liveStreamMode) {
        if (!this.pauseRequestSent) {
          this.pauseRequestSent = true;
          disconnectWithMQTT();
        }
        this.setState({
          liveStreamMode: false,
        });
      }
      if (this.state.cvrMode && !this.state.liveStreamMode) {
        if (!this.pauseRequestSent) {
          this.pauseRequestSent = true;
          this.sendPauseCVR();
        }
      }
    } else {
      if (
        this.state.minsView &&
        Utils.getUnixDate(timeInMin) !==
          Utils.getUnixDate(this.state.activeTime)
      ) {
        this.setState({
          activeTime: moment(event.start)
            .add({ minutes: 4, seconds: 20 })
            .toDate(),
        });
      } else if (this.state.daysView) {
        this.setState({
          activeTime: moment(event.start).add({ days: 1, hours: 6 }).toDate(),
        });
      } else if (this.state.secsView) {
        this.setState({
          activeTime: moment(event.start).add({ seconds: 40 }).toDate(),
        });
      }
    }
  };

  rangeChangedHandler = (event) => {
    if (
      moment
        .tz(
          moment(event.start).add({ minutes: 4, seconds: 20 }),
          this.state.timeZone
        )
        .isDST()
    ) {
      this.updateTimeZone(moment(event.start).add({ minutes: 4, seconds: 20 }));
    }
    if (this.state.cvrMode && !this.minOptionClicked) {
      this.props?.allDeviceIds?.forEach((deviceId) => {
        const findMetaByDevice = this.state.metadataByDeviceId?.find(
          (devMeta) => devMeta.deviceId === deviceId
        );
        if (
          findMetaByDevice &&
          findMetaByDevice?.metadata?.length > 0 &&
          !this.minOptionClicked
        ) {
          const isInRange = this.checkMetaInRange(
            findMetaByDevice?.metadata,
            this.state.activeTime
          );
          const isEOSDevice = checkEOSDevices();
          const checkEOSDevice =
            isEOSDevice.length > 0 && isEOSDevice.includes(deviceId);
          if (isEOSDevice.length > 0 && checkEOSDevice) {
            if (!this.state.noContentDeviceIds.includes(deviceId)) {
              this.setState({
                noContentDeviceIds: [
                  ...this.state.noContentDeviceIds,
                  deviceId,
                ],
              });
            }
          }
          if (isInRange) {
            const findNoContentDevice =
              this.state.noContentDeviceIds?.findIndex(
                (device) => device === deviceId
              );
            const updatedArray = [...this.state.noContentDeviceIds];
            if (findNoContentDevice > -1) {
              updatedArray.splice(findNoContentDevice, 1);
              this.setState(
                {
                  noContentDeviceIds: updatedArray,
                },
                () => {
                  const loaderObj = {
                    deviceId,
                    isLoading: true,
                  };
                  this.props?.setMultiLiveStreamLoader(loaderObj);
                  sendPlayCVR(this.state.activeTime, deviceId);
                }
              );
            }
          } else {
            const loaderObj = {
              deviceId,
              isLoading: false,
            };
            this.props?.setMultiLiveStreamLoader(loaderObj);
            const findNoContentDevice =
              this.state.noContentDeviceIds?.findIndex(
                (device) => device === deviceId
              );
            if (findNoContentDevice === -1) {
              this.setState(
                {
                  noContentDeviceIds: [
                    ...this.state.noContentDeviceIds,
                    deviceId,
                  ],
                },
                () => {
                  if (!this.minOptionClicked) {
                    sendPauseCVR(deviceId);
                  }
                }
              );
            }
          }
        } else {
          const loaderObj = {
            deviceId,
            isLoading: false,
          };
          this.props?.setMultiLiveStreamLoader(loaderObj);
        }
      });
      this.props?.setPlaybackActiveTime(this.state.activeTime);
    }
    if (event.byUser) {
      if (this.isRangeChanging) {
        this.isRangeChanging = false;
      }
      this.updateTimeZone(moment(event.start).add({ minutes: 4, seconds: 20 }));
      const time =
        this.state.selectedOption === 'Days'
          ? moment(event.end).subtract({ days: 1, hours: 6 }).toDate()
          : moment(event.end).subtract({ minutes: 4, seconds: 20 }).toDate();

      this.setState({
        selectedEventTime: Utils.getUnixDate(time) * 1000,
      });
      const scrubStopTimeDiff = new Date() - time;
      if (scrubStopTimeDiff <= 6000) {
        this.onGoLive();
        this.callAllAPIs(new Date());
      } else {
        if (this.pauseRequestSent) {
          this.pauseRequestSent = false;
        }
        if (
          this.state.cvrMode ||
          (!this.state.manualClicked && time < new Date())
        ) {
          this.setState(
            {
              CVRSnapMode: false,
              cvrMode: true,
            },
            () => {
              this.props?.allDeviceIds?.forEach((deviceId) => {
                const loaderObj = {
                  deviceId,
                  isLoading: true,
                };
                this.props?.setMultiLiveStreamLoader(loaderObj);
              });
              setTimeout(() => {
                this.sendPlayCVR();
                this.moveTimeline();
              }, 1500);
            }
          );
        }
        this.setState({
          moveTimelineImage: false,
        });
        if (!this.state.CVRSnapMode || !this.state.manualClicked) {
          this.setState({
            cvrMode: true,
          });
        }
        switch (this.state.selectedOption) {
          case 'Mins':
            this.fetchEventsDataInRange();
            this.fetchCVRMetaData();
            break;
          case 'Days':
            this.fetchCVRMetaData();
            this.fetchEventsDataInDays();
            break;
          case 'Secs':
            this.fetchCVRMetaData();
            this.fetchEventsDataInSecs();
            break;
          default:
            break;
        }
      }
    }
  };

  getItemsMetaData = (data) => {
    const minsAdjustment = { minutes: 0 };
    const daysAdjustment = { days: 1, hours: 6 };
    const secsAdjustment = { seconds: 0 };

    const minsData = getTimelineData(data, minsAdjustment);
    const daysData = getTimelineData(data, daysAdjustment);
    const secsData = getTimelineData(data, secsAdjustment);

    this.setState(
      {
        minsMetaData: minsData,
        daysMetaData: daysData,
        secsMetaData: secsData,
      },
      () => {
        this.updateEventsPlottingInRange(this.state.eventsDataCheck);
      }
    );
  };

  enterFullScreen = () => {
    this.setState({
      fullscreen: true,
    });
    this.props.enterFullScreen();
  };

  exitFullScreen = () => {
    this.setState({
      fullscreen: false,
    });
    this.props.exitFullScreen();
  };

  onSubmit = (data) => {
    this.props.setShowJumpToDateModal(false);
    this.updateTimeZone(data.selectedDate);
    this.setState({
      selectedTime: data.selectedTime,
      selectedDate: data.selectedDate,
    });
    if (this.state.fullscreenBackup) {
      this.enterFullScreen();
      this.setState({
        fullscreenBackup: false,
      });
    }
    this.filterObj = data;
    let date = data.filterdate;
    let parsed = moment
      .tz(moment(date, 'YYYY/MM/DD hh:mm:ss A'), this.state.timeZone)
      .format('MMMM Do, YYYY - hh:mm:ss A z');
    this.setState({
      showDateTimeModal: false,
      filterdate: parsed,
    });
    this.getMetaDataByFilterdate(date.getTime());
    switch (this.state.selectedOption) {
      case 'Mins':
        this.fetchEventsDataInRange(date.getTime());
        break;
      case 'Days':
        this.fetchEventsDataInDays(date.getTime());
        break;
      case 'Secs':
        this.fetchEventsDataInSecs(date.getTime());
        break;
      default:
        break;
    }
  };

  jumpToDate = (date) => {
    this.sendPauseCVR();
    disconnectWithMQTT();
    this.setState({
      eventTimestamp: date,
    });
    clearInterval(this.MultiCVRAutoPlay);
    this.updateTimeZone(Utils.getDate(date / 1000));
    if (
      this.state.internalEventFromSearch !== this.props.internalEventFromSearch
    ) {
      setTimeout(() => {
        this.setState({
          internalEventFromSearch: this.props.internalEventFromSearch,
        });
      }, 1000);
    }
    if (this.state.activeTime !== Utils.getDate(date / 1000)) {
      this.timeline?.current?.$el?.moveTo(Utils.getDate(date / 1000), [
        { duration: 0 },
      ]);
    }
    setTimeout(() => {
      if (this.state.liveStreamMode || this.state.cvrMode) {
        this.snapshotURL = null;
        this.props?.allDeviceIds?.forEach((deviceId) => {
          const loaderObj = {
            deviceId,
            isLoading: true,
          };
          this.props?.setMultiLiveStreamLoader(loaderObj);
        });
        this.setState(
          {
            cvrMode: true,
            liveStreamMode: false,
            liveSnapshot: false,
            CVRSnapMode: false,
            selectedEventTime: date,
          },
          () => {
            if (this.snapList) {
              this.snapList = null;
            }
            this.sendPlayCVR(Utils.getDate(date / 1000));
            if (!this.state.CVRSnapMode) {
              this.moveTimeline();
            }
          }
        );
      } else {
        this.snapshotURL = null;
        this.snapList = null;
        this.setState({
          CVRSnapMode: true,
          liveSnapshot: false,
        });
      }
    }, 2000);
  };

  getMetaDataByFilterdate = (date) => {
    let metaData = [];
    let itemProceed = 0;
    let playRequestSent = false;
    let minOptions;
    if (
      this.state.selectedOption === 'Mins' ||
      this.state.selectedOption === 'Secs'
    ) {
      minOptions = { minutes: 5 };
    } else if (this.state.selectedOption === 'Days') {
      minOptions = { days: 1 };
    }
    const startTime = Utils.getUnixDate(moment(date).subtract(minOptions));
    const endTime = Utils.getUnixDate(moment(date).add(minOptions));
    const deviceMetadata = [];
    this.props?.allDeviceIds?.forEach(async (deviceId, index, array) => {
      await axios
        .get(
          `timeline/device/${deviceId}/metadata?startTime=${startTime}000&endTime=${endTime}000`,
          Utils.requestHeader()
        )
        .then((response) => {
          itemProceed++;
          if (response?.data?.data.length > 0) {
            if (metaData?.length > 0) {
              metaData = metaData.concat(response.data?.data);
            } else {
              metaData = response?.data?.data;
            }
            const deviceBaseMetadata = {
              deviceId: deviceId,
              metadata: response.data?.data,
            };
            deviceMetadata.push(deviceBaseMetadata);
            this.setState(
              {
                rawMetaData: metaData,
                metadataByDeviceId: deviceMetadata,
              },
              () => {
                this.getItemsMetaData(metaData);
                if (itemProceed === array.length && !playRequestSent) {
                  this.checkMissingData(date, false);
                  playRequestSent = true;
                }
              }
            );
          } else {
            if (itemProceed === array.length && !playRequestSent) {
              this.checkMissingData(date, false);
              playRequestSent = true;
            }
          }
        });
    });
  };

  checkMissingData = (date, isOldData) => {
    let crossingMeta = true;
    for (let range of this.state.rawMetaData) {
      if (range?.start <= date && date < range?.end) {
        crossingMeta = false;
        this.jumpToDate(date);
        break;
      } else if (isOldData && range?.start > date) {
        crossingMeta = false;
        this.getMetaDataByFilterdate(date);
        break;
      } else if (!isOldData && range?.start > date) {
        crossingMeta = false;
        this.jumpToDate(range?.start);
        break;
      }
    }
    if (crossingMeta) {
      let parsed = moment
        .tz(
          moment(new Date(date), 'YYYY/MM/DD hh:mm:ss A'),
          this.state.timeZone
        )
        .format('MMMM Do, YYYY - hh:mm:ss A z');
      this.props.showErrorToaster(
        constants.CAMERAS_LIVE_JUMP_TO_DATE_NO_METADATA + parsed
      );
    }
  };

  hideShowdateTimeModal = (status) => {
    this.setState({
      showDateTimeModal: status,
    });
    this.props.setShowJumpToDateModal(status);
  };

  getDeviceInfo = (deviceId) => {
    if (this.props?.allDevices?.length) {
      const deviceData = this.props?.allDevices?.find(
        (device) => device.deviceId === deviceId
      );
      return deviceData;
    }
  };

  downloadLiveSnapshotForSafari = async (index) => {
    const { allDeviceIds } = this.props;
    if (index < allDeviceIds?.length) {
      let deviceId = allDeviceIds?.[index];
      const deviceData = this.getDeviceInfo(deviceId);
      const time = moment(this.state.activeTime).format(
        'YYYY-MM-DD - hh.mm.ss A'
      );
      const id = this.state.liveStreamMode
        ? `${deviceId}${deviceId}`
        : `${deviceId}`;
      const data = await multiStreamSnapDownload(
        `${deviceData?.deviceName} - ${time}`,
        `canvas${id}`,
        `video${id}`,
        deviceId
      );
      if (data) {
        index++;
        setTimeout(() => {
          this.downloadLiveSnapshotForSafari(index);
        }, 1500);
      }
    }
  };

  downloadSnapshotsForSafari = async (index) => {
    const { allDeviceIds } = this.props;
    if (index < allDeviceIds?.length) {
      const deviceId = allDeviceIds?.[index];
      const deviceData = this.getDeviceInfo(deviceId);
      const time = moment(this.state.activeTime).format(
        'YYYY-MM-DD - hh.mm.ss A'
      );
      if (
        this.state.liveSnapshot ||
        (this.state.CVRSnapMode && this.snapshotURL)
      ) {
        let a = document.createElement('a');
        a.download = `${deviceData?.deviceName} - ${time}.png`;
        const liveSnapEle = document.getElementById(`imgSnapshot_${deviceId}`);
        if (this.state.liveSnapshot && liveSnapEle) {
          a.href = liveSnapEle.src;
        } else if (this.state.CVRSnapMode) {
          a.href = this.snapshotURL?.[deviceId];
        }
        document.body.appendChild(a);
        a.click();
        index++;
      } else {
        const data = await this.fetchTimeStampImage(
          deviceData?.deviceName,
          time,
          deviceId
        );
        if (data) index++;
      }
      setTimeout(() => {
        this.downloadSnapshotsForSafari(index);
      }, 1500);
    }
  };

  downloadLiveImage = () => {
    // This function used to download snapshot during on going live stream
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      // For safari, as safari blocks multiple downloads at once
      let index = 0;
      this.downloadLiveSnapshotForSafari(index);
    } else {
      const { allDeviceIds } = this.props;
      allDeviceIds?.forEach((deviceId, index) => {
        const deviceData = this.getDeviceInfo(deviceId);
        const time = moment(this.state.activeTime).format(
          'YYYY-MM-DD - hh.mm.ss A'
        );
        const id = `${deviceId}${deviceId}`;
        multiStreamSnapDownload(
          `${deviceData?.deviceName} - ${time}`,
          `canvas${id}`,
          `video${id}`,
          deviceId
        );
      });
    }
  };

  downloadPlaybackImage = async () => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const time = moment(this.state.activeTime).format(
      'YYYY-MM-DD - hh.mm.ss A'
    );
    if (isSafari) {
      // For safari, as safari blocks multiple downloads at once
      let index = 0;
      if (!this.state.CVRSnapMode && this.state.cvrMode) {
        this.downloadLiveSnapshotForSafari(index);
      } else {
        this.downloadSnapshotsForSafari(index);
      }
    } else {
      const { allDeviceIds } = this.props;
      allDeviceIds?.forEach((deviceId, index) => {
        const deviceData = this.getDeviceInfo(deviceId);
        if (this.state.liveSnapshot) {
          this.downloadLiveSnapshot(deviceId, deviceData?.deviceName);
        } else if (this.state.CVRSnapMode) {
          if (this.snapshotURL) {
            let a = document.createElement('a');
            a.download = `${deviceData?.deviceName} - ${time}.png`;
            a.href = this.snapshotURL?.[deviceId];
            document.body.appendChild(a);
            a.click();
          } else {
            this.fetchTimeStampImage(deviceData?.deviceName, time, deviceId);
          }
        }
        if (!this.state.CVRSnapMode && this.state.cvrMode) {
          multiStreamSnapDownload(
            `${deviceData?.deviceName} - ${time}`,
            `canvas${deviceId}`,
            `video${deviceId}`,
            deviceId
          );
        }
      });
    }
  };

  fetchPlaybackSnapshot = async (typeCanvas, typeVideo, deviceId) => {
    // This function fetch and show the snapshot when playback is running and we click pause button.
    let canvas = document.getElementById(typeCanvas);
    let video = document.getElementById(typeVideo);
    const context = canvas?.getContext('2d');
    const bitmapObjects = this.props?.streaming?.bitmapObjects;
    const bitmapObject = bitmapObjects ? bitmapObjects[deviceId] : null;
    const bitmapObjectHeight = bitmapObject ? bitmapObject?.StreamHeight : 450;
    const bitmapObjectWidth = bitmapObject ? bitmapObject?.StreamWidth : 764;
    if (context?.canvas) {
      context.canvas.width = bitmapObjectWidth;
      context.canvas.height = bitmapObjectHeight;
    }
    context?.drawImage(video, 0, 0, bitmapObjectWidth, bitmapObjectHeight);
    const data = canvas?.toDataURL('image/png');
    this.snapshotURL = {
      ...this.snapshotURL,
      [deviceId]: this.props?.streaming?.multiLiveStreamLoader?.[deviceId]
        ? null
        : data,
    };
  };

  downloadLiveSnapshot = (deviceId, deviceName) => {
    // This function is used when we pause the live stream and download live snapshot.
    const time = moment(this.state.activeTime).format(
      'YYYY-MM-DD - hh.mm.ss A'
    );
    let a = document.createElement('a');
    const liveSnapEle = document.getElementById(`imgSnapshot_${deviceId}`);
    if (liveSnapEle) {
      a.download = `${deviceName} - ${time}.png`;
      a.href = liveSnapEle.src;
      document.body.appendChild(a);
      a.click();
    }
  };

  fetchTimeStampImage = async (deviceName, time, deviceId) => {
    // This function download snapshot from s3 bucket while playback is in paused mode
    const unixTime = Utils.getUnixDate(this.state.activeTime);
    const cdnValue = this.props?.streaming?.cdnInfo;
    const bucket = (cdnValue?.bucket).replace('${deviceId}', deviceId);
    const date = Utils.fetchDateInUnix(unixTime);
    await fetch(
      `${cdnValue?.protocol}://${cdnValue?.host}/${bucket}/${date}/${unixTime}.jpg`,
      {
        credentials: 'include',
      }
    )
      .then((response) => {
        if (response.ok) {
          fetch(response?.url, {
            credentials: 'include',
          })
            .then((response) => response.blob())
            .then((blob) => {
              let blobUrl = window.URL.createObjectURL(blob);
              let a = document.createElement('a');
              a.download = `${deviceName} - ${time}.png`;
              a.href = blobUrl;
              document.body.appendChild(a);
              a.click();
              return blobUrl;
            });
        }
      })
      .catch((error) => {
        // Todo: Delete later
        Utils.vmsLogger().log('error comes in catch', error);
      });
  };

  render() {
    return (
      <>
        <div className="main-content-container">
          {this.state.liveStreamMode ? (
            <LiveGridStructure
              activeTime={this.state.activeTime}
              timeZone={this.state.timeZone}
              orgDetails={this.props.orgDetails}
              devicesData={this.props.allDevices}
              currentViewDeviceList={this.props.currentViewDeviceList}
              setCurrentViewDeviceList={this.props.setCurrentViewDeviceList}
              entitleData={this.props?.entitleData}
              metadataByDeviceId={this.state.metadataByDeviceId}
            />
          ) : this.state.activeTime && this.state.CVRSnapMode ? (
            <ImageGridStructure
              moveTimeline={this.state.moveTimelineImage}
              liveSnapshot={this.state.liveSnapshot}
              time={this.state.activeTime}
              cdnValue={this.props?.streaming?.cdnInfo}
              timeZone={this.state.timeZone}
              orgDetails={this.props.orgDetails}
              devicesData={this.props.allDevices}
              currentViewDeviceList={this.props.currentViewDeviceList}
              setCurrentViewDeviceList={this.props.setCurrentViewDeviceList}
              snapshotURL={this.snapshotURL}
              snapshotImages={this.snapList}
            />
          ) : !this.state.CVRSnapMode ? (
            <PlaybackGridStructure
              activeTime={this.state.activeTime}
              timeZone={this.state.timeZone}
              noContentDeviceIds={this.state.noContentDeviceIds}
              orgDetails={this.props.orgDetails}
              devicesData={this.props.allDevices}
              currentViewDeviceList={this.props.currentViewDeviceList}
              setCurrentViewDeviceList={this.props.setCurrentViewDeviceList}
              cdnValue={this.props?.streaming?.cdnInfo}
              selectedEventTime={this.state.selectedEventTime}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="stream-timeline-wrapper timeline-controls-page multiview-timeline">
          <div className={`scrubber-wrapper`}>
            <div className="timelines-controls-wrapper">
              {this.state.liveStreamMode && (
                <>
                  <div className="timeline-controls-icons">
                    {!(this.state.cvrMode || this.state.liveStreamMode) && (
                      <span
                        onClick={() => this.onPlayCVR()}
                        className="icons-image"
                      >
                        <img width={16} src={play} alt="icon" />
                      </span>
                    )}

                    {(this.state.cvrMode || this.state.liveStreamMode) && (
                      <span
                        onClick={(e) => this.onPauseCVR(e)}
                        className="icons-image"
                      >
                        <img width={16} src={pause} alt="icon" />
                      </span>
                    )}
                    <span
                      className="icons-image"
                      onClick={() => this.downloadLiveImage()}
                    >
                      <img src={camera} alt="icon" />
                    </span>
                    <button onClick={() => this.onClickMins()} className="mins">
                      {this.state.selectedOption === 'Mins' ? 'Mins' : ''}
                      {this.state.selectedOption === 'Days' ? 'Days' : ''}
                      {this.state.selectedOption === 'Secs' ? 'Secs' : ''}
                    </button>
                  </div>
                  <div className="live">
                    Live
                    <span
                      className="filter-date-image"
                      onClick={() => {
                        this.hideShowdateTimeModal(true);
                        if (this.state.fullscreen) {
                          this.exitFullScreen();
                          this.setState({
                            fullscreenBackup: true,
                          });
                        }
                      }}
                    >
                      <img src={calender} alt="" />
                    </span>
                  </div>
                  <div className={`active_time`}>
                    {moment
                      .tz(moment(this.state.activeTime), this.state.timeZone)
                      .format('hh:mm:ss A z')}
                    <span
                      className="icons-image"
                      onClick={() =>
                        this.state.fullscreen
                          ? this.exitFullScreen()
                          : this.enterFullScreen()
                      }
                    >
                      <img
                        src={this.state.fullscreen ? minimize : fullscreen}
                        alt="icon"
                      />
                    </span>
                  </div>
                </>
              )}
              {!this.state.liveStreamMode && (
                <>
                  <div className="timeline-controls-icons">
                    {!(
                      this.state.cvrMode ||
                      this.state.liveStreamMode ||
                      !this.state.manualClicked
                    ) && (
                      <span
                        onClick={() => this.onPlayCVR()}
                        className="icons-image"
                      >
                        <img width={16} src={play} alt="icon" />
                      </span>
                    )}

                    {(this.state.cvrMode ||
                      this.state.liveStreamMode ||
                      !this.state.manualClicked) && (
                      <span
                        onClick={(e) => this.onPauseCVR(e)}
                        className="icons-image"
                      >
                        <img width={16} src={pause} alt="icon" />
                      </span>
                    )}
                    <span
                      className="icons-image"
                      onClick={() => this.downloadPlaybackImage()}
                    >
                      <img src={camera} alt="icon" />
                    </span>
                    <button onClick={() => this.onClickMins()} className="mins">
                      {this.state.selectedOption === 'Mins' ? 'Mins' : ''}
                      {this.state.selectedOption === 'Days' ? 'Days' : ''}
                      {this.state.selectedOption === 'Secs' ? 'Secs' : ''}
                    </button>
                    {/* {this.state.cvrMode && !this.state.CVRSnapMode && (
                      <div
                        className="sync-command d-flex justify-content-center align-items-center gap-1"
                        onClick={() => {
                          // this.onClickSync();
                          this.onClickSyncPassPlayCommand()
                        }}
                      >
                        <GoSync
                          size={16}
                          color={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--brand_primary')}
                        />
                        {constants.SYNC_COMMAND_LABEL}
                      </div>
                    )}
                     
                      <ToggleInput
                      classes={`sync-command p-0 bg-transparent d-flex justify-content-center align-items-center gap-1`}
                      labelClass={'text-white'}
                        label={'Single ice'}
                        name={'SingleIce'}
                        value={this.state.oneIceSend}
                        labelColor={true}
                        changeHandler={(e) => {
                          this.setState({
                            oneIceSend: !this.state.oneIceSend
                          });
                        }}
                      /> */}
                  </div>
                  <div className={`active_time`}>
                    {moment
                      .tz(moment(this.state.activeTime), this.state.timeZone)
                      .format('MMM DD, YYYY - hh:mm:ss A z')}
                    <div className="date">
                      <span
                        className="filter-date-image"
                        onClick={() => {
                          this.hideShowdateTimeModal(true);
                          if (this.state.fullscreen) {
                            this.exitFullScreen();
                            this.setState({
                              fullscreenBackup: true,
                            });
                          }
                        }}
                      >
                        <img src={calender} alt="" />
                      </span>
                    </div>
                  </div>
                  <div className="golive-view">
                    <button
                      className="live"
                      onClick={() => {
                        this.onGoLive();
                        this.callAllAPIs(new Date());
                      }}
                    >
                      Go Live
                    </button>
                    <span
                      className="icons-image"
                      onClick={() =>
                        this.state.fullscreen
                          ? this.exitFullScreen()
                          : this.enterFullScreen()
                      }
                    >
                      <img
                        src={this.state.fullscreen ? minimize : fullscreen}
                        alt="icon"
                      />
                    </span>
                  </div>
                </>
              )}
            </div>
            {!this.state.clickAction && this.state.metaDataHere && (
              <div className={`scrubber`}>
                <Timeline
                  ref={this.timeline}
                  options={this.getOptions()}
                  rangechangeHandler={(event) => this.rangeChangeHandler(event)}
                  rangechangedHandler={(event) =>
                    this.rangeChangedHandler(event)
                  }
                />
              </div>
            )}
          </div>
          {this.state.liveStreamMode && (
            <div className={`timeline-icons golive`}>
              <button
                onClick={() => {
                  this.onGoLive();
                  this.callAllAPIs(new Date());
                }}
                className="golive-button"
              >
                {constants.TOP_HEADER_LIVE_NAV_TITLE}
              </button>
            </div>
          )}
        </div>
        {/* Go to Date */}
        <SiteModal
          modalTitle={constants.CAMERAS_LIVE_GO_TO_DATE_MODAL_TITLE}
          showModal={this.state.showDateTimeModal}
          hideModal={() => {
            this.hideShowdateTimeModal(false);
          }}
          classes="date-time-picker-modal"
          size="sm"
        >
          <DateTimePicker
            date={this.state.selectedDate}
            time={this.state.selectedTime}
            minDate={this.props.streaming.cvrStartDate}
            onSubmit={this.onSubmit}
            timeZone={this.state.timeZone}
          />
        </SiteModal>
      </>
    );
  }
}

const mapDispatchToProps = {
  setMetaData,
  //setSnapshotImage,
  setPlaybackActiveTime,
  setMultiLiveStreamLoader,
  setKeepAliveRequest,
  resetEvents,
};

const mapStoreStateToProps = (state) => ({
  account: state.accounts,
  streaming: state.streaming,
  views: state.views,
});

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(DeviceWallTimeline);
