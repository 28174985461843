import { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Button } from 'react-bootstrap';
import { IoIosSearch } from 'react-icons/io';
import { FaCircle } from 'react-icons/fa';
import { deviceOptions } from '../../assets/images';
import { constants, Utils, AppDefaults } from '../../helpers/';
import { useCustOrgLocationArea } from '../../store/CustOrgLocationAreaStore';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import {
  setRemoteStream,
  getCDNInfo,
  setIsReloadedStream,
} from '../../store/reducers/StreamingReducer';
import { setDeviceInformation } from '../../store/reducers/AccountReducer';
import { SiteSpinner } from '../../components/common/';
import { disconnectWithMQTT } from '../../utils/connection/mqttConnection';
import './CameraWallDevices.scss';
import NavDropdown from 'react-bootstrap/NavDropdown';
import CameraWallSnapshot from './CameraWallSnapshot';
import CameraWallTable from './CameraWallTable';
import LocationAreaSelector from './LocationAreaSelector';
import { usePoliciesStore } from '../../store/policiesStore';
import { FiCameraOff } from 'react-icons/fi';
import { MdOutlineAddAPhoto } from 'react-icons/md';
import { HiOutlineVideoCamera } from 'react-icons/hi2';
import { MdOutlineDragIndicator } from 'react-icons/md';
import { FiSettings } from 'react-icons/fi';
import { RxCrossCircled } from 'react-icons/rx';
import { fetchCustomerOrgDevices } from '../../store/CustomerOrgDevicesStoreIDB';
import {
  setAllDevicesData,
  setAllDevicesDataNotification,
} from '../../store/AccountStoreIDB';
import { setSelfStatus } from '../../store/reducers/OrganizationsReducer';
import { useDeviceSnapshots } from '../../store/DeviceSnapshotsStore';
import { getAccountId } from '../../store/reducers/AccountReducer';

const CameraWallDevices = ({
  viewDetails,
  setSelectedViewDevices,
  setTotalDeviceCount,
  newViewDragMode,
  setNewViewDragMode,
  editView,
  isDragging,
  selectedViewDevices,
  orgDetails,
  custOrgDevices,
  allDevices,
}) => {
  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [currentSession, setCurrentSession] = useState(uuidv4());
  // const [devices, setDevices] = useState([]);
  const devicesRef = useRef([]);
  // const [filteredDevices, setFilteredDevices] = useState([]);
  const filteredDevicesRef = useRef([]);
  const localSelectedViewDevices = useRef(selectedViewDevices);
  const [isLoading, setIsLoading] = useState(true);
  const [redirectingLoader, setRedirectingLoader] = useState(false);
  const [searchText, setSearchText] = useState('');
  const cdnInfo = useSelector(getCDNInfo);
  const [hasDevices, setHasDevices] = useState(0);
  let [searchParams] = useSearchParams();
  const viewId = searchParams.get('viewId');
  // const [viewDevices, setViewDevices] = useState([]);
  const [viewDevicesDetails, setViewDevicesDetails] = useState({});
  const [isScrolled, setIsScrolled] = useState(false);
  const dispatch = useDispatch();
  const {
    getDeviceWallSelectedLocation,
    setDeviceWallSelectedLocation,
    getDeviceWallSelectedArea,
    setDeviceWallSelectedArea,
  } = useCustOrgLocationArea();
  const { getCustomerOrgLocations, getCustomerOrgLocationsData } =
    useCustomerOrgLocations();

  const selectedLocation = getDeviceWallSelectedLocation();
  const selectedArea = getDeviceWallSelectedArea();
  const navigate = useNavigate();
  const custOrgLocations = getCustomerOrgLocationsData();
  const { getLoggedInUserPolicies } = usePoliciesStore();
  const userPolicies = getLoggedInUserPolicies();
  const { setDeviceSnapshots, getDeviceSnapshots, resetDeviceSnapshots } =
    useDeviceSnapshots();
  const accountId = useSelector(getAccountId);

  useEffect(() => {
    if (selectedViewDevices !== localSelectedViewDevices.current) {
      localSelectedViewDevices.current = [...new Set(selectedViewDevices)];
    }
  }, [selectedViewDevices]);

  useEffect(() => {
    updateViewDevicesDetils(viewDetails?.devices ? viewDetails?.devices : []);
    if (
      viewDetails &&
      custOrgDevices?.length &&
      custOrgLocations?.length &&
      allDevices?.length
    ) {
      combineDeviceAndLocationData();
    }
    setNewViewDragMode(false);
  }, [viewDetails]);

  const combineDeviceAndLocationDataCallback = () => {
    if (custOrgLocations?.length) {
      combineDeviceAndLocationData();
    }
  };
  const debounceCombineDeviceAndLocationData = _.debounce(
    combineDeviceAndLocationDataCallback,
    100
  );

  useEffect(() => {
    if (custOrgLocations?.length && allDevices?.length) {
      debounceCombineDeviceAndLocationData();
    }
  }, [custOrgLocations?.length, custOrgDevices, allDevices?.length]);

  const filterDevicesByLocationAndAreaCallback = (event) => {
    if (devicesRef?.current?.length) {
      filterDevicesByLocationAndArea(devicesRef?.current);
    }
  };

  const debounceFilterDevicesByLocationAndArea = _.debounce(
    filterDevicesByLocationAndAreaCallback,
    1000
  );

  useEffect(() => {
    debounceFilterDevicesByLocationAndArea();
  }, [selectedLocation, selectedArea, searchText]);

  useEffect(() => {
    setCurrentSession(uuidv4());
    if (orgDetails) {
      const childDvs = Utils.getTotalChildDevices(orgDetails?.devices);
      setIsLoading(false);
      setHasDevices(false);
      if (!childDvs.length) {
        setIsLoading(false);
        setHasDevices(false);
        filteredDevicesRef.current = [];
      }
    }
    if (!selectedLocation.locationId) {
      setDeviceWallSelectedLocation({
        locationId: AppDefaults.ALL_LOCATIONS_ID,
      });
    }
    if (!selectedArea.areaId) {
      setDeviceWallSelectedArea({
        areaId: constants.LOCATION_DROPDOWN_ALL_AREAS_ID,
      });
    }
    fetchCameraWallData();
  }, [orgDetails?.orgId]);

  const updateViewDevicesDetils = (viewDevices) => {
    const data = {};
    viewDevices.forEach((deviceId) => {
      const childDevice = devicesRef?.current?.find(
        (device) => device.deviceId === deviceId
      );
      data[deviceId] = childDevice;
    });
    setViewDevicesDetails(data);
  };

  const fetchCameraWallData = () => {
    fetchLocations();
    fetchDevices();
    dispatch(setRemoteStream(null));
    disconnectWithMQTT();
  };

  const fetchDevices = useCallback(async () => {
    try {
      if (orgDetails?.orgId && !editView) {
        const fetchResult = await fetchCustomerOrgDevices(
          `device/orgs/${orgDetails?.orgId}/devices`
        );

        if (fetchResult.status === 'success') {
          dispatch(setSelfStatus('1'));
        } else {
          dispatch(setSelfStatus('1'));
          if (!fetchResult?.msg?.includes(AppDefaults.ERR_CANCELED)) {
            setShowToast(true);
            setUserMsg(fetchResult?.msg);
          }
          setIsLoading(false);
        }
      } else {
        throw new Error('ERROR: Could not retrieve organization details');
      }
    } catch (err) {
      dispatch(setSelfStatus('1'));
      if (!err?.msg?.includes(AppDefaults.ERR_CANCELED)) {
        setShowToast(true);
        setUserMsg(err?.msg);
      } else {
        setIsLoading(false);
      }
    }
  });

  const fetchLocations = useCallback(async () => {
    try {
      if (orgDetails?.orgId) {
        let fetchResult;
        if (
          !custOrgLocations?.length ||
          (custOrgLocations?.length &&
            custOrgLocations[0].orgId !== orgDetails?.orgId)
        ) {
          fetchResult = await getCustomerOrgLocations(
            `partner/orgs/${orgDetails?.orgId}/locations`
          );
          if (fetchResult.status === 'success') {
          } else {
            if (!fetchResult?.msg?.includes(AppDefaults.ERR_CANCELED)) {
              setShowToast(true);
              setUserMsg(fetchResult?.msg);
            }
            setIsLoading(false);
          }
        }
      } else {
        throw new Error('ERROR: Could not retrieve organization details');
      }
    } catch (err) {
      if (!err?.msg?.includes(AppDefaults.ERR_CANCELED)) {
        setShowToast(true);
        setUserMsg(err?.msg);
      } else {
        setIsLoading(false);
      }
    }
  });

  const combineDeviceAndLocationData = async () => {
    const locationAreas = [];
    const checkedStatusArray = [];

    const cameraWallDevices = structuredClone(custOrgDevices);

    // Retrieve the location name for each device
    cameraWallDevices.forEach((cameraDevice) => {
      const currentArea = custOrgLocations.find(
        (orgLocation) => orgLocation.locationId === cameraDevice?.locationId
      );

      if (
        locationAreas.findIndex(
          (locationArea) => locationArea.locationId === cameraDevice?.locationId
        ) === -1
      ) {
        const deviceArea = {
          deviceId: cameraDevice?.deviceId,
          areas: currentArea?.areas.length > 0 ? [...currentArea?.areas] : [],
        };

        locationAreas.push({
          locationId: cameraDevice?.locationId,
          areas: [...deviceArea.areas],
        });
      }

      cameraDevice.locationName = currentArea?.locationName;
      const deviceAreaName =
        currentArea?.areas.find((area) => area.areaId === cameraDevice.areaId)
          ?.areaName || '';

      cameraDevice.areaName = deviceAreaName;
      cameraDevice.locationAreaNames =
        currentArea?.locationName + ' - ' + deviceAreaName;

      const filteredLocationArea = locationAreas.find(
        (locationArea) => locationArea?.locationId === cameraDevice?.locationId
      ).areas;

      cameraDevice.areas = filteredLocationArea
        ? [...filteredLocationArea]
        : [];

      const viewStatus = localSelectedViewDevices?.current?.includes(
        cameraDevice?.deviceId
      );
      cameraDevice.style = null;
      cameraDevice.displayDeviceStatus = Utils.getDeviceStatus(
        cameraDevice.deviceStatus,
        cameraDevice.connectionStatus
      );
      if (viewStatus) {
        checkedStatusArray.push(cameraDevice?.deviceId);
      }
    });
    updateViewDevicesDetils(checkedStatusArray);
    const updatedAllDevices = allDevices.map((d) => {
      const foundDevice = cameraWallDevices.find(
        (c) => c.deviceId === d.deviceId
      );
      return foundDevice ? foundDevice : d;
    });
    devicesRef.current =
      cameraWallDevices?.length > 0 ? [...cameraWallDevices] : [];
    setTotalDeviceCount(
      cameraWallDevices?.length > 0 ? cameraWallDevices?.length : 0
    );
    if (cameraWallDevices?.length) {
      filterDevicesByLocationAndArea(cameraWallDevices);
    }
    if (updatedAllDevices?.length > 0) {
      await setAllDevicesData([...updatedAllDevices]);
    }
    await setAllDevicesDataNotification(
      cameraWallDevices?.length > 0 ? [...cameraWallDevices] : []
    );
  };

  const filterDevicesByLocationAndArea = (devciesList) => {
    let filteredDeviceData;
    if (_.isObject(selectedLocation) && selectedLocation?.locationId) {
      if (selectedLocation?.locationId !== AppDefaults.ALL_LOCATIONS_ID) {
        filteredDeviceData = devciesList.filter(
          (device) => device?.locationId === selectedLocation?.locationId
        );
      } else {
        filteredDeviceData = devciesList?.length > 0 ? [...devciesList] : [];
      }
    } else {
      filteredDeviceData = devciesList?.length > 0 ? [...devciesList] : [];
    }

    if (_.isObject(selectedArea) && selectedArea?.areaId) {
      if (selectedArea?.areaId !== constants.LOCATION_DROPDOWN_ALL_AREAS_ID) {
        filteredDeviceData = filteredDeviceData.filter(
          (device) => device?.areaId === selectedArea?.areaId
        );
      } else {
        filteredDeviceData =
          filteredDeviceData?.length > 0 ? [...filteredDeviceData] : [];
      }
    } else {
      filteredDeviceData =
        filteredDeviceData?.length > 0 ? [...filteredDeviceData] : [];
    }
    if (searchText) {
      filteredDeviceData = filteredDeviceData.filter((device) =>
        device?.deviceName?.toUpperCase().includes(searchText.toUpperCase())
      );
    }
    filteredDeviceData = filteredDeviceData.filter(
      (d) =>
        d.displayDeviceStatus === constants.DEVICES_RETURN_DEACTIVATED_STATUS ||
        (d.displayDeviceStatus !== constants.DEVICES_RETURN_CLAIMING_STATUS &&
          d?.apps?.vms)
    );
    const fillterByChannel = JSON.parse(
      JSON.stringify([...filteredDeviceData])
    );
    filteredDevicesRef.current =
      fillterByChannel?.length > 0 ? [...fillterByChannel] : [];
    setIsScrolled(!isScrolled);
    setHasDevices(fillterByChannel?.length > 0 ? true : false);
  };

  useEffect(() => {
    if (hasDevices === true && filteredDevicesRef?.current?.length) {
      setIsLoading(false);
    } else if (hasDevices === false) {
      setIsLoading(false);
    }
  }, [hasDevices, filteredDevicesRef?.current]);

  const onChangeSearchText = (e) => {
    setSearchText(e?.target?.value);
  };

  const OnClickCameraDetails = (device) => {
    setRedirectingLoader(true);
    dispatch(setIsReloadedStream(false));
    getCapabiltiesForDevice(device, redirectToCameraDashboardPage);
  };

  const redirectToCameraDashboardPage = (device) => {
    dispatch(setDeviceInformation(device));
    setRedirectingLoader(false);
    navigate(`/cameras/dashboard.html`, {
      state: {
        id: device?.deviceId,
        cdnInfo: cdnInfo ? cdnInfo : {},
      },
    });
  };

  const getCapabiltiesForDevice = async (deviceData, callback) => {
    if (deviceData?.capability) {
      try {
        const response = await fetch(deviceData?.capability?.url);
        if (response.status === 200) {
          const responseJson = await response.json();
          callback({ ...deviceData, capDetails: responseJson });
        } else {
          callback(deviceData);
        }
      } catch (error) {
        callback(deviceData);
      }
    } else {
      callback(deviceData);
    }
  };

  const OnClickCameraSettings = (device) => {
    setRedirectingLoader(true);
    getCapabiltiesForDevice(device, redirectToChannelSettingsPage);
  };

  const redirectToChannelSettingsPage = (device) => {
    setRedirectingLoader(false);
    dispatch(setDeviceInformation(device));
    device?.deviceId &&
      navigate(`/devices/dashboard.html?deviceId=${device?.deviceId}`);
  };

  const onChanegCheckBox = (e, device) => {
    const { checked } = e.target;
    if (filteredDevicesRef?.current?.length) {
      let viewList = structuredClone(localSelectedViewDevices?.current);
      if (checked) {
        viewList.push(device?.deviceId);
        if (!newViewDragMode) {
          setNewViewDragMode(true);
        }
      } else {
        const index = viewList.indexOf(device?.deviceId, 0);
        if (index > -1) {
          viewList.splice(index, 1);
        }
      }
      updateViewDevicesDetils(viewList);
      localSelectedViewDevices.current = [...new Set(viewList)];
      setSelectedViewDevices(localSelectedViewDevices?.current);
    }
  };
  const getDeviceStatus = (device) => {
    if (
      device?.deviceStatus?.toUpperCase() ===
      constants.DEVICES_DEACTIVATED_DEVICE_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-ready-to-claim" />
          {constants.DEVICES_RETURN_DEACTIVATED_STATUS}
        </div>
      );
    } else if (
      device?.displayDeviceStatus === constants.DEVICES_RETURN_OFFLINE_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-offline" />{' '}
          {device?.displayDeviceStatus}
        </div>
      );
    } else if (
      device?.displayDeviceStatus === constants.DEVICES_RETURN_ONLINE_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-online" />{' '}
          {device?.displayDeviceStatus}
        </div>
      );
    } else if (
      device?.displayDeviceStatus ===
      constants.DEVICES_RETURN_READY_TO_CLAIM_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-ready-to-claim" />{' '}
          {device?.displayDeviceStatus}
        </div>
      );
    } else if (
      device?.displayDeviceStatus === constants.DEVICES_RETURN_ENTERED_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-entered" />{' '}
          {device?.displayDeviceStatus}
        </div>
      );
    } else if (
      device?.displayDeviceStatus === constants.DEVICES_RETURN_CLAIMING_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-claiming" />
          {device?.displayDeviceStatus}
        </div>
      );
    } else if (
      device?.displayDeviceStatus ===
      constants.DEVICES_RETURN_DEACTIVATED_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-offline" />
          {device?.displayDeviceStatus}
        </div>
      );
    }
  };
  const columnsData1 = useMemo(
    () => [
      {
        id: 'viewChecked',
        Header: '',
        accessor: 'viewChecked',
        disableSortBy: true,
        Cell: ({ row }) => {
          let device = row.original;
          return (
            <div className="viewCheckbox">
              {device?.viewChecked ? (
                <MdOutlineDragIndicator className="drag-icon" />
              ) : null}
              <input
                id={`checkbox-${device.deviceId}`}
                type="checkbox"
                checked={localSelectedViewDevices?.current?.includes(
                  device?.deviceId
                )}
                onChange={(e) => onChanegCheckBox(e, device)}
              />
            </div>
          );
        },
      },
      {
        Header: '',
        accessor: 'snapshot',
        disableSortBy: true,
        Cell: ({ row }) => {
          let device = row.original;
          return (
            <span className="snapshot-wrapper">
              <CameraWallSnapshot
                key={device.deviceId}
                deviceElemId={`camera${device.deviceId}`}
                orgId={orgDetails?.orgId}
                cdnInfo={cdnInfo}
                locationAreaName={device.locationAreaNames}
                isHighlighted={false}
                showZoomOptions={false}
                conStatus={device.connectionStatus || 'offline'}
                hubId={device.deviceId}
                isScrolled={isScrolled}
                displayDeviceStatus={Utils.getDeviceStatus(
                  device?.deviceStatus,
                  device?.connectionStatus
                )}
                {...device}
              />
            </span>
          );
        },
      },
      {
        Header: constants.DEVCIE_WALL_HEADER_DEVICE,
        accessor: 'deviceName',
        sortType: (sortPrev, sortAfter) => {
          if (
            sortPrev.values['deviceName']?.toLowerCase() >
            sortAfter.values['deviceName']?.toLowerCase()
          ) {
            return -1;
          } else if (
            sortPrev.values['deviceName']?.toLowerCase() <
            sortAfter.values['deviceName']?.toLowerCase()
          ) {
            return 1;
          } else {
            return 0;
          }
        },
      },
      {
        Header: constants.DEVCIE_WALL_HEADER_lOCATION,
        accessor: 'locationName',
        sortType: 'basic',
      },
      {
        Header: constants.DEVCIE_WALL_HEADER_AREA,
        accessor: 'areaName',
        sortType: 'basic',
      },
      {
        Header: constants.DEVCIE_WALL_HEADER_STATUS,
        accessor: 'displayDeviceStatus',
        Cell: ({ row }) => {
          let device = row.original;
          return <>{getDeviceStatus(device)}</>;
        },
        sortType: (sortPrev, sortAfter) => {
          if (
            sortPrev.values['displayDeviceStatus']?.toLowerCase() >
            sortAfter.values['displayDeviceStatus']?.toLowerCase()
          ) {
            return -1;
          } else if (
            sortPrev.values['displayDeviceStatus']?.toLowerCase() <
            sortAfter.values['displayDeviceStatus']?.toLowerCase()
          ) {
            return 1;
          } else {
            return 0;
          }
        },
      },
    ],
    []
  );

  const columnsData2 = useMemo(
    () => [
      {
        id: 'viewChecked',
        Header: '',
        accessor: 'viewChecked',
        disableSortBy: true,
        Cell: ({ row }) => {
          let device = row.original;
          return (
            <div className="viewCheckbox">
              {device?.viewChecked ? (
                <MdOutlineDragIndicator className="drag-icon" />
              ) : null}
              <input
                id={`checkbox-${device.deviceId}`}
                type="checkbox"
                checked={localSelectedViewDevices?.current?.includes(
                  device?.deviceId
                )}
                onChange={(e) => onChanegCheckBox(e, device)}
              />
            </div>
          );
        },
      },
      {
        Header: '',
        accessor: 'snapshot',
        disableSortBy: true,
        Cell: ({ row }) => {
          let device = row.original;
          return (
            <span className="snapshot-wrapper">
              <CameraWallSnapshot
                key={device.deviceId}
                deviceElemId={`camera${device.deviceId}`}
                orgId={orgDetails?.orgId}
                cdnInfo={cdnInfo}
                locationAreaName={device.locationAreaNames}
                isHighlighted={false}
                showZoomOptions={false}
                conStatus={device.connectionStatus || 'offline'}
                hubId={device.gatewayId}
                isScrolled={isScrolled}
                displayDeviceStatus={Utils.getDeviceStatus(
                  device?.deviceStatus,
                  device?.connectionStatus
                )}
                {...device}
              />
            </span>
          );
        },
      },
      {
        Header: constants.DEVCIE_WALL_HEADER_DEVICE,
        accessor: 'deviceName',
        disableSortBy: false,
        Cell: ({ row }) => {
          let device = row.original;
          return (
            <span
              className="deviceNameClick"
              onClick={() => OnClickCameraDetails(row.original)}
            >
              {device.deviceName}
            </span>
          );
        },
        sortType: (sortPrev, sortAfter) => {
          if (
            sortPrev.values['deviceName']?.toLowerCase() >
            sortAfter.values['deviceName']?.toLowerCase()
          ) {
            return -1;
          } else if (
            sortPrev.values['deviceName']?.toLowerCase() <
            sortAfter.values['deviceName']?.toLowerCase()
          ) {
            return 1;
          } else {
            return 0;
          }
        },
      },
      {
        Header: constants.DEVCIE_WALL_HEADER_lOCATION,
        accessor: 'locationName',
        disableSortBy: false,
        sortType: 'basic',
      },
      {
        Header: constants.DEVCIE_WALL_HEADER_AREA,
        accessor: 'areaName',
        disableSortBy: false,
        sortType: 'basic',
      },
      {
        Header: constants.DEVCIE_WALL_HEADER_STATUS,
        accessor: 'displayDeviceStatus',
        Cell: ({ row }) => {
          let device = row.original;
          return <>{getDeviceStatus(device)}</>;
        },
        sortType: (sortPrev, sortAfter) => {
          if (
            sortPrev.values['displayDeviceStatus']?.toLowerCase() >
            sortAfter.values['displayDeviceStatus']?.toLowerCase()
          ) {
            return -1;
          } else if (
            sortPrev.values['displayDeviceStatus']?.toLowerCase() <
            sortAfter.values['displayDeviceStatus']?.toLowerCase()
          ) {
            return 1;
          } else {
            return 0;
          }
        },
        disableSortBy: false,
      },
      {
        Header: '',
        accessor: 'cameraOptions',
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <NavDropdown
              className="table-action-dropdown"
              title={
                <div>
                  <img src={deviceOptions} alt=""></img>
                </div>
              }
            >
              <NavDropdown.Item
                onClick={() => OnClickCameraDetails(row.original)}
              >
                <HiOutlineVideoCamera
                  size={20}
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--greyscale_64')}
                />
                {constants.DEVCIE_WALL_DEVICE_OPTION_DETAILS}
              </NavDropdown.Item>
              {userPolicies.view_device_settings && (
                <NavDropdown.Item
                  onClick={() => OnClickCameraSettings(row.original)}
                >
                  <FiSettings
                    size={20}
                    color={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--greyscale_64')}
                  />
                  {constants.DEVCIE_WALL_DEVICE_OPTION_SETTINGS}
                </NavDropdown.Item>
              )}
            </NavDropdown>
          );
        },
      },
    ],
    []
  );

  const sortingData = useMemo(
    () => [
      {
        id: 'deviceName',
        desc: true,
      },
      {
        id: 'locationName',
        desc: true,
      },
      {
        id: 'areaName',
        desc: true,
      },
      {
        id: 'connectionStatus',
        desc: true,
      },
      {
        id: 'displayDeviceStatus',
        desc: true,
      },
    ],
    []
  );

  useEffect(() => {
    const accountSnap = getDeviceSnapshots(accountId);
    if (new Date().getTime() - accountSnap > AppDefaults.DAY_IN_MILLISECONDS) {
      resetDeviceSnapshots();
      setDeviceSnapshots(accountId, new Date().getTime());
    }
  }, []);

  return (
    <div
      className="camera-wall-devices"
      style={redirectingLoader ? { pointerEvents: 'none' } : {}}
    >
      {redirectingLoader && (
        <div className="redirecting-loader loading-container">
          <SiteSpinner height="50px" width="50px" />
          <div className="mt-2">{constants.LOADING}</div>
        </div>
      )}
      <div className="main-page-wrapper">
        <div className="filter-wrapper">
          <div className="search-all-container">
            <IoIosSearch className="search-camera-icon" />
            <input
              id="deviceSearch"
              type="text"
              placeholder="Search All"
              className="search-camera-input"
              onChange={(e) => onChangeSearchText(e)}
            />
            {searchText ? (
              <RxCrossCircled
                className="search-close"
                onClick={() => {
                  setSearchText('');
                  document.getElementById('deviceSearch').value = '';
                }}
              />
            ) : null}
          </div>
          {userPolicies.view_location ? (
            <LocationAreaSelector locations={custOrgLocations} />
          ) : null}
        </div>
        <div className="table-scroll relative">
          {isLoading || (hasDevices && !filteredDevicesRef?.current?.length) ? (
            <div className="loading-container">
              <SiteSpinner height="50px" width="50px" />
              <div className="mt-2">{constants.LOADING}</div>
            </div>
          ) : hasDevices || filteredDevicesRef?.current?.length !== 0 ? (
            <CameraWallTable
              columns={viewId ? columnsData1 : columnsData2}
              data={filteredDevicesRef?.current}
              defaultSortByData={sortingData}
              viewDevices={localSelectedViewDevices.current}
              viewId={viewId}
              newViewDragMode={newViewDragMode}
              viewDevicesDetails={viewDevicesDetails}
              cdnInfo={cdnInfo}
              getDeviceStatus={getDeviceStatus}
              editView={editView}
              isDragging={isDragging}
              isScrolled={isScrolled}
            />
          ) : hasDevices === false ? (
            <div className="no-cameras-container">
              <div className="no-cameras-icon-container">
                <FiCameraOff
                  stroke={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--greyscale_56')}
                  size={32}
                />
              </div>
              <div className="no-cameras-notice">
                {constants.CAMERAS_VIDEO_WALL_NO_CAMERAS_AVAILABLE_LABEL}
              </div>
              {userPolicies.install_device && userPolicies.claim_device && (
                <div className="no-cameras-cta">
                  <Button
                    className="no-cameras-add-device-btn"
                    onClick={(e) => {
                      navigate(
                        `/devices/listing.html?orgId=${orgDetails?.orgId}`
                      );
                    }}
                  >
                    <span>
                      <MdOutlineAddAPhoto
                        stroke={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--brand_white')}
                        size={16}
                      />
                    </span>
                    {constants.CAMERAS_VIDEO_WALL_ADD_CAMERA_LABEL}
                  </Button>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CameraWallDevices;
