import store from '../../store/Store';
import {
  setLoadingStream,
  setRemoteStream,
  setRemoteStreamAudio,
  setStremBitmapData,
  setOfferrecived,
  setStreamLoader,
  setIsActiveStream
} from '../../store/reducers/StreamingReducer';
import * as mqtt from '../connection/mqttConnection';
import Store from '../../store/Store';
import { Utils } from '../../helpers';

let mqttPeerConnection;
let topic;
let server;
let updatedDeviceId;
let updateAccountId;
let updatedUUID;
let updatedStreamId;
let connectionState;
let currentStreamId;

export const getLocalStreamLive = (
  publishTopic,
  serverDetails,
  accountId,
  deviceId,
  hubId,
  uuid,
  streamId
) => {
  setRemoteStream(null);
  setStremBitmapData(null);
  setOfferrecived(false)
  server = serverDetails;
  topic = publishTopic;
  updateAccountId = accountId;
  updatedDeviceId = deviceId;
  updatedUUID = uuid;
  updatedStreamId = streamId;
  createMQTTPeerConnection(
    publishTopic,
    serverDetails,
    accountId,
    deviceId,
    hubId,
    uuid,
    updatedStreamId
  );
};

const createMQTTPeerConnection = (
  publishTopic,
  { stun, turn },
  accountId,
  deviceId,
  hubId,
  uuid,
  updatedStreamId
) => {
  const iceConfiguration = {
    iceServers: [
      {
        urls: `${stun.protocol}:${stun.host}:${stun.port}`,
      },
      {
        urls: `${turn.protocol}:${turn.host}:${turn.port}`,
        username: `${turn.userName}`,
        credential: `${turn.password}`,
      },
    ],
    // Todo: Delete later - keep it for now as connection is not finalyzed yet
    iceTransportPolicy: 'all',
    rtcpMuxPolicy: 'require',
    bundlePolicy: 'balanced',
  };
  Utils.vmsLogger().log("camera detail iceConfiguration:", iceConfiguration);
  mqttPeerConnection = new RTCPeerConnection(iceConfiguration);

  mqttPeerConnection.addTransceiver('video', {
    direction: 'recvonly',
  });

  // Will test once update on live streaming from firmware
  mqttPeerConnection.addTransceiver('audio', {
    direction: 'recvonly',
  });

  sendOffer(publishTopic, accountId, deviceId, hubId, uuid, updatedStreamId);

  mqttPeerConnection.ontrack = ({ streams: [stream] }) => {
    console.log("Livestream on track", stream, '-', getTimesinmili());
    if (stream.getAudioTracks().length) {
      store.dispatch(setRemoteStreamAudio(stream));
    }
    if (stream?.getVideoTracks()?.length) {
      const track = stream?.getVideoTracks()?.[0];
      // if (typeof ImageCapture !== 'undefined'){
      //   // setTimeout(() => {
      //   //   new ImageCapture(track)?.grabFrame()?.then((imageBitmap) => {
      //   //     const data = {
      //   //       'StreamHeight': imageBitmap?.height,
      //   //       'StreamWidth': imageBitmap?.width,
      //   //     };
      //   //     //TODO DELETE LATER
      //   //     Utils.vmsLogger().log('imageBitmap',data)
      //   //     store.dispatch(setStremBitmapData(data)) 
      //   //   })
      //   //   .catch((error) => {
      //   //     //TODO DELETE LATER
      //   //     Utils.vmsLogger().error('grabFrame() error: ', error);
      //   //     if(connectionState === 'connected') {
      //   //       store.dispatch(setStreamLoader(false));
      //   //     }
      //   //   });
      //   // }, 2000);
      // } else {
        const video = document.createElement('video');
        video.srcObject = new MediaStream([track]);
        video.addEventListener('loadedmetadata', () => {
          // const aspectRatio = 16 / 9;
          let width = video.videoWidth;
          let height = video.videoHeight;

          //TODO: DELETE later
          // As the parent div is already in 16/9 aspect ratio no need to calculate this.
          
          // Calculate dimensions maintaining aspect ratio
          // if (width / height > aspectRatio) {
          //   width = height * aspectRatio;
          // } else {
          //   height = width / aspectRatio;
          // }

          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(video, 0, 0, width, height);
          // const imageData = ctx.getImageData(0, 0, width, height);
          const data = {
            'StreamHeight': height,
            'StreamWidth': width,
            // You may need to handle imageData to send it or process it further
          };
          store.dispatch(setStremBitmapData(data));
        });
        video.play().catch((error) => {
          Utils.vmsLogger().error('Error playing video: ', error);
        });
      // }
      // store.dispatch(setStreamLoader(false));
      // store.dispatch(setRemoteStream(stream));
      if(stream?.active) {
        const ele = document.getElementById('video');
        Utils.vmsLogger().log("Livestream ele:", ele,'-', getTimesinmili())
        if(ele) {
          ele.srcObject = stream;
          ele.addEventListener('loadeddata', handleFirstFrame)
        }
      };
    }
  };
  const handleFirstFrame = () => {
    Utils.vmsLogger().log('LiveStream: First frame render', deviceId, '-', getTimesinmili());
    store.dispatch(setStreamLoader(false));
    store.dispatch(setIsActiveStream(true));
  }
  mqttPeerConnection.onicecandidate = (event) => {
    console.log("LiveStream: onicecandidate ~ candidate:",  '-', getTimesinmili())
    if (event.candidate) {
      let candidate_data = JSON.parse(JSON.stringify(event.candidate));
      candidate_data.type = 'candidate';
      const time = Math.floor(new Date().getTime() / 1000.0);
      candidate_data.id = `${updatedStreamId}`;
      const payload = {
        tid: `${time}`,
        to: hubId,
        from: accountId,
        msg: {
          resource: `ch/${deviceId}/camera/streaming`,
          properties: { ...candidate_data },
        },
        publish: `d/notify/${accountId}/${uuid}`,
      };
      mqtt.sendWebRTCCandidate(publishTopic, payload);
    }
  };

  mqttPeerConnection.onconnectionstatechange = (event) => {
    // TODO: delete later
    Utils.vmsLogger().log('connection state', mqttPeerConnection.connectionState);
    connectionState = mqttPeerConnection?.connectionState;
    if (mqttPeerConnection.connectionState === 'connected') {
      store.dispatch(setOfferrecived(true));
      Utils.vmsLogger().log('LiveStream: peer connection', deviceId, '-', getTimesinmili());
      // TODO: delete later
      Utils.vmsLogger().log('succesfully connected with other peer');
      Store.dispatch(setLoadingStream(false));
    }
    if(mqttPeerConnection.connectionState === 'disconnected') {
      Utils.vmsLogger().log('LiveStream: peer disconnected', deviceId, '-', getTimesinmili());
    }
    if (mqttPeerConnection.connectionState === 'failed') {
      Utils.vmsLogger().log('LiveStream: peer failed', deviceId, '-', getTimesinmili());
      getLocalStreamLive(
        topic,
        server,
        updateAccountId,
        updatedDeviceId,
        hubId,
        updatedUUID,
        updatedStreamId
      );
    }
  };
};
const getTimesinmili = () => {
  const time = new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() + ':' + new Date().getMilliseconds();
  return time;
}
export const sendOffer = async (
  publishTopic,
  accountId,
  deviceId,
  hubId,
  uuid,
  updatedStreamId
) => {
  const offer = await mqttPeerConnection.createOffer({
    offerToReceiveVideo: true,
    offerToReceiveAudio: true,
  });
  await mqttPeerConnection.setLocalDescription(offer);
  Utils.vmsLogger().log('LiveStream: Single offer send', deviceId, '-', getTimesinmili());
  const time = Math.floor(new Date().getTime() / 1000.0);
  currentStreamId = `${updatedStreamId}`
  const payload = {
    tid: `${time}`,
    to: hubId,
    from: accountId,
    msg: {
      resource: `ch/${deviceId}/camera/streaming`,
      properties: {
        id: currentStreamId,
        type: 'offer',
        sdp: offer.sdp,
      },
    },
    publish: `d/notify/${accountId}/${uuid}`,
  };
  mqtt.sendWebRTCOffer(publishTopic, payload);
};

export const handleOffer = async (data) => {
  await mqttPeerConnection.setRemoteDescription(data.offer);
};

export const handleAnswer = async (data) => {
  // store.dispatch(setOfferrecived(true));
  Utils.vmsLogger().log('LiveStream: answer handleAnswer', '-', getTimesinmili());
  if(currentStreamId !== data.id) {
    return;
  }
  await mqttPeerConnection
    ?.setRemoteDescription(data)
    ?.then(() => Utils.vmsLogger().log('LiveStream: setRemoteDescription', '-', getTimesinmili()))
    .catch(() => null);
};

export const handleCandidate = async (candidate) => {
  console.log("Livestream handleCandidate ~ candidate:", candidate, '-', getTimesinmili())
  try {
    if (!candidate) return;

    if (mqttPeerConnection) {
      if (!candidate.candidate) {
        await mqttPeerConnection.addIceCandidate(null);
      } else {
        await mqttPeerConnection.addIceCandidate(candidate);
      }
    }
  } catch (err) {
    // TODO: Delete later
    Utils.vmsLogger().error(
      'error occurred when trying to add received ice candidate',
      err
    );
  }
};

export const handleLeaveCall = async () => {
  store.dispatch(setRemoteStream(null));
  // store.dispatch(setStremBitmapData(null));
  if (mqttPeerConnection) {
    mqttPeerConnection.close();
    mqttPeerConnection.onicecandidate = null;
    mqttPeerConnection.ontrack = null;
    mqttPeerConnection = null;
  }
};
