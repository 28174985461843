import {
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
  useEffect,
} from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useCustomerOrgDevices } from '../../../store/CustomerOrgDevicesStore';
import { Row } from 'react-bootstrap';
import DatePicker from 'react-multi-date-picker';
import useEventsStore from '../../../store/EventsStore';
import {
  DevicesFilter,
  DropdownList,
  SiteSpinner,
} from '../../../components/common';
import { constants, Utils } from '../../../helpers';
import { Section, SingleColumnLayout } from '../components';

import BaseChartConfig from '../shared/BaseChartConfig';
import '../OccupancyAnalysis.scss';
import moment from 'moment';
import CamAvailableListTable from './camAvailableListTable';
import {
  getSelectedDeviceFromFillter,
  setSelectedDeviceFromFillter,
} from '../../../store/CustomerOrgDevicesStoreIDB';

const OccupacyDetails = ({ custOrgData, devices, columnsData }) => {
  // itemValue is set in number of days, except
  // the one for the Custom Range
  const pastTimeSeries = [
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_24_HOURS,
      itemValue: 1,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_48_HOURS,
      itemValue: 2,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_7_DAYS,
      itemValue: 7,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_14_DAYS,
      itemValue: 14,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_30_DAYS,
      itemValue: 30,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_YEAR,
      itemValue: 365,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_2_YEARS,
      itemValue: 730,
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_RANGE_CUSTOM_RANGE,
      itemValue: 'custom',
    },
  ];

  const frequencies = [
    {
      itemName: constants.ANALYTICS_DASHBOARD_DURATION_ITEM_HOUR,
      itemValue: 'hourly',
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_DURATION_ITEM_DAY,
      itemValue: 'daily',
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_DURATION_ITEM_WEEK,
      itemValue: 'weekly',
    },
    {
      itemName: constants.ANALYTICS_DASHBOARD_DURATION_ITEM_MONTH,
      itemValue: 'monthly',
    },
  ];

  const frequencyGetter = {
    hourly: Utils.getHourFromEpoch,
    daily: Utils.getDateFromEpoch,
    weekly: Utils.getWeekFromEpoch,
    monthly: Utils.getMonthFromEpoch,
  };

  const START_TIME = 1680307200000;
  const END_TIME = 1685343600000;

  const [hasData, setHasData] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [rangeYear, setRangeYear] = useState(false);
  const [rangeDay, setRangeDay] = useState(false);
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const deviceTimezone = useEventsStore((state) => state.deviceTimezone);
  const calendarRef = useRef();
  const [filterDate, setFilterDate] = useState([]);
  const [timezone, setTimezone] = useState(null);
  const [showCustomRange, setShowCustomRange] = useState(false);

  const [options, setOptions] = useState({
    ...BaseChartConfig,
    xaxis: {
      categories: ['12/29', '12/30', '12/31', '1/1', '1/2', '1/3', '1/4'],
    },
    yaxis: {
      opposite: true,
      min: 0,
      tickAmount: 5,
    },
    colors: [
      getComputedStyle(document.documentElement).getPropertyValue(
        '--primary_56'
      ),
    ],
  });
  const [series, setSeries] = useState([
    {
      name: constants.ANALYTICS_DASHBOARD_CAMERA_AVAILABILITY_SERIES_LABEL,
      data: [100, 100, 80, 100, 100, 100],
    },
  ]);
  const [highlight, setHighlight] = useState({
    value: '0%',
    description: constants.ANALYTICS_DASHBOARD_OCCUPANCY_COUNTED,
  });
  const [metricArray, setMetricArray] = useState([
    {
      text: 'Front Door',
      subtext: 'San Diego, Front Door',
      value: '65%',
      color: 'pink',
    },
    {
      text: 'Meeting Room A',
      subtext: 'San Diego, Front Door',
      value: '72%',
      color: 'pink',
    },
    {
      text: 'Meeting Room B',
      subtext: 'San Diego, Front Door',
      value: '75%',
      color: 'pink',
    },
  ]);
  const [pastRange, setPastRange] = useState(-7);
  const [pastRangeLabel, setPassRangeLabel] = useState(
    constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_7_DAYS
  );
  const [duration, setDuration] = useState('weekly');

  // const {
  //   getCustomerOrgDevices,
  //   setSelectedDeviceFromFillter,
  //   getSelectedDeviceFromFillter,
  // } = useCustomerOrgDevices();
  // const cameraDevices = getCustomerOrgDevices();

  const defaultValue = (
    <div className="d-flex justify-content-around align-content-center no-content">
      {constants.ANALYTICS_DASHBOARD_NO_DATA_AVAILABLE_TEXT}
    </div>
  );

  useEffect(() => {
    if (showCustomRange) {
      calendarRef?.current?.openCalendar();
      // setPastRange(0);
    }
  }, [showCustomRange]);

  const CustomRangePicker = ({ openCalendar, value }) => {
    if (value === new Date()) {
      return <div onClick={openCalendar}>Custom Range Picker</div>;
    } else {
      return <div onClick={openCalendar}></div>;
    }
  };

  useEffect(() => {
    let startTime = 0;
    let endTime = 0;

    if (filterDate && filterDate[1] && filterDate[0]) {
      startTime = new Date(
        moment
          .tz(new Date(filterDate[0]), timezone)
          .format('MMM DD, YYYY 00:00:00')
      ).getTime();
      endTime = new Date(
        moment
          .tz(new Date(filterDate[1]), timezone)
          .format('MMM DD, YYYY 23:59:59')
      ).getTime();
    } else if (filterDate && filterDate[0]) {
      startTime = new Date(
        moment
          .tz(new Date(filterDate[0]), timezone)
          .format('MMM DD, YYYY 00:00:00')
      ).getTime();
      let eTime = new Date(
        moment
          .tz(new Date(filterDate[0]), timezone)
          .format('MMM DD, YYYY 23:59:59')
      ).getTime();
      if (eTime < new Date().getTime()) {
        endTime = eTime;
      } else {
        endTime = new Date().getTime();
      }
    }
    if (startTime && endTime && startTime !== 0 && endTime !== 0) {
      setShowCustomRange(false);
      fetchData(0, duration, devices, startTime, endTime);
    }
  }, [filterDate]);

  useEffect(() => {
    setTimezone(deviceTimezone);
  }, [deviceTimezone]);

  const updateList = async (deviceDataList, status) => {
    if (deviceDataList?.length > 0) {
      if (status) {
        await setSelectedDeviceFromFillter([]);
        fetchData(pastRange, duration, devices);
      } else {
        if (deviceDataList?.length !== 0) {
          let deviceData = [];
          devices?.map((deviceItem, index) => {
            const deviIdExist = deviceDataList?.find(
              (item) => item === deviceItem?.deviceId
            );
            if (deviIdExist) {
              deviceData.push(deviceItem);
            }
          });
          await setSelectedDeviceFromFillter(deviceDataList);
          fetchData(pastRange, duration, deviceData);
        }
      }
    } else {
      let deviceData = [];
      devices?.map((deviceItem, index) => {
        deviceData.push(deviceItem?.deviceId);
      });
      await setSelectedDeviceFromFillter(deviceData);
      fetchData(pastRange, duration, devices);
    }
  };

  const getDeviceLocationName = (device, isForDevice) => {
    let deviceLocation = '--';
    const deviceData = custOrgData?.devices.find(
      (item) => item.deviceId === device?.deviceId
    );
    if (deviceData) {
      if (isForDevice) {
        deviceLocation = deviceData?.deviceName;
      } else {
        const location = custOrgData?.locations.find(
          (item) => item.locationId === deviceData?.locationId
        );
        if (location) {
          const areas = location?.areas.find(
            (item) => item.locationId === location?.locationId
          );
          if (areas) {
            deviceLocation = `${location?.locationName} - ${areas?.areaName}`;
          }
        }
      }
    }
    return deviceLocation;
  };

  const filterContent = (
    <>
      <DevicesFilter
        devices={devices}
        filterDescription={
          constants.CAMERAS_VIDEO_WALL_FILTER_MODAL_DESCRIPTION
        }
        applyButtonLabel={
          constants.CAMERAS_VIDEO_WALL_FILTER_MODAL_APPLY_FILTER_LABEL
        }
        disableBackgroundColor={true}
        clearFilterPlacement="bottom"
        callback={(deviceData, status) => {
          updateList(deviceData, status);
          setShow(false);
        }}
      />
    </>
  );

  const dynamicWidth = series[0]?.data?.length * 45;
  const chartWidth = dynamicWidth < window.innerWidth ? '100%' : dynamicWidth;

  const chartContent = (
    <div className="scrollable-chart-container">
      <Chart
        options={options}
        series={series}
        type="bar"
        height={200}
        width={chartWidth}
      />
    </div>
  );

  const topRightLinks = (
    <div className="filter-group">
      {/* Filter */}
      <DropdownList
        defaultLabel={constants.ANALYTICS_DASHBOARD_ALL_CAMERAS_LINK}
        hasSecondaryDropdown={true}
        items={filterContent}
        show={show}
        setShow={(status) => {
          setShow(status);
        }}
      />
      {/* Past Range */}
      <DropdownList
        defaultLabel={pastRangeLabel}
        items={pastTimeSeries}
        onSelectHandler={(selectedKey) => {
          if (selectedKey === 'custom') {
            setShowCustomRange(true);
          } else {
            setFilterDate(new Date());
            setShowCustomRange(false);
            setPastRange(-Math.abs(selectedKey));
          }
        }}
        show={rangeYear}
        setShow={(status) => {
          setRangeYear(status);
        }}
      />
      {/* Duration */}
      <DropdownList
        defaultLabel={constants.ANALYTICS_DASHBOARD_DURATION_ITEM_WEEK}
        items={frequencies}
        show={rangeDay}
        setShow={(status) => {
          setRangeDay(status);
        }}
        onSelectHandler={(selectedKey) => {
          setDuration(selectedKey);

          switch (selectedKey.toUpperCase()) {
            case 'HOURLY':
              setPassRangeLabel(
                constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_48_HOURS
              );
              break;

            case 'DAILY':
              setPassRangeLabel(
                constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_7_DAYS
              );
              break;

            case 'WEEKLY':
              setPassRangeLabel(
                constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_YEAR
              );
              break;

            default:
            // Do not do anything
          }
        }}
      />
    </div>
  );

  const getDeviceId = () => {
    let deviceID = [];
    const devicesList = custOrgData?.devices;
    devicesList &&
      devicesList.length > 0 &&
      devicesList?.forEach((device, i) => {
        deviceID.push(device.deviceId);
      });
    return deviceID;
  };

  const getDeviceDetails = (deviceId, isLocation) => {
    let locationAreaName = '--';
    const devicesList = custOrgData?.devices;
    const deviceData = devicesList?.find(
      (device) => device?.deviceId === deviceId
    );
    if (deviceData) {
      if (isLocation) {
        const locationsList = custOrgData?.locations;
        const location = locationsList?.find(
          (location) => location?.locationId === deviceData?.locationId
        );
        if (location) {
          locationAreaName = `${location?.locationName}`;
        }
      } else {
        locationAreaName = deviceData?.deviceName;
      }
    }
    return locationAreaName;
  };

  const getDeviceIdsString = async (devicList) => {
    const deviceSelected = await getSelectedDeviceFromFillter();
    let deviceIdsString = '';

    devicList?.forEach((device, index) => {
      if (deviceSelected?.length !== 0) {
        const deviIdExist = deviceSelected?.find(
          (item) => item === device?.deviceId
        );
        if (deviIdExist) {
          deviceIdsString += `${device?.deviceId},`;
        }
      } else {
        deviceIdsString += `${device?.deviceId},`;
      }
    });
    return deviceIdsString?.substring(0, deviceIdsString.length - 1);
  };

  const fetchData = useCallback(
    async (newPastRange, newDuration, devicesList, startTime, endTime) => {
      if (devicesList?.length === 0) return;

      //TODO Delete Later
      //deviceIds:getDeviceId().toString()
      try {
        setShowLoader(true);
        const baseUrl = `metrics/orgs/${custOrgData?.orgId}/trend/device-person-count`;
        const requestId = uuidv4();
        let date = new Date();
        date.setDate(date.getDate() + newPastRange);
        const fetchResult = await axios.get(baseUrl, {
          params: {
            orgId: custOrgData?.orgId,
            startTime: newPastRange === 0 ? startTime : date.getTime(),
            endTime: newPastRange === 0 ? endTime : new Date().getTime(),
            rangeBy: (newPastRange == -365 && newDuration == "hourly") ? "weekly" : (newPastRange == -730 && newDuration == "hourly") ? "monthly" :  newDuration,
            deviceIds: getDeviceIdsString(devicesList),
            requestTime: Date.now(),
            requestId: requestId,
          },
          ...Utils.requestHeader(requestId),
          credentials: 'include',
          withCredentials: true,
        });

        const xAxis = [];
        const yValues = [];
        let respData, timestampGetter, timeSeriesData, timestamp;

        if (fetchResult?.data?.meta?.code === 200) {
          setShowLoader(false);
          respData = fetchResult.data.data?.timeSeries;

          if (Array.isArray(respData) && respData?.length > 0) {
            // Get x axis values for past 7 days
            switch (newPastRange) {
              case -1:
                timeSeriesData = respData
                timestampGetter = frequencyGetter['hourly'];
                break;

              case -2:
                timeSeriesData = respData
                timestampGetter = frequencyGetter['hourly'];
                break;

              case -365:
                timeSeriesData = respData;
                timestampGetter = frequencyGetter['weekly'];
                break;

              case -730:
                timeSeriesData = respData;
                timestampGetter = frequencyGetter['monthly'];
                break;

              default:
                timeSeriesData = respData
                timestampGetter = frequencyGetter[newDuration];
            }
            let totalPeopleCount = 0;
            const metricGraphData = [];
            const tableList = [];
            timeSeriesData?.sort((a, b) => a.timestamp - b.timestamp);
            timeSeriesData.forEach((time) => {
              timestamp = timestampGetter(time.timestamp);
              xAxis.push(timestamp);
              if (Array.isArray(time?.devices) && time.devices.length > 1) {
                time.devices.forEach((device) => {
                  totalPeopleCount = totalPeopleCount + device?.peopleCount;
                  metricGraphData.push({
                    timestamp: time.timestamp,
                    deviceID: device.deviceId,
                    value: device.peopleCount,
                    color: 'light-blue',
                    subtext: getDeviceDetails(device?.deviceId, true),
                    text: getDeviceDetails(device?.deviceId, false),
                  });
                  tableList.push({
                    monthYear: moment(new Date(time?.timestamp)).format(
                      'DD MMM yyyy hh:mm '
                    ),
                    deviceName: getDeviceLocationName(device, true),
                    locationArea: getDeviceLocationName(device, false),
                    percentageOnline: device?.peopleCount,
                  });
                });
              } else {
                totalPeopleCount =
                  totalPeopleCount + time?.devices[0]?.peopleCount;
                metricGraphData.push({
                  timestamp: time?.devices[0].timestamp,
                  deviceID: time?.devices[0]?.deviceId,
                  value: time?.devices[0]?.peopleCount,
                  color: 'light-blue',
                  subtext: getDeviceDetails(time?.devices[0]?.deviceId, true),
                  text: getDeviceDetails(time?.devices[0]?.deviceId, false),
                });
                tableList.push({
                  monthYear: moment(new Date(time?.timestamp)).format(
                    'DD MMM yyyy hh:mm '
                  ),
                  deviceName: getDeviceLocationName(time.devices[0], true),
                  locationArea: getDeviceLocationName(time.devices[0], false),
                  percentageOnline: time?.devices[0]?.peopleCount,
                });
              }
            });
            let yaxisData = Object.values(
              metricGraphData?.reduce((c, { timestamp, value }, i) => {
                c[timestamp] = c[timestamp] || { value: 0 };
                c[timestamp].value += value;
                return c;
              }, {})
            );
            yaxisData?.forEach((yaxis) => {
              yValues.push(yaxis.value);
            });

            setTimeSeriesData(tableList);
            setSeries([
              {
                name: '',
                data: yValues,
              },
            ]);
            // Update the chart options with new data
            setOptions({
              ...options,
              xaxis: {
                categories: [...xAxis],
              },
            });

            setHasData(true);
          }
          else{
            setSeries([]);
            setHasData(false);
            setTimeSeriesData([])
            return;
          }
        } else {
          setHasData(false);
          setShowLoader(false);
        }
      } catch (err) {
        setHasData(false);
        setShowLoader(false);
      }
    },
    []
  );

  useLayoutEffect(() => {
    fetchData(pastRange, duration, devices);

    new Promise((resolve) => {
      setHighlight((highlight) => ({
        value: '0%',
        description: highlight?.description,
      }));
      resolve();
    });
  }, [pastRange, duration, devices]);

  return (
    <div>
      <Row className="analytics-row">
        <Section
          analyticsTitle={
            constants.ANALYTICS_DASHBOARD__SECTION_TITLE_OCCUPANCY_SECTION
          }
          analyticsDescription={
            constants.ANALYTICS_DASHBOARD_OCCUPANCY_SECTION_DESCRIPTION
          }
          topRightLinks={topRightLinks}
        >
          {showLoader ? (
            <div className="no-data-available">
              <SiteSpinner height="50px" width="50px"></SiteSpinner>
            </div>
          ) : (
            <div>
              {hasData ? (
                <SingleColumnLayout chart={chartContent} />
              ) : (
                defaultValue
              )}
            </div>
          )}
          <div className="filterDate-image">
            <DatePicker
              ref={calendarRef}
              range
              minDate={new Date(moment(new Date()).subtract({ days: 30 }))}
              maxDate={new Date()}
              className="rmdp-mobile customized-datepicker"
              value={filterDate}
              onChange={setFilterDate}
              //onClose={calendarRef?.current?.closeCalendar()}
              mobileButtons={[
                {
                  type: 'button',
                  className: 'rmdp-button rmdp-action-button',
                  onClick: () => setFilterDate({}),
                },
              ]}
              render={<CustomRangePicker openCalendar value />}
            />
          </div>
        </Section>
      </Row>
      <Row className="analytics-row">
        <div className={`section-wrapper default-bg`}>
          <CamAvailableListTable columns={columnsData} data={timeSeriesData} />
        </div>
      </Row>
    </div>
  );
};

export default OccupacyDetails;
