import { useCallback, useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import {
  ActiveLicenses,
  AvatarList,
  checkAvailableLicenses,
  BreadcrumbList,
  Header,
  PrimaryButton,
  ScrollbarWrapper,
  SiteModal,
  SiteSpinner,
} from '../../components/common/';
import {
  AppDefaults,
  constants,
  LicenseStatuses,
  OfferingTypeEnum,
  roles,
  ServiceTypeEnum,
  Utils,
} from '../../helpers/';
import { useSelector, useDispatch } from 'react-redux';
import { icontick, noTechAssign } from '../../assets/images';
import {
  orgUserData,
  setLocationsData,
  getLocationsData,
} from '../../store/reducers/AccountReducer';
import { updatePolicies } from '../../store/reducers/PoliciesReducer';
import { HiOutlinePencil } from 'react-icons/hi2';
import { VscCopy } from 'react-icons/vsc';
import useLicensesStore from '../../store/LicensesStore';
import { useCustomerOrgUsers } from '../../store/CustomerOrgUsersStore';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import { usePoliciesStore } from '../../store/policiesStore';

import { RxCheckCircled } from 'react-icons/rx';
import { FiVideo } from 'react-icons/fi';
import LocationsMap from './LocationsMap';
import { HiUserCircle } from 'react-icons/hi2';
import {
  checkMQTTConnection,
  connectWithMQTT,
  mqttUnsubscribe,
} from '../../utils/connection/mqttConnection';
import {
  getPlatformInfo,
  setPlatformInfo,
} from '../../store/reducers/StreamingReducer';
import { getAccountId } from '../../store/reducers/AccountReducer';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import ReactSlider from 'react-slider';

import './OrganizationDetails.scss';
import DeviceUptime from './DeviceUptime';

import { TbDeviceComputerCameraOff } from 'react-icons/tb';
import { PiWarningCircleLight, PiInfo } from 'react-icons/pi';
import { useLayoutEffect } from 'react';
import { isObject } from 'lodash';
import { _ } from 'lodash';
import { StorageDashboard } from '../../components/common/StorageCommon';
import { BsInfoCircle } from 'react-icons/bs';
import useCustomRoles from '../../store/CustomRolesStore';
import { setManufacturerSKUList } from '../../store/reducers/NVRDeviceReducer';
import DefaultPolicies from '../../store/reducers/DefaultPolicies';
import {
  fetchCustomerOrgDevices,
  getCustomerOrgDevices,
} from '../../store/CustomerOrgDevicesStoreIDB';
import {
  setAllDevicesData,
  setSelectedOrganization,
} from '../../store/AccountStoreIDB';
import {
  getLoggedInUserRole,
  setOrgInfo,
  getPartnerOrgData,
  getSelfAccountData,
  setLoggedInUserRole,
} from '../../store/reducers/OrganizationsReducer';
import {
  getCustomerOrgData,
  getOrganizations,
} from '../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import { devicesMQTTStore } from '../../store/DevicesMQTTStore';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';

function OrganizationDetails(props) {
  const [orgStatus, setOrgStatus] = useState(false);
  const [emailInput, setEmailInput] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState(
    constants.ORG_EMAIL_ERROR
  );
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //153894 get user data from reducer
  const { allDevices, userdata, orgUsers } = useSelector(
    (state) => state.accounts
  );
  const locationsData = useSelector(getLocationsData);
  const [onlinestatus, setOnlinestatus] = useState(0);
  const [oflinestatus, setOflinestatus] = useState(0);
  const [userId, setUserid] = useState();
  const [nonInstallerUsers, setNonInstallerUsers] = useState([]);
  const [installerUsers, setInstallerUsers] = useState([]);
  const [unclaimed, setUnclaimed] = useState(0);
  const [expiredLicenses, setExpiredLicenses] = useState(0);
  const [expiringLicenses, setExpiringLicenses] = useState(0);
  const [availableLicenses, setAvailableLicenses] = useState(0);
  const [activeLicenses, setActiveLicenses] = useState(0);
  const [offlinenotPendingclaim, setOfflinenotPendingclaim] = useState(0);
  const [editUser, setEditUser] = useState({});
  const [custPartnerOrgData, setCustPartnerOrgData] = useState({});
  const [locationCoordinates, setlocationCoordinates] = useState({
    lat: 0,
    lng: 0,
  });
  const [
    deviceWithUpdatedFirmwareVersion,
    setDeviceWithUpdatedFirmwareVersion,
  ] = useState(-1);
  // TODO : use later after demo2
  const [showRemovePartnerModal, setShowRemovePartnerModal] = useState(false);
  const [invitePartnerModal, setInvitePartnerModal] = useState(false);
  const [invitationSend, setInvitationSend] = useState(false);
  const [customRole, setCustomRole] = useState([]);
  const [orgAdmins, setOrgAdmins] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [totalDevicesCount, setTotalDevicesCount] = useState(0);
  const [totalOnlineDevicesCount, setTotalOnlineDevicesCount] = useState(0);
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData
  );
  const loggedInUser = getLoggedInUserData();
  const [storageData, setStorageData] = useState(null);
  const [licenseList, setLicenseList] = useState([]);
  const [activeLicense, setActiveLicense] = useState(false);
  const breadList = [
    {
      url:
        loggedInUser?.orgType === AppDefaults.ORG_TYPE_INSTALLER
          ? '/customers/manage.html'
          : `/customers/dashboard.html?orgId=${orgId}`,
      title:
        loggedInUser.role !== roles.ROLE2VMS
          ? constants.MANAGE_ORG_PAGE_TITLE
          : constants.MANAGE_ORG_PAGE_ORG_TITLE,
    },
    {
      url: `/customers/dashboard.html?orgId=${orgId}`,
      title: editUser?.orgName,
    },
  ];

  //=== Store get/set actions
  const setCustomerOrgPolicies = usePoliciesStore(
    (state) => state.setCustomerOrgPolicies
  );
  const { setLicenses, getLicenses } = useLicensesStore();
  const setCustomerOrgUsers = useCustomerOrgUsers(
    (state) => state.setCustomerOrgUsers
  );
  const getCustomerOrgUsersData = useCustomerOrgUsers(
    (state) => state.getCustomerOrgUsersData
  );
  // const customerOrgData = useOrganizations((state) => state.customerOrgData);
  // const getCustomerOrgData = useOrganizations(
  //   (state) => state.getCustomerOrgData
  // );
  const [custOrgData, setCustOrgData] = useState([]);
  // const getSelfAccountData = useOrganizations(
  //   (state) => state.getSelfAccountData
  // );
  const selfAccountData = useSelector(getSelfAccountData);
  // const getOrganizations = useOrganizations((state) => state.getOrganizations);
  // const loggedInUserRole = useOrganizations((state) => state.loggedInUserRole);
  const loggedInUserRole = useSelector(getLoggedInUserRole);
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies
  );
  const getLoggedInUserPolicies = usePoliciesStore(
    (state) => state.getLoggedInUserPolicies
  );
  // const setLoggedInUserRole = useOrganizations(
  //   (state) => state.setLoggedInUserRole
  // );
  // const deviceListData = useSelector(getAllDevicesData);
  const [deviceListData, setDeviceListData] = useState([]);
  const platformDetails = useSelector(getPlatformInfo);
  const accountId = useSelector(getAccountId);
  const subscribeTopic =
    platformDetails?.mqtt?.topic_details?.subscribe?.settings;
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const getLoggedInUserUUID = useLoggedInUserData(
    (state) => state.getLoggedInUserUUID
  );
  const { getState } = devicesMQTTStore;
  const state = getState();
  const [devices, setDevices] = useState([]);
  const [updatedDevice, setUpdatedDevice] = useState([]);
  const [parentDevices, setParentDevices] = useState([]);
  // const {
  //   fetchCustomerOrgDevices,
  //   getCustomerOrgDevices
  // } = useCustomerOrgDevices();
  const {
    getSelectedLocation,
    getCustomerOrgLocationsData,
    getCustomerOrgLocations,
  } = useCustomerOrgLocations();
  const selectedLocation = getSelectedLocation(accountId);

  // const partnerOrgData = useOrganizations((state) => state.partnerOrgData);
  const partnerOrgData = useSelector(getPartnerOrgData);
  const partnerOrgId =
    loggedInUserRole === roles.ROLE4VMS || loggedInUserRole === roles.ROLE5VMS
      ? partnerOrgData?.orgId
      : custOrgData[0]?.orgId;

  const setLoggedInUserData = useLoggedInUserData(
    (state) => state.setLoggedInUserData
  );
  const setLoggedInUserPolicies = usePoliciesStore(
    (state) => state.setLoggedInUserPolicies
  );
  const { getCustomRoleList, roleList } = useCustomRoles();
  const setPatnerOrgUser = useCustomerOrgUsers(
    (state) => state.setPatnerOrgUser
  );
  const getPartnerOrgUsersData = useCustomerOrgUsers(
    (state) => state.getPartnerOrgUsersData
  );
  const [customerTechAccounts, setCustomerTechAccounts] = useState([]);

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setCustOrgData(orgs || []);
  }, []);

  const loadCustomerOrgDevices = useCallback(async () => {
    const devices = await getCustomerOrgDevices();
    const updatedDevices = devices.map((device) => ({
      ...device,
      displayDeviceStatus: Utils.getDeviceStatus(
        device.deviceStatus,
        device.connectionStatus
      ),
    }));
    setDeviceListData(updatedDevices || []);
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );

  const debouncedLoadCustomerOrgDevices = useDebouncedCallback(
    loadCustomerOrgDevices,
    1000
  );

  useEffect(() => {
    setShowLoader(true);
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
      if (data.key === 'customerOrgDevices') {
        await debouncedLoadCustomerOrgDevices();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();
    debouncedLoadCustomerOrgDevices();
    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData, debouncedLoadCustomerOrgDevices]);

  useLayoutEffect(() => {
    fetchOrganizations();
  }, []);

  useEffect(() => {
    if (editUser.role) {
      fetchStorageDetail();
      fetchAvailableLicense();
    }
  }, []);

  const fetchStorageDetail = async () => {
    try {
      const res = await axios.get(
        `partner/orgs/${orgId}/storageData`,
        Utils.requestHeader()
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        setStorageData(responseData?.data);
      } else {
        if (res?.code) {
          Utils.vmsLogger().error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    }
  };
  /***
   * GET LICENSES LIST
   */

  const fetchAvailableLicense = async () => {
    const res = await axios.get(
      `/user/orgs/${orgId}/licenses`,
      Utils.requestHeader()
    );
    const responseData = res?.data;

    if (responseData?.meta?.code === 200) {
      const licenseData = Utils.filterLicenseData(responseData.data);
      setLicenseList(licenseData);
    } else {
      setLicenseList([]);
    }
  };

  useEffect(() => {
    //Todo Delete Later
    const userIndex = custOrgData.find((user) => user.orgId === orgId);
    let policies = userIndex?.policies;
    if (selfAccountData?.orgType === AppDefaults?.ORG_TYPE_INSTALLER) {
      const selectedRole = selfAccountData?.role;
      if (selectedRole) {
        dispatch(setLoggedInUserRole(selectedRole));
      }

      const loggedInUsersPolicies = partnerOrgData?.policies;
      const loggedInUsersPoliciesData = Utils.mapUserPolicies(
        DefaultPolicies,
        loggedInUsersPolicies
      );

      dispatch(updatePolicies(loggedInUsersPoliciesData));
      setLoggedInUserPolicies(loggedInUsersPoliciesData);

      let updatedPoliciesData = Utils.mapUserPolicies(
        DefaultPolicies,
        policies
      );
      setCustomerOrgPolicies(updatedPoliciesData);
      setLoggedInUserData(selfAccountData);
      getMQTT(selfAccountData?.accountId);
    } else {
      let updatedPoliciesData = Utils.mapUserPolicies(
        DefaultPolicies,
        policies
      );
      setCustomerOrgPolicies(updatedPoliciesData);
      //Todo Delete Later
      setLoggedInUserData(selfAccountData);
    }
  }, [selfAccountData, custOrgData.length]);

  const getMQTT = (accountIdRes) => {
    axios
      .get(`/partner/platform/v2`, {
        withCredentials: true,
        ...Utils.requestHeader(),
      })
      .then((response) => {
        if (response?.data?.data) {
          dispatch(setPlatformInfo(response?.data?.data));
          if (!checkMQTTConnection() && accountIdRes) {
            connectWithMQTT(accountIdRes);
          }
        }
      });
  };

  useEffect(() => {
    fetchManufacturerData();
  }, []);

  const fetchManufacturerData = async () => {
    await axios
      .get('/device/manufacturerSkuBanks', Utils.requestHeader())
      .then((response) => {
        const resultData = response.data;
        if (resultData?.meta?.code === 200) {
          const responseData = resultData.data;
          dispatch(setManufacturerSKUList(responseData));
          setShowLoader(false);
        } else {
          dispatch(setManufacturerSKUList([]));
          setShowLoader(false);
        }
      });
  };

  useEffect(() => {
    if (editUser.role) {
      getCustomRoleList(`partner/orgs/${orgId}/roles`)
        .then((res) => {
          if (res?.length > 0) {
            const defaultRoles = [];
            const customRole = [];
            if (getCustomerOrgUsersData()?.length > 0) {
              const usersData = getCustomerOrgUsersData();
              res.forEach((role) => {
                let obj = {
                  roleName: role.roleName,
                  totalAssignees: 0,
                };
                const assignedRoles = usersData?.filter(
                  (user) =>
                    user.role === role.roleId &&
                    loggedInUser?.accountId !== user?.accountId
                );
                if (assignedRoles?.length > 0) {
                  obj.totalAssignees = assignedRoles.length;
                }
                if (role?.roleId !== roles.ROLE1VMS) {
                  if (!role.customRole) {
                    defaultRoles.push(obj);
                  } else {
                    customRole.push(obj);
                  }
                }
              });
            }
            const mergedArr = defaultRoles.concat(customRole);
            const slicedArray = mergedArr.slice(0, 10);
            setCustomRole(slicedArray);
          }
        })
        .catch((err) => {
          Utils.vmsLogger().log(err);
        });
    }
  }, [JSON.stringify(editUser), getCustomerOrgUsersData()]);

  useEffect(() => {
    let count = 0;
    const deviceListChannel = Utils.getTotalChildDevices(deviceListData);
    const deviceListParent = Utils.getTotalParentDevices(
      deviceListData
    )?.filter(
      (device) =>
        device.deviceStatus === constants.DEVICES_CLAIMED_DEVICE_STATUS
    );
    const deviceList =
      deviceListChannel?.filter(
        (device) =>
          device.deviceStatus === constants.DEVICES_CLAIMED_DEVICE_STATUS ||
          device.deviceStatus === constants.DEVICES_DEACTIVATED_DEVICE_STATUS
      ) || [];
    if (deviceListParent?.length > 0) {
      deviceListParent?.forEach((item) => {
        const deviceProperty = item?.properties;
        const avlbleDeviceVersion = deviceProperty?.['avlbl-device-ver'];
        const firmwareVersion = deviceProperty?.['firmware-version'];
        const avlbleDucloVersion = deviceProperty?.['avlbl-duclo-ver'];
        const edgeVersion = deviceProperty?.['edge-app-version'];
        let checkValue;
        checkValue =
          deviceProperty && avlbleDucloVersion
            ? avlbleDucloVersion !== edgeVersion
            : false;
        const checkStatus =
          avlbleDeviceVersion && firmwareVersion
            ? avlbleDeviceVersion?.toLowerCase() !==
              firmwareVersion?.toLowerCase()
            : false;
        if (!checkValue) {
          checkValue = checkStatus;
        }
        if (checkValue) {
          count = count + 1;
        }
      });
      setDeviceWithUpdatedFirmwareVersion(count);
    } else {
      setDeviceWithUpdatedFirmwareVersion(0);
    }
    const nvrDeviceList =
      Utils.getTotalParentDevices(deviceListData)?.filter(
        (device) =>
          (device.deviceStatus === constants.DEVICES_CLAIMED_DEVICE_STATUS ||
            device.deviceStatus ===
              constants.DEVICES_DEACTIVATED_DEVICE_STATUS) &&
          device.deviceType === 'nvr'
      ) || [];
    if (deviceList?.length > 0 || nvrDeviceList?.length > 0) {
      const deviceCounts = deviceList?.length + nvrDeviceList?.length;
      setTotalDevicesCount(deviceCounts);
      const onlineDevices =
        deviceList?.filter(
          (device) =>
            device.displayDeviceStatus ===
            constants.DEVICES_RETURN_ONLINE_STATUS
        ) || [];
      const onlineNVRDevices =
        nvrDeviceList?.filter(
          (device) =>
            device.displayDeviceStatus ===
            constants.DEVICES_RETURN_ONLINE_STATUS
        ) || [];
      const onlineDevicesCount =
        onlineDevices?.length + onlineNVRDevices?.length;
      setTotalOnlineDevicesCount(onlineDevicesCount);
    } else {
      setTotalDevicesCount(0);
      setTotalOnlineDevicesCount(0);
    }
  }, [deviceListData, selectedLocation]);

  const getCurrentLocationDevices = (deviceList) => {
    if (selectedLocation) {
      const selectedLocationList = deviceList?.filter(
        (item) => item?.locationId === selectedLocation?.locationId
      );
      if (selectedLocation?.locationId === AppDefaults?.ALL_LOCATIONS_ID) {
        if (deviceList?.length > 0) {
          return deviceList;
        }
      } else if (selectedLocationList?.length > 0) {
        return selectedLocationList;
      } else {
        return [];
      }
    } else {
      if (deviceList?.length > 0) {
        return deviceList;
      } else {
        return [];
      }
    }
  };

  useLayoutEffect(() => {
    if (editUser.role) {
      fetchDevices();
    }
  }, [JSON.stringify(editUser)]);

  const deletePartner = () => {
    axios
      .delete(`partner/orgs/${orgId}/partnerOrgMapping`, Utils.requestHeader())
      .then((response) => {
        if (response?.data?.meta?.code === 200) {
          setShowRemovePartnerModal(false);
          getPartnerOrg();
          fetchUser();
        } else {
          setShowRemovePartnerModal(false);
        }
      });
  };

  const fetchDevices = useCallback(async () => {
    try {
      // Get all locations for customer's organization
      const custOrgLocations = getCustomerOrgLocationsData();

      if (custOrgLocations.length < 1) {
        await getCustomerOrgLocations(`partner/orgs/${orgId}/locations`);
      }
      // Fetch the list of devices associated with
      // the user
      let fetchResult = await fetchCustomerOrgDevices(
        `device/orgs/${orgId}/devices`
      );

      const locationIds = new Set();
      let deviceArea, currentArea, cameraDevice;
      if (fetchResult.status === 'success') {
        setShowLoader(false);
        const cameraDevices = await getCustomerOrgDevices();
        setParentDevices(Utils.getTotalParentDevices(cameraDevices));
        getCapabiltiesFromDeviceList(cameraDevices);
        const devicePropertyErrorUnSubscribe = {
          topic: `d/notify/${accountId}/${state?.getSessionId()}`,
        };
        mqttUnsubscribe(devicePropertyErrorUnSubscribe);
        const orgLocations = getCustomerOrgLocationsData();
        const locationAreas = [];

        // Retrieve the location name for each device
        for (let i = 0; i < cameraDevices.length; i++) {
          cameraDevice = cameraDevices[i];
          locationIds.add(cameraDevice?.locationId);

          currentArea = orgLocations.find(
            (orgLocation) => orgLocation.locationId === cameraDevice?.locationId
          );

          if (
            locationAreas.findIndex(
              (locationArea) =>
                locationArea.locationId === cameraDevice?.locationId
            ) === -1
          ) {
            deviceArea = {
              deviceId: cameraDevice?.deviceId,
              areas: [...currentArea?.areas],
            };

            locationAreas.push({
              locationId: cameraDevice?.locationId,
              areas: [deviceArea],
            });
          }

          cameraDevices[i].locationName = currentArea?.locationName;

          cameraDevices[i].areas = [
            ...locationAreas.find(
              (locationArea) =>
                locationArea.locationId === cameraDevices[i].locationId
            ).areas[0].areas,
          ];

          cameraDevices[i].style = null;
        }
        await new Promise((resolve) => {
          setUpdatedDevice(cameraDevices?.length > 0 ? [...cameraDevices] : []);
          setAllDevicesData(
            cameraDevices?.length > 0 ? [...cameraDevices] : []
          );
          resolve();
        });

        let filteredDevices;

        if (selectedLocation && isObject(selectedLocation)) {
          if (selectedLocation?.locationId !== AppDefaults.ALL_LOCATIONS_ID) {
            filteredDevices = cameraDevices.filter(
              (device) =>
                device?.locationId === selectedLocation?.locationId &&
                device?.deviceStatus === 'CLAIMED'
            );
          } else {
            const list = cameraDevices?.filter(
              (device) => device?.deviceStatus === 'CLAIMED'
            );
            filteredDevices = cameraDevices?.length > 0 ? [...list] : [];
          }
        }
        await new Promise((resolve) => {
          const list = cameraDevices?.filter(
            (device) => device?.deviceStatus === 'CLAIMED'
          );
        });
      } else {
        setShowLoader(false);
      }
    } catch (err) {}
  }, []);

  useEffect(() => {
    const init = async () => {
      if (Array.isArray(custOrgData)) {
        const userIndex = custOrgData.find((user) => user.orgId === orgId);
        if (!userIndex) return;
        // await setSelectedOrganization(userIndex || {});
        dispatch(
          setOrgInfo({
            orgId: userIndex?.orgId,
            orgName: userIndex?.orgName,
            shortName: userIndex?.shortName,
          })
        );
        setEditUser({
          ...userIndex,
        });
        if (userIndex?.role) {
          setOrgStatus(false);
        } else if (userIndex) {
          setOrgStatus(true);
          setShowLoader(false);
        }
        setOnlinestatus(
          userIndex?.devices?.filter(
            (devicObj) => devicObj.connectionStatus === 'online'
          )
        );
        setOflinestatus(
          userIndex?.devices?.filter(
            (devicObj) => devicObj.connectionStatus === 'offline'
          )
        );
        setUnclaimed(
          userIndex?.devices?.filter(
            (devicObjj) => devicObjj.deviceStatus === 'PENDING_CLAIM'
          )
        );
        setOfflinenotPendingclaim(
          userIndex?.devices?.filter(
            (devicObj) => devicObj.deviceStatus !== 'PENDING_CLAIM'
          )
        );
        setUserid(userIndex?.orgId === orgId);
        await setSelectedOrganization(userIndex || {});
      }
    };
    init();
  }, [custOrgData.length]);

  useEffect(() => {
    const initialize = async () => {
      if (editUser.role) {
        getLocations();
        getPartnerOrg();
        fetchData();
      }
      // if (Array.isArray(custOrgData)) {
      //   const userIndex = custOrgData.find((user) => user.orgId === orgId);
      //   await setSelectedOrganization(userIndex || {});
      //   setEditUser({
      //     ...userIndex,
      //   });
      //   setOnlinestatus(
      //     userIndex?.devices?.filter(
      //       (devicObj) => devicObj.connectionStatus === 'online'
      //     )
      //   );
      //   setOflinestatus(
      //     userIndex?.devices?.filter(
      //       (devicObj) => devicObj.connectionStatus === 'offline'
      //     )
      //   );
      //   setUnclaimed(
      //     userIndex?.devices?.filter(
      //       (devicObjj) => devicObjj.deviceStatus === 'PENDING_CLAIM'
      //     )
      //   );
      //   setOfflinenotPendingclaim(
      //     userIndex?.devices?.filter(
      //       (devicObj) => devicObj.deviceStatus !== 'PENDING_CLAIM'
      //     )
      //   );
      //   setUserid(userIndex?.orgId === orgId);
      // }

      if (getCustomerOrgUsersData()?.length > 0) {
        const nonInstUsers = getCustomerOrgUsersData()?.filter(
          (user) =>
            user.role !== roles.ROLE1VMS &&
            loggedInUser?.accountId !== user?.accountId
        );

        setNonInstallerUsers(nonInstUsers);
      }
    };
    initialize();
    // }, [JSON.stringify(editUser), custOrgData.length]);
  }, [editUser.role]);

  const isLicenseExpiredOrExpiringSoon = (license) => {
    let countOfExpiringSoonDays = false;
    const currentTimestamp = Date.now();
    const test = license.expiryDate - license.gracePeriod * 24 * 60 * 60 * 1000;

    const differenceInMilliseconds = Math.abs(test - currentTimestamp);
    const differenceInDays = Math.floor(
      differenceInMilliseconds / (1000 * 60 * 60 * 24)
    );
    if (
      differenceInDays <= license.expiringSoonInDays &&
      license.licenseStatus !== 'EXPIRED'
    ) {
      countOfExpiringSoonDays = true;
    }
    return countOfExpiringSoonDays;
  };

  const fetchData = async () => {
    try {
      // Retrieve available licenses
      let licensesLoadSuccess = await setLicenses(
        `/user/orgs/${orgId}/licenses`
      );
      if (licensesLoadSuccess?.status === 'success') {
        // Get all licenses that are available
        let custLicenses = getLicenses(false, true);
        let filteredCustLicenses = custLicenses?.filter(license => 
          license?.offeringType !== OfferingTypeEnum.ONBOARDING &&
          license?.offeringType !== OfferingTypeEnum.EVAL
        );      

        // Get all available licenses
        setAvailableLicenses(
          filteredCustLicenses.filter(
            (license) =>
              license.licenseStatus === LicenseStatuses.UNASSIGNED ||
              license.licenseStatus === LicenseStatuses.ACTIVE_UNASSIGNED
          ).length
        );
        // Get all expired licenses
        setExpiredLicenses(
          filteredCustLicenses.filter(
            (license) =>
              license.licenseStatus === LicenseStatuses.EXPIRED &&
              (license.serviceId.toUpperCase() ===
                ServiceTypeEnum?.VMS_SERVICE?.toUpperCase() ||
                license.serviceId.toUpperCase() ===
                  ServiceTypeEnum?.CLOUD_STORAGE?.toUpperCase())
          ).length
        );
        // Get all licenses that are expiring soon
        setExpiringLicenses(
          filteredCustLicenses.filter(
            (license) =>
              (license.serviceId === 'vms-service' ||
                license.serviceId === 'cloud-storage') &&
              license?.offeringType !== OfferingTypeEnum.ONBOARDING &&
              isLicenseExpiredOrExpiringSoon(license)
          ).length
        );
        // Get all active licenses
        setActiveLicenses(
          filteredCustLicenses.filter(
            (license) =>
              (license.licenseStatus === 'ACTIVE' ||
                license.licenseStatus ===
                  LicenseStatuses.ASSIGNED_ACTIVATION_PENDING) &&
              Utils.getDateDifferenceInDays(
                license.expiryDate,
                moment().valueOf()
              ) >= 0
          ).length
        );
      } else {
        // TODO: implement this later
        // setUserMsg(licensesLoadSuccess.msg);
        // setShowToast(true);
      }
    } catch (error) {
      // TODO: implement this later
      // setUserMsg(error);
      // setShowToast(true);
    } finally {
      // TODO: implement this later
      // setShowLoader(false);
    }
  };

  const onChangeEdit = (values) => {
    setEditUser({ ...editUser, ...values });
  };

  useLayoutEffect(() => {
    if (editUser.orgId && !editUser.role) {
      getOrgAdmins();
    }
  }, [editUser.orgId]);

  const getOrgAdmins = () => {
    setOrgAdmins([]);
    axios
      .get(
        `partner/orgs/${partnerOrgId}/custAccounts?custOrgId=${orgId}`,
        Utils.requestHeader()
      )
      .then((response) => {
        let resultData = response.data;
        if (resultData) {
          let responseMeta = resultData.meta;
          let responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            setOrgAdmins(responseData);
          } else {
            setShowLoader(false);
          }
        } else {
          setShowLoader(false);
        }
      })
      .catch((err) => {
        Utils.vmsLogger().log(err);
        setShowLoader(false);
      });
  };

  const getCapabiltiesFromDeviceList = async (deviceData) => {
    // let deviceList = [...deviceData];
    // let newDeviceList = deviceList?.map(async (device, index) => {
    //   const deviceUnSubscription = {
    //     topic: Utils.replaceStringValues(
    //       subscribeTopic?.[0],
    //       '${deviceId}',
    //       `${device?.deviceId}`
    //     ),
    //   };
    //   mqttUnsubscribe(deviceUnSubscription);
    //   const deviceInfo = deviceListData?.find(
    //     (deviceStore) => deviceStore?.deviceId === device?.deviceId
    //   );
    //   let isCapabilityCall =
    //     deviceListData?.length === 0 ||
    //     deviceInfo?.capDetails === undefined ||
    //     deviceInfo?.capability?.version !== device?.capability?.version;
    //   if (device?.capability && isCapabilityCall) {
    //     let response = await fetch(device.capability.url);
    //     if (response.status === 200) {
    //       let responseJson = await response.json();
    //       return { ...device, capDetails: responseJson };
    //     } else {
    //       return { ...device };
    //     }
    //   } else if (
    //     device?.capability &&
    //     deviceInfo?.capability?.version === device?.capability?.version
    //   ) {
    //     return { ...device, capDetails: deviceInfo?.capDetails };
    //   } else {
    //     return { ...device };
    //   }
    // });
    // let deviceCapability = await Promise.all(newDeviceList);
    // await setAllDevicesData(deviceCapability);
    deviceData?.forEach((device) => {
      const deviceUnSubscription = {
        topic: Utils.replaceStringValues(
          subscribeTopic?.[0],
          '${deviceId}',
          `${device?.deviceId}`
        ),
      };
      mqttUnsubscribe(deviceUnSubscription);
    });
    await setAllDevicesData(deviceData);
  };

  const getLocations = () => {
    axios
      .get(`partner/orgs/${orgId}/locations`, Utils.requestHeader())
      .then((response) => {
        let resultData = response.data;
        if (resultData) {
          let responseMeta = resultData.meta;
          let responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            const data = [...responseData];
            const defaultLocation = data?.filter(
              (location) => location.isDefault === 'true'
            );
            if (defaultLocation) {
              setlocationCoordinates({
                lat: defaultLocation[0]?.lat,
                lng: defaultLocation[0]?.lng,
              });
            } else if (data) {
              setlocationCoordinates({ lat: data[0]?.lat, lng: data[0]?.lng });
            }
            const finalData = data.sort(function (x, y) {
              return x === defaultLocation[0]
                ? -1
                : y === defaultLocation[0]
                ? 1
                : 0;
            });
            dispatch(setLocationsData(finalData));
          }
        }
      });
  };

  const getPartnerOrg = () => {
    setInvitationSend(false);
    setInvitePartnerModal(false);
    setEmailInput('');
    setEmailError(false);
    axios
      .get(`partner/orgs/${orgId}/partnerOrg`, Utils.requestHeader())
      .then((response) => {
        let resultData = response.data;
        if (resultData) {
          let responseMeta = resultData.meta;
          let responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            setCustPartnerOrgData(responseData);
          } else {
            setCustPartnerOrgData({});
          }
        }
      });
  };

  //=== Get organizations data
  const fetchOrganizations = async () => {
    await getOrganizations(`/partner/account/self`);
  };

  const locationAddress = `${editUser?.addressLine1}, ${editUser?.city}, ${editUser?.city}, ${editUser?.country}`;
  const truncate = (string, length, delimiter) => {
    delimiter = delimiter || '...';
    return string.length > length
      ? string.substr(0, length) + delimiter
      : string;
  };
  const fetchUser = async () => {
    try {
      await axios
        .get(`partner/orgs/${orgId}/accounts`, Utils.requestHeader())
        .then((response) => {
          let resultData = response?.data?.data;
          setCustomerOrgUsers(resultData);
          dispatch(orgUserData(resultData));
          setShowLoader(false);
        });
    } catch (error) {
      // TODO : to print the error will update this later
      Utils.vmsLogger().error(error);
    }
  };

  const fetchUserTech = async () => {
    try {
      await axios
        .get(`partner/orgs/${partnerOrgId}/accounts`, {
          ...Utils.requestHeader(),
        })
        .then((response) => {
          if (
            response?.data?.meta?.code === 200 ||
            response?.data?.meta?.code === '200'
          ) {
            setPatnerOrgUser(response?.data?.data);
          }
        });
    } catch (error) {
      // TODO : to print the error will update this later
      Utils.vmsLogger().error(error);
    }
  };

  const fetchCustomerTechAccounts = async () => {
    try {
      await axios
        .get(
          `/partner/orgs/${partnerOrgId}/customerTechAccounts?custOrgId=${orgId}`,
          Utils.requestHeader()
        )
        .then((response) => {
          let resultData = response?.data?.data;
          setCustomerTechAccounts(resultData);
          if (!editUser.role) {
            setCustomerOrgUsers(resultData);
            dispatch(orgUserData(resultData));
          }
        });
    } catch (error) {
      // TODO : to print the error will update this later
      Utils.vmsLogger().error(error);
    }
  };

  useEffect(() => {
    if (orgId && editUser.role) {
      fetchUser();
    }
    if (orgId && partnerOrgId && loggedInUserData?.orgType !== 'CUSTOMER_ORG') {
      fetchCustomerTechAccounts();
    }
    if (partnerOrgId) {
      fetchUserTech();
    }
  }, [orgId, editUser?.role]);
  // }, [orgId, JSON.stringify(editUser)]);

  useEffect(() => {
    if (
      customerTechAccounts?.length &&
      Array.isArray(getPartnerOrgUsersData()) &&
      getPartnerOrgUsersData().length
    ) {
      setInstallerUsers([]);
      const custTechs = [];
      customerTechAccounts?.forEach((custData) => {
        const custDetail = getPartnerOrgUsersData()?.find(
          (detail) => detail.accountId === custData.accountId
        );
        custTechs.push(custDetail);
      });
      setInstallerUsers(custTechs);
    }
  }, [getPartnerOrgUsersData(), customerTechAccounts]);

  useEffect(() => {
    if (Array.isArray(getCustomerOrgUsersData())) {
      const nonInstUsers = getCustomerOrgUsersData()?.filter(
        (user) =>
          user.role !== roles.ROLE1VMS &&
          loggedInUser?.accountId !== user?.accountId
      );
      setNonInstallerUsers(nonInstUsers);
      const instUsers = getCustomerOrgUsersData()?.filter(
        (user) => user.role === roles.ROLE1VMS
      );
      setInstallerUsers(instUsers);
    }
  }, [getCustomerOrgUsersData()]);

  const goToDeviceList = () => {
    navigate(`/devices/listing.html?orgId=${orgId}`);
  };

  const goToLisenses = () => {
    navigate(
      `/licenses/listing.html?orgId=${orgId}&orgName=${editUser?.orgName}`
    );
  };

  const goToLocations = () => {
    if (getCustomerOrgPolicies()?.view_location) {
      navigate(`/locations/manage.html?orgId=${orgId}`);
    }
  };

  const goToUser = () => {
    if (getCustomerOrgPolicies()?.view_account) {
      navigate(`/users/manage.html?orgId=${editUser?.orgId}`);
    }
  };

  const goToRoles = () => {
    if (getCustomerOrgPolicies()?.view_org_roles) {
      navigate(`/roles/manage.html?orgId=${editUser?.orgId}`);
    }
  };

  const partnerFlow = () => {
    if (Object.keys(custPartnerOrgData)?.length > 0) {
      setShowRemovePartnerModal(true);
    } else {
      setInvitePartnerModal(true);
    }
  };

  const onKeyDownChange = (e) => {
    if (e != undefined) {
    }
  };

  const onHandleChange = (e) => {
    setEmailError(false);
    if (e != undefined) {
      setEmailInput(e?.target?.value);
    }
  };

  const validateEmail = (email) => {
    const emailRegex =
      /^[^<>()[\]\\,;:\%#^\s@\"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);
  };

  const sendInvite = async () => {
    const reqBody = {
      email: emailInput,
    };
    const resposne = await axios.post(
      `/partner/orgs/${editUser?.orgId}/invite`,
      reqBody,
      Utils.requestHeader()
    );
    if (resposne?.data?.meta?.code === 200) {
      setInvitationSend(true);
    } else {
      setEmailErrorMessage(resposne?.data?.meta?.userMsg);
      setTimeout(() => {
        setEmailError(true);
      }, 200);
      Utils.vmsLogger().error('ERROR: ', resposne?.data);
    }
  };

  const hideInviteModal = () => {
    setInvitePartnerModal(false);
    setEmailInput('');
    setEmailError(false);
  };

  useEffect(() => {
    if (process.env.REACT_APP_PROJECT == AppDefaults.PROJECT_MEGATRON) {
      document.documentElement.style.setProperty(
        '--backgroundColor',
        getComputedStyle(document.documentElement).getPropertyValue(
          '--hGreyscale_88'
        )
      );
    } else {
      document.documentElement.style.setProperty(
        '--backgroundColor',
        getComputedStyle(document.documentElement).getPropertyValue(
          '--primary_32'
        )
      );
    }
  }, []);

  const showActiveLicenseListModal = () => {
    setActiveLicense(!activeLicense);
  };
  const handleModalCallback = () => {
    setActiveLicense(!activeLicense);
    if (editUser.role) {
      fetchStorageDetail();
      fetchAvailableLicense();
    }
  };

  return (
    <div className="App org-detail-page">
      <Header breadcrumbData={breadList} />
      <div className={`organization-bg ${showLoader ? 'progress' : ''}`}>
        <Container className="mw-100">
          <Row className="justify-content-md-center">
            <Col xs lg="12">
              <div className="page-header">
                <Container className="mw-100">
                  {editUser.role ? (
                    <>
                      <Row>
                        <Col className="text-start">
                          <BreadcrumbList
                            isFromOrgDetails={true}
                            list={breadList}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md={6}
                          lg={6}
                          xl={6}
                          xs={12}
                          className={`page-title text-start d-flex flex-column ${
                            process.env.REACT_APP_PROJECT ===
                            AppDefaults.PROJECT_MEGATRON
                              ? 'org-details-black'
                              : 'org-details'
                          }`}
                        >
                          <div className="mb-1">
                            {editUser?.orgName}
                            {getCustomerOrgPolicies()?.update_cust_org && (
                              <HiOutlinePencil
                                style={{ marginLeft: 15, cursor: 'pointer' }}
                                onClick={() =>
                                  navigate(
                                    `/customers/editOrganization.html?orgId=${orgId}`
                                  )
                                }
                                size={16}
                              />
                            )}
                          </div>
                          <div
                            className={`page-title-note ${
                              process.env.REACT_APP_PROJECT ===
                              AppDefaults.PROJECT_MEGATRON
                                ? 'org-details-black'
                                : 'org-details'
                            }`}
                          >
                            {constants.ORG_DASHBOARD_ID_AND_SHORTNAME}
                          </div>
                          <div className="display-f-shortname">
                            <div
                              className={`page-title-note org-ids gap-1 ${
                                process.env.REACT_APP_PROJECT ===
                                AppDefaults.PROJECT_MEGATRON
                                  ? 'org-details-black'
                                  : 'org-details'
                              }`}
                            >
                              {orgId}
                              <OverlayTrigger
                                key={`triggerHeader${orgId}`}
                                placement="top"
                                overlay={
                                  <Tooltip key={`tooltipHeader${orgId}`}>
                                    {orgId}
                                  </Tooltip>
                                }
                              >
                                <div>
                                  <VscCopy
                                    className="copy-icon-custom"
                                    onClick={() =>
                                      navigator.clipboard.writeText(orgId)
                                    }
                                    size={20}
                                    style={{ cursor: 'pointer' }}
                                  />
                                </div>
                              </OverlayTrigger>
                            </div>

                            {editUser?.shortName && (
                              <div
                                className={`m-l-24 page-title-note org-ids gap-1 ${
                                  process.env.REACT_APP_PROJECT ===
                                  AppDefaults.PROJECT_MEGATRON
                                    ? 'org-details-black'
                                    : 'org-details'
                                }`}
                              >
                                {editUser?.shortName}
                                <OverlayTrigger
                                  key={`triggerHeader${editUser?.shortName}`}
                                  placement="top"
                                  overlay={
                                    <Tooltip
                                      key={`tooltipHeader${editUser?.shortName}`}
                                    >
                                      {editUser?.shortName}
                                    </Tooltip>
                                  }
                                >
                                  <div>
                                    <VscCopy
                                      className="copy-icon-custom"
                                      onClick={() =>
                                        navigator.clipboard.writeText(
                                          editUser?.shortName
                                        )
                                      }
                                      size={20}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </div>
                                </OverlayTrigger>
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col
                          md={6}
                          lg={6}
                          xl={6}
                          xs={12}
                          className="text-end page-header-buttons"
                        >
                          {getLoggedInUserPolicies()
                            ?.enable_installer_account &&
                          selfAccountData?.orgType ===
                            AppDefaults?.ORG_TYPE_INSTALLER ? (
                            <div className="installer-list-container">
                              <div className="installer-list">
                                {getCustomerOrgUsersData()
                                  ?.filter(
                                    (user) => user.role === roles.ROLE1VMS
                                  )
                                  ?.map((user, index) => {
                                    let profileImg = user?.image?.url;
                                    let letter = user?.name
                                      ?.match(/(\b\S)?/g)
                                      ?.join('')
                                      ?.match(/(^\S|\S$)?/g)
                                      ?.join('')
                                      ?.toUpperCase();
                                    return index < 3 ? (
                                      <div
                                        style={{
                                          maxWidth:
                                            getCustomerOrgUsersData()?.filter(
                                              (user) =>
                                                user.role === roles.ROLE1VMS
                                            )?.length > 2
                                              ? `16%`
                                              : '20%',
                                        }}
                                        key={user?.accountId}
                                      >
                                        <AvatarList
                                          avatarValueType={
                                            profileImg ? 'icon' : 'text'
                                          }
                                          avatarValue={
                                            profileImg ? profileImg : letter
                                          }
                                        />
                                      </div>
                                    ) : null;
                                  })}
                              </div>
                              <PrimaryButton
                                className="btn btn-primary-outline"
                                type="button"
                                width="auto"
                                borderWidth="1.5px"
                                hoverBorderWidth="1.5px"
                                color={getComputedStyle(
                                  document.documentElement
                                ).getPropertyValue(
                                  process.env.REACT_APP_PROJECT ===
                                    AppDefaults.PROJECT_MEGATRON
                                    ? '--button-color-1'
                                    : '--brand_white'
                                )}
                                borderColor={getComputedStyle(
                                  document.documentElement
                                ).getPropertyValue(
                                  process.env.REACT_APP_PROJECT ===
                                    AppDefaults.PROJECT_MEGATRON
                                    ? '--button-color-1'
                                    : '--brand_white'
                                )}
                                hoverColor={getComputedStyle(
                                  document.documentElement
                                ).getPropertyValue('--primary_40')}
                                backgroundColor="transparent"
                                hoverBackgroundColor={getComputedStyle(
                                  document.documentElement
                                ).getPropertyValue('--primary_96')}
                                hoverBorderColor={getComputedStyle(
                                  document.documentElement
                                ).getPropertyValue(
                                  process.env.REACT_APP_PROJECT ===
                                    AppDefaults.PROJECT_MEGATRON
                                    ? '--primary_40'
                                    : '--brand_white'
                                )}
                                onClick={() => {
                                  if (
                                    getLoggedInUserPolicies()
                                      ?.enable_installer_account
                                  ) {
                                    navigate(
                                      `/managetechnicians.html?orgId=${editUser?.orgId}`
                                    );
                                  }
                                }}
                              >
                                {
                                  constants.ORG_DASHBOARD_TECHNICIANS_SECTION_TITLE
                                }
                              </PrimaryButton>
                              {/* <div>

                                </div> */}
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                      {storageData !== null &&
                        StorageDashboard(
                          storageData?.usedStorage,
                          storageData?.allocatedStorage
                        )}
                      {licenseList.length > 0 &&
                        storageData !== null &&
                        Utils.checkStorageExceed(
                          storageData?.usedStorage,
                          storageData?.allocatedStorage
                        ) &&
                        checkAvailableLicenses((result) => {
                          showActiveLicenseListModal();
                        })}
                      {activeLicense && (
                        <ActiveLicenses
                          data={licenseList}
                          callBackModal={handleModalCallback}
                          from={constants.FROM_DASHBOARD}
                        />
                      )}
                      <Row>
                        <Col>
                          <div className="device-detail-graph-conainer">
                            <div className="device-details">
                              <div className="header-layout">
                                <div className="devices-label">
                                  {
                                    constants.ORG_DASHBOARD_DEVICES_SECTION_TITLE
                                  }
                                </div>
                                {(getCustomerOrgPolicies()?.install_device ||
                                getCustomerOrgPolicies()?.delete_device ||
                                getCustomerOrgPolicies()?.claim_device ||
                                getCustomerOrgPolicies()?.manage_device_settings||
                                getCustomerOrgPolicies()?.view_device_settings ||
                                getCustomerOrgPolicies()?.view_license_key ||
                                getCustomerOrgPolicies()?.assign_license ||
                                getCustomerOrgPolicies()?.detach_license ||
                                getCustomerOrgPolicies()?.view_device_metadata ||
                                getCustomerOrgPolicies()?.view_manufacturer_sku ||
                                getCustomerOrgPolicies()?.move_device) && (
                                  <div
                                    onClick={() => goToDeviceList()}
                                    className="manage-devices"
                                  >
                                    {constants.ORG_DAHSBOARD_MANAGE_ALL_DEVICES}
                                  </div>
                                )}
                              </div>
                              <div className="camera-layout">
                                <FiVideo className="img-size" />{' '}
                              </div>
                              <div
                                className={`range-field-wrap ${
                                  deviceListData?.filter(
                                    (device) =>
                                      device?.deviceStatus ===
                                      constants.DEVICES_CLAIMED_DEVICE_STATUS
                                  )?.length > 0
                                    ? 'org-details'
                                    : 'no-devices'
                                } `}
                              >
                                <div className="range-field-container">
                                  <ReactSlider
                                    name={'valueFocus'}
                                    value={totalOnlineDevicesCount}
                                    min={0}
                                    max={totalDevicesCount}
                                    className={`range-slider ${
                                      totalOnlineDevicesCount ===
                                        totalDevicesCount && 'custom-radius'
                                    }`}
                                    trackClassName={`org-range-slider-track`}
                                  />
                                </div>
                              </div>{' '}
                              <div className="device-count-layout">
                                <div className="device-count">
                                  {totalOnlineDevicesCount}
                                </div>
                                <div className="total-device-layout">
                                  <div className="total-devices">
                                    / {totalDevicesCount}
                                  </div>
                                  <div className="active-devices">
                                    {constants.ORG_ACTIVE_DEVICES}
                                  </div>
                                </div>
                              </div>
                              {deviceWithUpdatedFirmwareVersion === -1 ||
                              deviceListData?.length === 0 ||
                              (totalDevicesCount !== 0 &&
                                deviceWithUpdatedFirmwareVersion ===
                                  totalDevicesCount) ||
                              deviceWithUpdatedFirmwareVersion <
                                totalDevicesCount ? (
                                <div className="seprator"></div>
                              ) : (
                                ''
                              )}
                              <div className="bottom-container">
                                {deviceWithUpdatedFirmwareVersion === -1 ? (
                                  <div className="device-row-left">
                                    <SiteSpinner height="20px" width="20px" />
                                    <div className="label-name">
                                      {constants.ORG_LOADING_FIRMWARE_STATUS}
                                    </div>
                                  </div>
                                ) : deviceListData?.length === 0 ? (
                                  <div className="device-row-left">
                                    <TbDeviceComputerCameraOff className="status-icon no-devices" />
                                    <div className="label-name">
                                      {constants.ORG_NO_DEVICES_AVAILABLE}
                                    </div>
                                  </div>
                                ) : totalDevicesCount !== 0 &&
                                  deviceWithUpdatedFirmwareVersion === 0 ? (
                                  <div className="device-row-left">
                                    <RxCheckCircled className="status-icon" />
                                    <div className="label-name">
                                      {constants.ORG_DEVICE_FIRMWARE_UPTO_DATE}
                                    </div>
                                  </div>
                                ) : (
                                  deviceWithUpdatedFirmwareVersion > 0 && (
                                    <div className="device-row-left">
                                      <PiWarningCircleLight className="status-icon update-available" />
                                      <div className="label-name">
                                        {constants.ORG_FIRMWARE_UPDATES_AVAILABLE.replace(
                                          '54',
                                          deviceWithUpdatedFirmwareVersion
                                        )}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                            <div className="device-uptime-graph">
                              {
                                <DeviceUptime
                                  custOrgData={editUser}
                                  devices={parentDevices}
                                  // devices={Utils.getTotalParentDevices(
                                  //   getCustomerOrgDevices()
                                  // )}
                                />
                              }
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <div className="licenses-location-account-container">
                          <div className="licenses-details">
                            <div className="header-layout">
                              <div className="header-label">
                                {constants.ORG_DASHBOARD_LICENSES_SECTION_TITLE}
                              </div>
                              {getCustomerOrgPolicies()?.view_license_key && (
                                <div
                                  onClick={() => goToLisenses()}
                                  className="manage-label"
                                >
                                  {constants.SIDEBAR_USERS_SUBMENU_MANAGE_LABEL}
                                </div>
                              )}
                            </div>
                            <div className="section-licenses">
                              <div className="label-name highlight-bg">
                                {expiringLicenses}
                              </div>
                              <div className="label-value">
                                {
                                  constants.ORG_DASHBOARD_LICENSES_EXPIRING_SOON_SHORT_TITLE
                                }
                              </div>
                            </div>
                            <div className="section-licenses">
                              <div className="label-name">{activeLicenses}</div>
                              <div className="label-value">
                                {constants.ORG_DASHBOARD_LICENSES_ACTIVE_TITLE}
                              </div>
                            </div>
                            <div className="section-licenses">
                              <div className="label-name">
                                {availableLicenses}
                              </div>
                              <div className="label-value">
                                {
                                  constants.ORG_DASHBOARD_LICENSES_AVAILABLE_TITLE
                                }
                              </div>
                            </div>

                            <div className="section-licenses">
                              <div className="label-name">
                                {expiredLicenses}
                              </div>
                              <div className="label-value">
                                {constants.ORG_DASHBOARD_LICENSES_EXPIRED_TITLE}
                              </div>
                            </div>
                          </div>
                          <div className="location-details">
                            <div className="header-layout">
                              <div className="header-label">
                                {
                                  constants.ORG_DASHBOARD_LOCATIONS_SECTION_TITLE
                                }
                              </div>
                              {getCustomerOrgPolicies()?.view_location && (
                                <div
                                  onClick={() => goToLocations()}
                                  className="manage-label"
                                >
                                  {constants.SIDEBAR_USERS_SUBMENU_MANAGE_LABEL}
                                </div>
                              )}
                            </div>

                            <div className="location-layout">
                              <LocationsMap
                                locationPositions={{
                                  lat: locationCoordinates?.lat,
                                  lng: locationCoordinates?.lng,
                                }}
                              />
                              <div className="section-location">
                                <div className="label-name">
                                  {locationsData?.length}
                                </div>
                                <div className="label-value">
                                  {
                                    constants.ORG_DASHBOARD_LOCATIONS_SECTION_TITLE
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accounts-details">
                            <div className="header-layout">
                              <div className="header-label">
                                {constants.TOP_HEADER_USERS_NAV_TITLE}
                              </div>
                              {getCustomerOrgPolicies()?.view_all_accounts && (
                                <div
                                  className="manage-label"
                                  onClick={() => goToUser()}
                                >
                                  {constants.SIDEBAR_USERS_SUBMENU_MANAGE_LABEL}
                                </div>
                              )}
                            </div>
                            <div className="account-status-layout">
                              <div className="block-one-status">
                                <div className="label-name">
                                  {
                                    nonInstallerUsers?.filter(
                                      (user) =>
                                        user?.accountStatus === 'ACTIVE' &&
                                        user?.isLocked !== true &&
                                        user?.accountId !== accountId
                                    )?.length
                                  }
                                </div>
                                <div className="label-value">
                                  {constants.ORG_LABELS_ACTIVATED}
                                </div>
                              </div>
                              <div className="block-one-status">
                                <div className="label-name">
                                  {
                                    nonInstallerUsers?.filter(
                                      (user) =>
                                        user?.accountStatus === 'INACTIVE' &&
                                        user?.accountId !== accountId
                                    )?.length
                                  }
                                </div>
                                <div className="label-value">
                                  {constants.ORG_LABELS_INACTIVE}
                                </div>
                              </div>
                            </div>
                            <div className="account-status-layout">
                              <div className="block-one-status">
                                <div className="label-name highlight-bg">
                                  {' '}
                                  {
                                    nonInstallerUsers?.filter(
                                      (user) =>
                                        user?.accountStatus ===
                                          'PENDING_ACTIVATION' &&
                                        user?.accountId !== accountId
                                    )?.length
                                  }
                                </div>
                                <div className="label-value highlight-sec">
                                  {constants.ORG_LABELS_INVITED}
                                </div>
                              </div>
                              <div className="block-one-status">
                                <div className="label-name highlight-bg">
                                  {
                                    nonInstallerUsers?.filter(
                                      (user) =>
                                        user?.accountStatus === 'ACTIVE' &&
                                        user?.isLocked === true &&
                                        user?.accountId !== accountId
                                    )?.length
                                  }
                                </div>
                                <div className="label-value highlight-sec">
                                  {constants.ORG_LABLES_LOCKED}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Row>
                      <div className="role-and-partner-section">
                        <div className="roles-details">
                          <div className="header-layout">
                            <div className="header-label">
                              {constants.ORG_LABELS_ROLES}
                            </div>
                            {getCustomerOrgPolicies()?.view_org_roles ? (
                              <div
                                className="manage-label"
                                onClick={() => goToRoles()}
                              >
                                {constants.SIDEBAR_USERS_SUBMENU_MANAGE_LABEL}
                              </div>
                            ) : null}
                          </div>
                          <div className="section-roles-colums">
                            <div className="section-header-roles">
                              {customRole?.map((role, roleIndex) => {
                                return (
                                  <div
                                    key={`role-${roleIndex}`}
                                    className="section-roles"
                                  >
                                    <div className="label-name">
                                      {' '}
                                      {role.totalAssignees}
                                    </div>
                                    <div className="label-value">
                                      {!role.customRole &&
                                      role.roleName === 'cust_org_admin'
                                        ? constants.ADMIN_USER_CUSTOM_ROLE_LABEL
                                        : !role.customRole &&
                                          role.roleName === 'cust_org_user'
                                        ? constants.NORMAL_USER_ROLE_LABEL
                                        : !role.customRole &&
                                          role.roleName === 'cust_org_installer'
                                        ? constants.INSTALLER_USER_ROLE_LABEL
                                        : role.roleName}
                                    </div>
                                  </div>
                                );
                              })}
                              {/* <div className="section-roles">
                                <div className="label-name">
                                  {' '}
                                  {
                                    getCustomerOrgUsersData()?.filter(
                                      (user) =>
                                        user.role === roles.ROLE2VMS &&
                                        user?.accountId !== accountId
                                    )?.length
                                  }
                                </div>
                                <div className="label-value">
                                  {constants.ORG_ADMINISTRATORS}
                                </div>
                              </div> */}
                            </div>

                            {/* <div className="section-header-roles margin-section">
                              <div className="section-roles">
                                <div className="label-name">
                                  {' '}
                                  {
                                    getCustomerOrgUsersData()?.filter(
                                      (user) => user.role === roles.ROLE3VMS
                                    )?.length
                                  }
                                </div>
                                <div className="label-value">
                                  {constants.TOP_HEADER_USERS_NAV_TITLE}
                                </div>
                              </div>
                              <div className="section-roles">
                                <div className="label-name">{0}</div>
                                <div className="label-value">
                                  {constants.ORG_ROLE_CUSTOM_ONE}
                                </div>
                              </div>
                            </div>

                            <div className="section-header-roles margin-section">
                              <div className="section-roles">
                                <div className="label-name">{0}</div>
                                <div className="label-value">
                                  {constants.ORG_OFFICE_ACCESS}
                                </div>
                              </div>
                              <div className="section-roles">
                                <div className="label-name">{0}</div>
                                <div className="label-value">
                                  {constants.ORG_ROLE_CUSTOM_ONE}
                                </div>
                              </div>
                            </div>

                            <div className="section-header-roles margin-section">
                              <div className="section-roles">
                                <div className="label-name">{0}</div>
                                <div className="label-value">
                                  {constants.ORG_ROLE_CUSTOM_ONE}
                                </div>
                              </div>
                              <div className="section-roles">
                                <div className="label-name">{0}</div>
                                <div className="label-value">
                                  {constants.ORG_ROLE_CUSTOM_ONE}
                                </div>
                              </div>
                            </div>

                            <div className="section-header-roles margin-section">
                              <div className="section-roles">
                                <div className="label-name">{0}</div>
                                <div className="label-value">
                                  {constants.ORG_ROLE_CUSTOM_ONE}
                                </div>
                              </div>
                              <div className="section-roles">
                                <div className="label-name">{0}</div>
                                <div className="label-value">
                                  {constants.ORG_ROLE_CUSTOM_ONE}
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                        {loggedInUser?.orgType === 'CUSTOMER_ORG' &&
                          getCustomerOrgPolicies()?.view_org_partnerorg && (
                            <div className="partner-flow-section">
                              <div className="header-layout">
                                <div className="header-label">
                                  {process.env.REACT_APP_PROJECT ===
                                  AppDefaults.PROJECT_MEGATRON
                                    ? constants.ORG_DASHBOARD_STEP_PARTNER_SECTION_TITLE
                                    : constants.ORG_DASHBOARD_PARTNER_SECTION_TITLE}
                                </div>
                                {getCustomerOrgPolicies()?.send_invitation && (
                                  <div
                                    className="manage-label"
                                    onClick={() => partnerFlow()}
                                  >
                                    {Object.keys(custPartnerOrgData)?.length > 0
                                      ? constants.ORG_REMOVE_PARTNER
                                      : constants.ORG_DASHBOARD_PARTNER_INVITE}
                                  </div>
                                )}
                              </div>
                              <div
                                className={`main-section-partner ${
                                  Object.keys(custPartnerOrgData)?.length ===
                                    0 && 'no-patner'
                                }`}
                              >
                                {custPartnerOrgData?.invitationStatus &&
                                  custPartnerOrgData?.invitationStatus ===
                                    'PENDING_ACTION' && (
                                    <div className="pending-invite">
                                      <BsInfoCircle
                                        size={20}
                                        color={getComputedStyle(
                                          document.documentElement
                                        ).getPropertyValue('--warning_24')}
                                      />
                                      <div className="label-pending">
                                        {
                                          constants.ORG_PENDING_INVITATION_ACCEPTENCE
                                        }
                                      </div>
                                    </div>
                                  )}
                                {Object.keys(custPartnerOrgData)?.length > 0 ? (
                                  <div className="section-partner no-invite-section mb-1">
                                    <div className="section-left-patner-details ">
                                      <div
                                        className={`label-name ${
                                          !custPartnerOrgData?.orgName &&
                                          'd-none'
                                        }`}
                                      >
                                        {
                                          constants.ORG_DASHBOARD_PARTNER_ORG_NAME_LBAEL
                                        }
                                      </div>
                                      <div className="label-value">
                                        {custPartnerOrgData?.orgName}
                                      </div>
                                      <div
                                        className={`label-name other-label ${
                                          !custPartnerOrgData?.addressLine1 &&
                                          'd-none'
                                        }`}
                                      >
                                        {
                                          constants.ORG_DASHBOARD_PARTNER_ADDRESS_LBAEL
                                        }
                                      </div>
                                      <div className="label-value">
                                        {custPartnerOrgData?.addressLine1}
                                      </div>
                                      <div
                                        className={`label-name other-label ${
                                          !custPartnerOrgData?.phoneNumber &&
                                          'd-none'
                                        }`}
                                      >
                                        {
                                          constants.ORG_DASHBOARD_PARTNER_PHONE_NUMBER_LBAEL
                                        }
                                      </div>
                                      <div className="label-value">
                                        {custPartnerOrgData?.phoneNumber}
                                      </div>
                                      <div
                                        className={`label-name other-label ${
                                          !custPartnerOrgData?.contactEmail &&
                                          'd-none'
                                        }`}
                                      >
                                        {
                                          constants.ORG_DASHBOARD_PARTNER_EMAIL_LBAEL
                                        }
                                      </div>
                                      <div className="label-value">
                                        {custPartnerOrgData?.contactEmail}
                                      </div>
                                    </div>
                                    <div className="section-technicians">
                                      <div className="label-name">
                                        {
                                          constants.ORG_DASHBOARD_TECHNICIANS_SECTION_TITLE
                                        }
                                      </div>
                                      {installerUsers?.length > 0 ? (
                                        <ScrollbarWrapper height={200}>
                                          {installerUsers?.map(
                                            (user, index) => {
                                              let profileImg = user?.image?.url;
                                              let letter = user?.name
                                                ?.match(/(\b\S)?/g)
                                                ?.join('')
                                                ?.match(/(^\S|\S$)?/g)
                                                ?.join('')
                                                ?.toUpperCase();
                                              return index < 3 ? (
                                                <div key={user?.accountId}>
                                                  <AvatarList
                                                    avatarValueType={
                                                      profileImg
                                                        ? 'icon'
                                                        : 'text'
                                                    }
                                                    avatarValue={
                                                      profileImg
                                                        ? profileImg
                                                        : letter
                                                    }
                                                    title={`${user?.firstName} ${user?.lastName}`}
                                                  />
                                                </div>
                                              ) : null;
                                            }
                                          )}
                                        </ScrollbarWrapper>
                                      ) : (
                                        <div className="no-technician">
                                          <div className="bg-no-tech">
                                            <img
                                              className="img-no-tech"
                                              src={noTechAssign}
                                            ></img>
                                          </div>
                                          <div className="label-no-tech">
                                            {
                                              constants.NO_ASSIGN_TECHNICIANS_FOUND_TITLE
                                            }
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <Row>
                                    <Col className="userIcon">
                                      <HiUserCircle
                                        size={48}
                                        fill={getComputedStyle(
                                          document.documentElement
                                        ).getPropertyValue('--greyscale_80')}
                                      />
                                      <div className="noUser">
                                        {
                                          constants.ORG_DASHBOARD_PARTNER_NOT_FOUND
                                        }
                                      </div>
                                    </Col>
                                  </Row>
                                )}
                              </div>
                            </div>
                          )}
                      </div>

                      {/* <Row>
                        <div className="feedback-footer-layout">
                          <div
                            className={`feedback-container ${
                              (loggedInUserRole == roles.ROLE4VMS ||
                                loggedInUserRole == roles.ROLE5VMS) &&
                              'margin-patner'
                            }`}
                          >
                            <div className="left-container">
                              <BiHappy size={20} className="smiley-icon" />
                              <div className="label-text">
                                {constants.ORG_FEEDBACK_MESSAGE}
                              </div>
                            </div>
                            <div className="send-feedback">
                              {constants.ORG_SEND_FEEDBACK}
                            </div>
                          </div>
                        </div>
                      </Row> */}
                    </>
                  ) : (
                    <>
                      {orgStatus && (
                        <div>
                          <Row>
                            <Col className="text-start">
                              <BreadcrumbList
                                isFromOrgDetails={true}
                                list={breadList}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              md={6}
                              lg={6}
                              xl={6}
                              xs={12}
                              className={`page-title text-start ${
                                process.env.REACT_APP_PROJECT ===
                                AppDefaults.PROJECT_MEGATRON
                                  ? 'org-details-black'
                                  : 'org-details'
                              }`}
                            >
                              {editUser?.orgName}
                              <p
                                className={`page-title-note org-ids mt-2 ${
                                  process.env.REACT_APP_PROJECT ===
                                  AppDefaults.PROJECT_MEGATRON
                                    ? 'org-details-black'
                                    : 'org-details'
                                }`}
                              >
                                {editUser.addressLine1}
                              </p>
                              <p
                                className={`page-title-note org-ids mt-1 ${
                                  process.env.REACT_APP_PROJECT ===
                                  AppDefaults.PROJECT_MEGATRON
                                    ? 'org-details-black'
                                    : 'org-details'
                                }`}
                              >
                                {editUser.city ? editUser.city + ',' : ''}{' '}
                                {editUser.stateName} {editUser.zipcode}
                              </p>
                            </Col>
                            <Col
                              md={6}
                              lg={6}
                              xl={6}
                              xs={12}
                              className="text-end page-header-buttons"
                            >
                              {getLoggedInUserPolicies()
                                ?.enable_installer_account &&
                              selfAccountData?.orgType ===
                                AppDefaults?.ORG_TYPE_INSTALLER ? (
                                <div className="installer-list-container">
                                  <div className="installer-list">
                                    {installerUsers?.map((user, index) => {
                                      let profileImg = user?.image?.url;
                                      let letter = user?.name
                                        ?.match(/(\b\S)?/g)
                                        ?.join('')
                                        ?.match(/(^\S|\S$)?/g)
                                        ?.join('')
                                        ?.toUpperCase();
                                      return index < 3 ? (
                                        <div
                                          style={{
                                            maxWidth:
                                              installerUsers?.length > 2
                                                ? `16%`
                                                : '20%',
                                          }}
                                          key={user?.accountId}
                                        >
                                          <AvatarList
                                            avatarValueType={
                                              profileImg ? 'icon' : 'text'
                                            }
                                            avatarValue={
                                              profileImg ? profileImg : letter
                                            }
                                          />
                                        </div>
                                      ) : null;
                                    })}
                                  </div>
                                  <PrimaryButton
                                    className="btn btn-primary-outline"
                                    type="button"
                                    width="auto"
                                    borderWidth="1.5px"
                                    hoverBorderWidth="1.5px"
                                    color={getComputedStyle(
                                      document.documentElement
                                    ).getPropertyValue(
                                      process.env.REACT_APP_PROJECT ===
                                        AppDefaults.PROJECT_MEGATRON
                                        ? '--button-color-1'
                                        : '--brand_white'
                                    )}
                                    borderColor={getComputedStyle(
                                      document.documentElement
                                    ).getPropertyValue(
                                      process.env.REACT_APP_PROJECT ===
                                        AppDefaults.PROJECT_MEGATRON
                                        ? '--button-color-1'
                                        : '--brand_white'
                                    )}
                                    hoverColor={getComputedStyle(
                                      document.documentElement
                                    ).getPropertyValue('--primary_40')}
                                    backgroundColor="transparent"
                                    hoverBackgroundColor={getComputedStyle(
                                      document.documentElement
                                    ).getPropertyValue('--primary_96')}
                                    hoverBorderColor={getComputedStyle(
                                      document.documentElement
                                    ).getPropertyValue(
                                      process.env.REACT_APP_PROJECT ===
                                        AppDefaults.PROJECT_MEGATRON
                                        ? '--primary_40'
                                        : '--brand_white'
                                    )}
                                    onClick={() => {
                                      if (
                                        getLoggedInUserPolicies()
                                          ?.enable_installer_account
                                      ) {
                                        navigate(
                                          `/managetechnicians.html?orgId=${editUser?.orgId}`
                                        );
                                      }
                                    }}
                                  >
                                    {
                                      constants.ORG_DASHBOARD_TECHNICIANS_SECTION_TITLE
                                    }
                                  </PrimaryButton>
                                </div>
                              ) : null}
                            </Col>
                          </Row>
                          <div className="info-notification">
                            <PiInfo size={20} className="smiley-icon" />
                            {constants.ORD_DASHBOARD_MESSAGE}
                          </div>

                          <div className="admin-container">
                            <div className="admin-title">
                              {constants.ORG_DASHBOARD_ADMIN}
                            </div>
                            <>
                              {orgAdmins.length &&
                                !showLoader &&
                                orgAdmins?.map((admin, index) => {
                                  return (
                                    <>
                                      <div className="admin-details">
                                        <div className="initials">
                                          {admin.firstName.charAt(0)}
                                          {admin.lastName.charAt(0)}
                                        </div>
                                        <div className="info-cintainer">
                                          <div className="name">
                                            {admin.firstName} {admin.lastName}
                                          </div>
                                          <div className="info">
                                            {admin.email}
                                          </div>
                                          <div className="info">
                                            {admin.phoneNumber}
                                          </div>
                                        </div>
                                      </div>
                                      {index !== orgAdmins.length - 1 ? (
                                        <div className="seperator"></div>
                                      ) : null}
                                    </>
                                  );
                                })}
                            </>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {showLoader && (
                    <div
                      className="position-absolute"
                      style={{ left: '47%', top: '47%' }}
                    >
                      <SiteSpinner height="50px" width="50px"></SiteSpinner>
                      <div className="mt-2 text-dark">{constants.LOADING}</div>
                    </div>
                  )}
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* TODO : use later */}
      <SiteModal
        classes="remove-partner-modal"
        modalTitle={`${constants.ORG_REMOVE_PARTNER_MODAL_TITLE} ${custPartnerOrgData?.orgName} ?`}
        showModal={showRemovePartnerModal}
        hideModal={() => setShowRemovePartnerModal(false)}
      >
        {constants.ORG_REMOVE_PARTNER_DESCRIPTION}
        <div className="remove-btn-conatiner">
          <PrimaryButton
            className="btn btn-remove"
            type="button"
            width="auto"
            borderWidth="1.5px"
            hoverBorderWidth="1.5px"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--error_64'
            )}
            borderColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--error_48')}
            hoverColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--brand_white')}
            backgroundColor="transparent"
            hoverBackgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--error_80')}
            hoverBorderColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--brand_white')}
            onClick={() => deletePartner()}
          >
            {constants.ORG_REMOVE_PARTNER}
          </PrimaryButton>

          <PrimaryButton
            className="btn"
            type="button"
            width="auto"
            borderWidth="1.5px"
            hoverBorderWidth="1.5px"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--brand_white'
            )}
            borderColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--brand_white')}
            hoverColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--brand_white')}
            backgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_40')}
            hoverBackgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_72')}
            hoverBorderColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--brand_white')}
            onClick={() => {
              setShowRemovePartnerModal(false);
            }}
          >
            {constants.CAMERAS_LIVE_ACTION_BUTTONS_CANCEL_LABEL}
          </PrimaryButton>
        </div>
      </SiteModal>

      <SiteModal
        modalTitle={
          invitationSend
            ? constants.ORG_INVITE_SENT
            : `${constants.ORG_INVITE_A_PARTNER}`
        }
        showModal={invitePartnerModal}
        hideModal={() => (invitationSend ? getPartnerOrg() : hideInviteModal())}
        classes={invitationSend && 'send-invitation-partner remove-org-modal'}
      >
        <div className={`sub-title-modal ${invitationSend && 'sent-invite'}`}>
          {invitationSend
            ? constants.ORG_INVITE_SENT_MESSAGE.replace('$email', emailInput)
            : constants.ORG_INVITE_PARTNER_MESSAGE}
        </div>
        {invitationSend ? (
          <div>
            <div className="org-remove-invite-status">
              <div className="device-claimed-status">
                <div className="claimed-view">
                  <img className="image-tick" src={icontick}></img>
                </div>
              </div>
            </div>
            <PrimaryButton
              className="btn mt-4"
              type="button"
              width="100%"
              borderWidth="1.5px"
              hoverBorderWidth="1.5px"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              borderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_72')}
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              onClick={() => {
                setInvitePartnerModal(false);
                setInvitationSend(false);
                getPartnerOrg();
              }}
            >
              {constants.ORG_BACK_TO_SETTING}
            </PrimaryButton>
          </div>
        ) : (
          <div>
            <div className="textbox role-input-group-text">
              <input
                value={emailInput}
                onChange={(e) => onHandleChange(e)}
                onKeyDown={(e) => onKeyDownChange(e)}
                style={{ width: '100%', marginTop: 10 }}
                className={`form-control require shadow-none${
                  emailInput ? 'has-value' : ''
                } ${emailError && 'email-error'}`}
                placeholder={constants.EMAIL_TABLE_HEADER}
                autoComplete="off"
                beforeinputicon=""
                afterinputicon=""
              />
              {emailInput === '' && (
                <span className="placeholder-wrapper org-invite-partner required is-invalid"></span>
              )}
            </div>
            {emailError && (
              <div className="partner-email-not-verified">
                <PiWarningCircleLight
                  size={25}
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--error_112')}
                />
                <div className="email-error-message">{emailErrorMessage}</div>
              </div>
            )}
            <div className="org-remove-invite-status mt-4">
              <PrimaryButton
                className="btn"
                type="button"
                width="100%"
                borderWidth="1.5px"
                hoverBorderWidth="1.5px"
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--brand_white')}
                borderColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--brand_white')}
                hoverColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--brand_white')}
                backgroundColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--primary_40')}
                hoverBackgroundColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--primary_72')}
                hoverBorderColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--brand_white')}
                disabled={!validateEmail(emailInput)}
                onClick={() => {
                  sendInvite();
                }}
              >
                {constants.ORG_SEND_PARTNER_INVITE}
              </PrimaryButton>
            </div>
          </div>
        )}
      </SiteModal>
    </div>
  );
}

export default OrganizationDetails;
