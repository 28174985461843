import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import RegionSelect from 'react-region-select';
import { useForm } from 'react-hook-form';
import { RiErrorWarningLine } from 'react-icons/ri';
import { RxCross1, RxPlus } from 'react-icons/rx';
import { PrimaryButton, SiteSpinner } from '../../../../components/common';
import { Utils, constants } from '../../../../helpers';
import { useSelector } from 'react-redux';
import {
  getAllMqttDataFromResponse,
  setDeviceInformation,
} from '../../../../store/reducers/AccountReducer';
import { ReactComponent as CrossIcon } from '../../../../assets/images/icons/crossWithRound.svg';
import { ReactComponent as InformationCircle } from '../../../../assets/images/icons/InformationCircle.svg';
import { getCDNInfo, getStreamBitmapData } from '../../../../store/reducers/StreamingReducer';
import publishDeviceSetting, {
  deleteDeviceSetting,
} from '../shared/PublishDeviceSettings';
// import { getAllMqttDataFromResponse } from '../../../../store/AccountStoreIDB';
import InclusionExclusionSnapShot from './InclusionExclusionSnapShot';
import { InputSlider } from '../../../../components/forms';
import { Col, Row } from 'react-bootstrap';

const InclusionZone = ({
  accountId,
  refResolution,
  deviceId,
  deviceInfos,
  devicePublishId,
  uuid,
  capInclusionArea,
  inclusionZonesData,
  hideModelPopup,
}) => {
  const initialValues = {
    zoneName: '',
  };
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: initialValues,
  });
  const dispatch = useDispatch();
  const watchedFields = watch();
  const formRef = useRef();
  const expiredTime = 20;
  const resourceList = Utils.getDeviceSettingResource(7);
  const MAX_REGION_TO_BE_ADDED_COUNT =
    +capInclusionArea?.['md-include-area-index']?.max + 1 || 1;
  const REGION_X_RANGE =
    +refResolution?.[0] || 1;
  const REGION_Y_RANGE =
    +refResolution?.[1] || 1;
  const colors = Utils.getAllColors();
  const [regions, setRegions] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const publishPayload = {
    deviceId: deviceId,
    devicePublishId: devicePublishId,
    uuid: uuid,
    accountId: accountId,
    hubId: deviceInfos.gatewayId,
  };
  const [snapshot, setSnapshot] = useState('');
  const [imageWidth, setImageWidth] = useState(0);
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  // const [allMqttData, setAllMqttData] = useState([]);
  const bitmapObject = useSelector(getStreamBitmapData);
  const [inclusionAreas, setInclusionAreas] = useState(
    inclusionZonesData['include-areas'] || []
  );
  const [isDeleteTriggered, setIsDeleteTriggered] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(1.7);
  const cdnInfo = useSelector(getCDNInfo);
  const capMotion = Utils.getCurrentTabProperty(
    deviceInfos?.capDetails?.capabilities,
    resourceList[1]
  );
  const [motionData,setMotionData] = useState(Utils.getModalData(
    1,
    deviceInfos?.properties,
    capMotion,
    resourceList[1]
  ))
  const [mdIncludeLevel, setMdIncludeLevel] = useState(motionData?.valueLevelDetection);
  const [mdIncludeSensitivity, setMdIncludeSensitivity] = useState(motionData?.valueSensitivity);
  const [mdIncludeMinDur, setMdIncludeMinDur] = useState(motionData?.valueDuration);
  const [unsavedZone, setUnsavedZone] = useState('');
  const [initialZoneName, setInitialZoneName] = useState("");

  
  const setInitaialValue = ()=>{
    setMdIncludeLevel(motionData?.valueLevelDetection);
    setMdIncludeSensitivity(motionData?.valueSensitivity);
    setMdIncludeMinDur(motionData?.valueDuration);
  }

  useEffect(()=>{
    if(isDeleteTriggered){
      setInitaialValue()
    }
  },[isDeleteTriggered])

  useEffect(() => {
    if (inclusionAreas && inclusionAreas?.length > 0) {
      const selctedObjtTypes = inclusionAreas?.find(
        (item) => item?.['md-include-area-index'] == selectedRegion?.index
      );
      if (selctedObjtTypes) {
        const value = selctedObjtTypes?.['md-include-area-level']
          ? parseInt(selctedObjtTypes?.['md-include-area-level'])
          : 0;
        setMdIncludeLevel(value);
        setMdIncludeSensitivity(
          selctedObjtTypes?.['md-include-area-sensitivity']
            ? parseInt(
                selctedObjtTypes?.['md-include-area-sensitivity']
              )
            : 0
        );
        setMdIncludeMinDur(
          selctedObjtTypes?.['md-include-area-min-dur']
            ? parseInt(selctedObjtTypes?.['md-include-area-min-dur'])
            : 0
        );
      } else {
        setInitaialValue()
      }
    }
  }, [selectedRegion]);


  useEffect(() => {
    const resource = allMqttData?.msg?.resource;
    if (resource?.includes(resourceList[7]) && resource?.includes(deviceId)) {
      const properties = allMqttData?.msg?.properties;
      const action = allMqttData?.msg?.action;
      if (action === 'error') {
        setRemainingTime(0);
        setErrorMsg(properties.desc);
      } else if (action === 'is') {
        setRemainingTime(0);
        // Update Device Details
        const localStoreProperty =
          JSON.parse(localStorage.getItem('localStoreProperty')) || {};
        const updatedProperties = {
          ...localStoreProperty,
          'include-area': properties['include-area'],
        };
        localStorage.setItem(
          'localStoreProperty',
          JSON.stringify(updatedProperties)
        );
        dispatch(
          setDeviceInformation({
            ...deviceInfos,
            properties: {
              ...deviceInfos.properties,
              'include-area': properties['include-area'],
            },
          })
        );
        setInclusionAreas(
          JSON.parse(properties['include-area'])['include-areas']
        );
        if (isDeleteTriggered) {
          setIsDeleteTriggered(false);
        } else if (showLoader) {
          hideModelPopup();
        }
      }
    }
  }, [allMqttData]);

  useEffect(() => {
    const updatedRegions = regions.map((reg) => {
      return {
        ...reg,
        data: {
          ...reg.data,
          regionStyle: {
            ...reg.data.regionStyle,
            display:
              selectedRegion.data.regionColor === reg.data.regionColor
                ? 'block'
                : 'none',
          },
        },
      };
    });
    setRegions(updatedRegions);
    document.getElementsByClassName('modal-body')[0].scrollTo(0, 0);
  }, [selectedRegion]);

  useEffect(() => {
    setErrorMsg('');
    const snapshotURL = fetchSnapshotCanvas(`canvas`, `video`);
    setSnapshot(snapshotURL);
  }, []);

  const fetchSnapshotCanvas = (typeCanvas, typeVideo) => {
    let canvas = document.getElementById(typeCanvas);
    let video = document.getElementById(typeVideo);
    const context = canvas?.getContext('2d');
    const bitmapObjectHeight = bitmapObject ? bitmapObject?.StreamHeight : 450;
    const bitmapObjectWidth = bitmapObject ? bitmapObject?.StreamWidth : 764;
    context?.drawImage(video, 0, 0, bitmapObjectWidth, bitmapObjectHeight);
    const data = canvas?.toDataURL('image/png');
    return data;
  };

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (remainingTime > 0) {
        setRemainingTime((prevTime) => prevTime - 1);
      } else {
        setShowLoader(false);
        clearInterval(timerInterval);
      }
    }, 1000);
    return () => {
      clearInterval(timerInterval);
    };
  }, [remainingTime]);

const resetCoords = (regionsdata)=>{
  const regionList = regionsdata?.map((reg) => {
    const r = inclusionAreas?.find((item)=> item?.['md-include-area-index'] == reg?.index)
    if(r){
      const dimensions = r?.["md-include-area-coordinates"] && getZoneDimensions(
        ...r?.['md-include-area-coordinates']?.split(',')
      );
      const color = colors[r?.['md-include-area-index']];
      return {
        x: dimensions?.left,
        y: dimensions?.top,
        width: dimensions?.width,
        height: dimensions?.height,
        index: r?.['md-include-area-index'],
        data: {
          zoneName: r?.['md-include-area-name'],
          motion: 'true',
          regionColor: color,
          regionStyle: {
            border: `2px solid ${color}`,
          },
          x: dimensions?.left,
          y: dimensions?.top,
          width: dimensions?.width,
          height: dimensions?.height,
          ...dimensions,
        },
        isChanging: false,
      };
    }
    else{
      return reg
    }
  });
  if (regionList?.length) {
    setRegions(regionList);
  }
}

  useEffect(() => {
    if (REGION_X_RANGE && REGION_Y_RANGE && imageWidth) {
      setAspectRatio(REGION_X_RANGE / REGION_Y_RANGE);
      const regionList = inclusionAreas?.map((r) => {
       
        const dimensions = r?.["md-include-area-coordinates"] && getZoneDimensions(
          ...r?.['md-include-area-coordinates']?.split(',')
        );
        const color = colors[r?.['md-include-area-index']];
        return {
          x: dimensions?.left,
          y: dimensions?.top,
          width: dimensions?.width,
          height: dimensions?.height,
          index: r?.['md-include-area-index'],
          data: {
            zoneName: r?.['md-include-area-name'],
            motion: 'true',
            regionColor: color,
            regionStyle: {
              border: `2px solid ${color}`,
            },
            x: dimensions?.left,
            y: dimensions?.top,
            width: dimensions?.width,
            height: dimensions?.height,
            ...dimensions,
          },
          isChanging: false,
        };
      });
      if (inclusionAreas?.length === 0) {
        setSelectedRegion(null);
        setRegions([]);
      }
      if (regionList?.length) {
        setRegions(regionList);
        const updatedSelectedRegion = regionList?.find(
          (region) =>
            region.data.regionColor === selectedRegion?.data?.regionColor
        );
        updateRegionAndForm(
          updatedSelectedRegion
            ? updatedSelectedRegion
            : regionList.length > 0
            ? regionList[0]
            : null
        );
      }
    }
  }, [imageWidth, inclusionAreas]);
  
  

  const handleAddZoneClick = () => {
    // If there is any region without name then it should not allow to add new region
    const withoutName = regions.filter(
      (x) => x.data.zoneName === '' || x.data.zoneName === 'Area' || x?.isAreaNotSaved
    );

    if (withoutName.length > 0) {
      setUnsavedZone(watchedFields.zoneName)
      return;
    }
    const occupiedIndices = inclusionAreas.map((x) => x['md-include-area-index']);
    const index = Utils.getMinAvailableIndex(occupiedIndices);
    const color = colors[index];
    const initialRegion = {
      x: 26,
      y: 25,
      width: 44,
      height: 50,
      new: false,
      index: index,
      isAreaNotSaved:true,
      data: {
        ...getZoneCoordinates(26, 25, 44, 50),
        zoneName: 'Area',
        regionColor: color,
        regionStyle: {
          border: `2px solid ${color}`,
        },
        x: 26,
        y: 25,
        width: 44,
        height: 50,
      },
      isChanging: false,
    };
    setRegions([...regions, initialRegion]);
    resetCoords([...regions, initialRegion])
    updateRegionAndForm(initialRegion);
  };

  useEffect(()=>{
    if(regions?.length >0){
      setValue(
        'zoneName',
        getSelectedArea(false)
      );
    }
  },[regions])

  const onRegionChange = (regs) => {
    let updatedRegions = [];
    if (regs.filter((x) => x.isChanging).length === 0) {
      updatedRegions = regs.map((reg) => {
        return {
          ...reg,
          data: {
            ...reg.data,
            ...getZoneCoordinates(reg.x, reg.y, reg.width, reg.height),
            x: reg.x,
            y: reg.y,
            height: reg.height,
            width: reg.width,
          },
        };
      });
    }
    setInitialZoneName('')
    setRegions(updatedRegions.length > 0 ? updatedRegions : regs);
  };

  const getZoneCoordinates = (left, top, width, height) => {
    const divResolutionWidth =
      document.getElementById('img-snapshot')?.offsetWidth;
    const divResolutionHeight =
      document.getElementById('img-snapshot')?.offsetHeight;
    const proportionWidth = parseInt(REGION_X_RANGE - 1) / divResolutionWidth;
    const proportionHeight = parseInt(REGION_Y_RANGE - 1) / divResolutionHeight;

    const updatedLeft = proportionWidth * ((divResolutionWidth / 100) * left);
    const updatedTop = proportionHeight * ((divResolutionHeight / 100) * top);
    const updatedHeight =
      proportionHeight * ((divResolutionHeight / 100) * height);
    const updatedWidth = proportionWidth * ((divResolutionWidth / 100) * width);

    const TL = { x: updatedLeft, y: updatedTop };
    const TR = { x: updatedLeft + updatedWidth, y: updatedTop };
    const BL = { x: updatedLeft, y: updatedTop + updatedHeight };
    const BR = { x: updatedLeft + updatedWidth, y: updatedTop + updatedHeight };

    return {
      TLx: Math.round(TL.x),
      TLy: Math.round(TL.y),
      TRx: Math.round(TR.x),
      TRy: Math.round(TR.y),
      BLx: Math.round(BL.x),
      BLy: Math.round(BL.y),
      BRx: Math.round(BR.x),
      BRy: Math.round(BR.y),
    };
  };

  const getZoneDimensions = (BLx, BLy, BRx, BRy, TRx, TRy, TLx, TLy) => {
    const divResolutionWidth =
      document.getElementById('img-snapshot')?.offsetWidth;
    const divResolutionHeight =
      document.getElementById('img-snapshot')?.offsetHeight;
    const proportionWidth = parseInt(REGION_X_RANGE - 1) / divResolutionWidth;
    const proportionHeight = parseInt(REGION_Y_RANGE - 1) / divResolutionHeight;
    const left = +TLx / proportionWidth / (divResolutionWidth / 100);
    const top = +TLy / proportionHeight / (divResolutionHeight / 100);
    const width = (+BRx - +BLx) / proportionWidth / (divResolutionWidth / 100);
    const height =
      (+TLy - +BLy) / proportionHeight / (divResolutionHeight / 100);

    return {
      left: Math.abs(left),
      top: Math.abs(top),
      width: Math.abs(width),
      height: Math.abs(height),
      TLx,
      TLy,
      TRx,
      TRy,
      BLx,
      BLy,
      BRx,
      BRy,
    };
  };

  const getMsgProperties = (region) => {
    const index = colors.indexOf(region.data.regionColor) || 0;
    const jsonObject = {};
    const keyPrefix = `md-include-area`;
    jsonObject[`${keyPrefix}-index`] = index;
    jsonObject[`${keyPrefix}-index-enable`] = 'true';
    jsonObject[`${keyPrefix}-name`] = region.data.zoneName;
    jsonObject[
      `${keyPrefix}-coordinates`
    ] = `${region.data.BLx},${region.data.BLy},${region.data.BRx},${region.data.BRy},${region.data.TRx},${region.data.TRy},${region.data.TLx},${region.data.TLy}`;
    jsonObject[`${keyPrefix}-md`] = 'true';
    return jsonObject;
  };

  const saveZoneHandler = (values) => {
    setErrorMsg('');
    setShowLoader(true);
    setRemainingTime(expiredTime);
    try {
      const regionsWithUpdatedCoordinates = regions.find(
        (region) =>
          region.data.regionColor === selectedRegion?.data?.regionColor
      );
      const updatedSelectedRegion = {
        ...regionsWithUpdatedCoordinates,
        data: {
          ...regionsWithUpdatedCoordinates.data,
          ...values,
        },
      };
      const msgProperties = getMsgProperties(updatedSelectedRegion);
      const property = {
        [motionData.mindurationKey]: mdIncludeMinDur.toString(),
        [motionData.levelKey]: mdIncludeLevel.toString(),
        [motionData.sensitivityKey]: mdIncludeSensitivity.toString(),
        "md-include-area-enable":"true"
      };
      const updateProperty = {...msgProperties,...property}
      publishDeviceSetting(updateProperty, resourceList[7], publishPayload);
      setSelectedRegion(updatedSelectedRegion);
    } catch (error) {
      Utils.vmsLogger().log('error', error);
      setShowLoader(false);
      setRemainingTime(0);
    }
  };

  const handleZoneClick = (region) => {
    updateRegionAndForm(region);
    if(region?.isAreaNotSaved){
      setInitialZoneName('')
    }
    setUnsavedZone('');
    resetCoords(regions)
  };

  const updateRegionAndForm = (region) => {
    reset();
    const zoneName = region.data.zoneName === 'Area' ? '' : region.data.zoneName;
    setInitialZoneName(zoneName);  // Save the initial zone name when the region is selected
    setValue('zoneName', zoneName);
    setSelectedRegion(region);
  };

  const handleRemoveZone = (e, region) => {
    e.stopPropagation();
    try {
      const currentIndex = colors.indexOf(region.data.regionColor);
      if (
        inclusionAreas.find((q) => q['md-include-area-index'] === currentIndex)
      ) {
        setErrorMsg('');
        setShowLoader(true);
        setRemainingTime(expiredTime);
        const msgProperties = {
          'md-include-area-index': currentIndex,
          'md-include-area-enable':"true"

        };
        deleteDeviceSetting(msgProperties, resourceList[7], publishPayload);
        setIsDeleteTriggered(true);
      } else {
        const updatedRegions = regions.filter(
          (item) => item.data.regionColor !== region.data.regionColor
        );
        setRegions(updatedRegions);
        setSelectedRegion(updatedRegions.length > 0 ? updatedRegions[0] : null);
        updateRegionAndForm(
          updatedRegions.length > 0 ? updatedRegions[0] : null
        );
      }
    } catch (error) {
      Utils.vmsLogger().log('error', error);
      setShowLoader(false);
      setRemainingTime(0);
    }
  };

  const handleErrorMsgClose = () => {
    setErrorMsg('');
  };

  const getSelectedArea = (isRemove) => {
    if(isRemove){
      return regions?.find(
        (region) => region?.data?.regionColor === selectedRegion?.data?.regionColor
      );
    }
        // Check if regions exist
    if (!regions || regions?.length <= 0) {
      return "";
    }
    // Find the selected area based on region color
    const selectedArea = regions?.find(
      (region) => region?.data?.regionColor === selectedRegion?.data?.regionColor
    );
    // Return the appropriate zone name based on conditions
    if (selectedArea) {
      return watchedFields.zoneName ||  `Area ${regions?.length}`;
    } else if (selectedRegion?.data?.zoneName) {
      return selectedRegion.data.zoneName;
    }
    return '';
  };

  const handleChangeZoneName = (e,selectedregion) => {
    setValue('zoneName', e.target.value);
    setRegions((prevRegions) => {
      return prevRegions.map((region) =>
        region?.isAreaNotSaved && region?.index === selectedregion?.index
          ? { ...region, data: { ...region.data, zoneName: e.target.value },isFieldAdded:true }
          : region
      );
    });
  };

  const isAnyFiledUpdated =(initialValue,valueSelected)=>{
    if(parseInt(initialValue) == parseInt(valueSelected)){
      setInitialZoneName(selectedRegion?.data?.zoneName)
    }
    else{
      setInitialZoneName('')
    }
  }

  const isZoneNameChanged = watchedFields.zoneName !== initialZoneName;
  return (
    <div className="component-container">
      <div className={`${showLoader ? 'disable-bg' : ''}`}></div>
      {showLoader && (
        <SiteSpinner
          height="50px"
          width="50px"
          styles={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            bottom: '50%',
            right: '50%',
            zIndex: 15,
          }}
        ></SiteSpinner>
      )}
      <div className="activity-zone-info">
        {constants.INCLUSION_ZONE_POPUP_TITLE_INFO}
      </div>
      <div className="hr-devider"></div>
      {errorMsg && (
        <div className="mb-4 error-conatiner">
          <div className="error-message-container">
            <RiErrorWarningLine className="icon-warning-col" />
            <div className="">{errorMsg}</div>
          </div>
          <RxCross1
            onClick={() => handleErrorMsgClose()}
            className="icon-warning-col"
          />
        </div>
      )}
      {!unsavedZone && inclusionAreas && inclusionAreas.length > 0 ? (
        <></>
      ):(unsavedZone || inclusionAreas?.length > 0) && <div className="mb-4 error-conatiner">
      <div className="error-message-container">
        <InformationCircle className="icon-error-col" />
        <div className="error-headline">
          {constants.YOU_HAVE_UNSAVED_ZONE}
        </div>
      </div>
      <RxCross1
        onClick={() => setUnsavedZone('')}
        className="icon-warning-col"
      />
      <div className='unsaved'>{unsavedZone}</div>
    </div>}
      <div className="area-conatiner mt-3">
        <div className="modal-sub-heading-left">
          {constants.INCLUSION_ZONE_POPUP_CREATE_ZONES}
        </div>
        {regions.length < MAX_REGION_TO_BE_ADDED_COUNT && (
          <PrimaryButton
            className="add-zone-btn mt-3"
            type="button"
            width="112px"
            fontSize="12px"
            lineHeight="16px"
            borderWidth="0"
            height="8px"
            hoverBorderWidth="0"
            fontWeight="600"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--primary_40'
            )}
            hoverColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_40')}
            disabledBackgroundColor="transparent"
            disabledColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--greyscale_72')}
            hoverBackgroundColor="transparent"
            backgroundColor="transparent"
            onClick={handleAddZoneClick}
          >
            <RxPlus className="plus-icon-btn" strokeWidth={'1.5px'} size={16} />
            {constants.ANALYTICS_SETTINGS_POPUP_ADD_ZONE}
          </PrimaryButton>
        )}
      </div>
      {regions?.length > 0 && (
        <div className="activity-zone-info">
          {constants.ANALYTICS_SETTINGS_POPUP_CREATE_ZONES_INFO}
        </div>
      )}
      <div className="regions-container mt-3 mb-3">
        <span className='regions-button-container'>
          {regions.map((region, index) => (
            <PrimaryButton
              key={index}
              className={`region-btn ${
                region.data.regionColor === selectedRegion?.data?.regionColor &&
                'selected-region'
              }`}
              type="button"
              fontSize="12px"
              width="fit-content"
              lineHeight="16px"
              borderWidth="0"
              hoverBorderWidth="0"
              color={getComputedStyle(document.documentElement).getPropertyValue(
                '--primary_40'
              )}
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              hoverBackgroundColor="transparent"
              disabledBackgroundColor="transparent"
              backgroundColor="transparent"
              onClick={() => handleZoneClick(region)}
            >
              <div
                className="circle-dot"
                style={{ backgroundColor: region.data.regionColor }}
              ></div>
              {region.data.regionColor === selectedRegion?.data?.regionColor
                ? watchedFields.zoneName || 'Area'+ (regions?.length)
                : region?.isFieldAdded ? region.data.zoneName : region?.isAreaNotSaved ? `${region.data.zoneName} ${regions?.length}`  : region.data.zoneName}
              {/* <CrossIcon
                className="cross-icon"
                onClick={(e) => handleRemoveZone(e, region)}
              /> */}
            </PrimaryButton>
          ))}
        </span>
      </div>
      <div className="snap-shot-container">
        <div className="live-snapshot">
          {/* <img
            id="img-snapshot"
            src={snapshot}
            alt=""
            className={`live-snapshot-image ${
              aspectRatio === 1 ? 'image-aspect' : ''
            }`}
            onLoad={() => {
              setImageWidth(
                document.getElementById('img-snapshot')?.offsetWidth
              );
            }}
            onError={(event) => {
              event.target.src = nosnapshot;
            }}
          /> */}
          <InclusionExclusionSnapShot
            key={deviceInfos?.deviceId}
            deviceElemId={`camera${deviceInfos?.deviceId}`}
            orgId={deviceInfos?.orgId}
            cdnInfo={cdnInfo}
            locationAreaName={deviceInfos?.locationAreaNames}
            isHighlighted={false}
            showZoomOptions={false}
            conStatus={deviceInfos?.connectionStatus || 'offline'}
            hubId={deviceInfos?.gatewayId}
            displayDeviceStatus={Utils.getDeviceStatus(
              deviceInfos?.deviceStatus,
              deviceInfos?.connectionStatus
            )}
            setImageWidth={setImageWidth}
            aspectRatio={aspectRatio}
            {...deviceInfos}
          />
          <RegionSelect
            id="region-select"
            maxRegions={3}
            regions={regions}
            selectedRegion={selectedRegion}
            regionStyle={{
              outline: 'none',
              zIndex: 70,
            }}
            className={`region-container region${selectedRegion?.index}`}
            onChange={onRegionChange}
            style={{
              border: '0px solid black',
              position: 'absolute',
              height: '-webkit-fill-available',
              display: 'inline-block',
              // top: '0px',
              // left: '0px',
              width: imageWidth,
              // maxWidth: '100%',
              // maxHeight: '100%',
            }}
            constraint={true}
          >
            {regions
              .filter(
                (region) =>
                  region.data.regionColor !== selectedRegion?.data?.regionColor
              )
              .map((region) => (
                <div
                  style={{
                    border: `2px solid ${region.data.regionColor}`,
                    position: 'absolute',
                    height: `${region.data.height}%`,
                    width: `${region.data.width}%`,
                    zIndex: 20,
                    pointerEvents: 'none',
                    left: `${region.data.x}%`,
                    top: `${region.data.y}%`,
                  }}
                ></div>
              ))}
            <div></div>
          </RegionSelect>
        </div>
      </div>
      <form ref={formRef} onSubmit={handleSubmit(saveZoneHandler)}>
        {selectedRegion && (
          <div className="add-zone-container">
            <div className="mb-4 mt-4 zone-input-wrapper">
              <div className="placeholder-wrap">
                <input
                  {...register('zoneName', { required: true, maxLength: 25 })}
                  defaultValue={selectedRegion?.data?.zoneName}
                  className="input-box"
                  autoFocus
                  maxLength={25}
                  onChange={(e)=>handleChangeZoneName(e,selectedRegion)}  // Manually handle input changes

                />
                {!watchedFields.zoneName && (
                  <div className="custom-placeholder">
                    {
                      constants.ANALYTICS_SETTINGS_POPUP_CREATE_ZONES_PLACEHOLDER
                    }
                    <span className="required">*</span>
                  </div>
                )}
              </div>
              {errors.zoneName && (
                <div className="error">
                  {errors.zoneName.type === 'required'
                    ? constants.ANALYTICS_SETTINGS_POPUP_CREATE_ZONES_ERROR
                    : constants.ANALYTICS_SETTINGS_POPUP_CREATE_ZONES_MAXLENGTH_ERROR}
                </div>
              )}
              <div className='line-crossing-count'>{watchedFields.zoneName.length || 0}/25</div>
            </div>
          </div>
        )}{' '}
        <Row className={`settings-block ${regions?.length <= 0 ? 'margin-top-no-zones' : ""} mt-2`}>
          <Col>
            <InputSlider
              label={constants.DEVICE_TAB_LEVEL_OF_DETECTION}
              fieldName="levelDetection"
              maxValue={parseInt(motionData?.maxLevelDetection)}
              minValue={parseInt(motionData?.minLevelDetection)}
              currentValue={parseInt(mdIncludeLevel)}
              isErrorMessage={false}
              changeHandler={(value) => {
                isAnyFiledUpdated(motionData?.valueLevelDetection,value)
                setMdIncludeLevel(value.toString())
                // publishDeviceSetting(
                //   property,
                //   motionData.resource,
                //   publishPayload
                // );
              }}
            />
          </Col>
        </Row>
        <Row className="settings-block">
          <Col>
            <InputSlider
              label={constants.DEVICES_TAB_AUDIO_SETTINGS_SENSITIVITY_LABEL}
              fieldName="sensitivity"
              maxValue={parseInt(motionData?.maxSensitivity)}
              minValue={parseInt(motionData?.minSensitivity)}
              currentValue={parseInt(mdIncludeSensitivity)}
              isErrorMessage={false}
              changeHandler={(value) => {
                isAnyFiledUpdated(motionData?.valueSensitivity,value)
                setMdIncludeSensitivity(value.toString())
                // publishDeviceSetting(
                //   property,
                //   motionData.resource,
                //   publishPayload
                // );
              }}
            />
          </Col>
        </Row>
        <Row className="settings-block">
          <Col>
            <InputSlider
              label={constants.DEVICE_TAB_MINIMUM_DURATION}
              fieldName="mDuration"
              maxValue={parseInt(motionData?.maxDuration)}
              minValue={parseInt(motionData?.minDuration)}
              currentValue={parseInt(mdIncludeMinDur)}
              isErrorMessage={false}
              changeHandler={(value) => {
                isAnyFiledUpdated(motionData?.valueDuration,value)
                setMdIncludeMinDur(value.toString())
                // publishDeviceSetting(
                //   property,
                //   motionData.resource,
                //   publishPayload
                // );
              }}
            />
          </Col>
        </Row>
        <div className={`button-conatiner-excluded-area`}>
          <PrimaryButton
            className="btn btn-primary mt-4"
            type="button"
            width="auto"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--primary_40'
            )}
            backgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--brand_white')}
            disabled={
              !selectedRegion ||
              !watchedFields.zoneName ||
              watchedFields?.zoneName?.length > 25
            }
            onClick={(e) => handleRemoveZone(e, getSelectedArea(true))}
          >
            {constants.DEVICES_TAB_NETWORK_MAXIMUM_DELETE +
              ' ' +
              getSelectedArea(false)}
          </PrimaryButton>
          <PrimaryButton
            className="btn btn-primary mt-4 btn-margin-save"
            type="submit"
            width="20%"
            disabled={
              !selectedRegion ||  // No region selected
              !watchedFields.zoneName ||  // No zone name entered
              watchedFields?.zoneName?.length > 25 ||  // Name exceeds max length
              !isZoneNameChanged 
            }
          >
            {constants.ANALYTICS_SETTINGS_POPUP_SAVE}
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};

export default InclusionZone;
