import { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import nosnapshot from '../../assets/images/nosnapshot.svg';
import { Utils } from '../../helpers';

import 'react-lazy-load-image-component/src/effects/blur.css';

const ReImageFetch = ({
  customClass,
  time,
  cdnValue,
  deviceId,
  cacheName,
  scrollPosition,
}) => {
  const [newSrc, setNewSrc] = useState('');

  useEffect(() => {
    const newTime = Utils.getUnixDate(Utils.getDate(time / 1000));
    const cacheKey = Utils.generateCacheKey(time);
    let cache, cachedResponse;

    const isImageCached = async () => {
      let response = false;
      try {
        cache = await caches.open(cacheName);
        response = await cache.match(cacheKey);
      } catch (error) {
        Utils.vmsLogger().error(error);
      } finally {
        return response;
      }
    };

    const getCachedImage = async (response) => {
      // Fetch the image from cache
      const blob = await response.blob();

      return URL.createObjectURL(blob);
    };

    const setImageSource = async () => {
      try {
        let eventSnapshot;

        if (deviceId && newTime && cdnValue?.protocol && cdnValue?.host) {
          cachedResponse = await isImageCached();
          if (cachedResponse) {
            // Fetch the image from cache
            eventSnapshot = await getCachedImage(cachedResponse);
            setNewSrc(eventSnapshot);
          } else {
            const bucket = (cdnValue?.bucket).replace('${deviceId}', deviceId);
            const date = Utils.fetchDateInUnix(newTime);
            let responseClone;

            fetch(
              `${cdnValue?.protocol}://${cdnValue?.host}/${bucket}/${date}/${newTime}.jpg`,
              {
                credentials: 'include',
              }
            )
              .then((response) => {
                responseClone = response.clone();
                // Save to Events Cache
                cache && cache.put(cacheKey, responseClone);
                return response.blob();
              })
              .then((blob) => {
                setNewSrc(URL.createObjectURL(blob));
              });
          }
        }
      } catch (error) {
        Utils.vmsLogger().error(error);
      }
    };

    try {
      setImageSource();
    } catch (error) {
      Utils.vmsLogger().error(error);
    }
  }, [time, deviceId]);

  return (
    <div className={`image-wrapper ${customClass ? customClass : ''}`}>
      <>
        {newSrc ? (
          <LazyLoadImage
            className="event-list-item"
            alt="event snapshot"
            src={newSrc ? newSrc : nosnapshot}
            scrollPosition={scrollPosition}
            onLoad={(event) => {
              event.target.style.display = 'inline-block';
            }}
            onError={(event) => (event.target.src = nosnapshot)}
          />
        ) : null}
      </>
    </div>
  );
};

export default ReImageFetch;
