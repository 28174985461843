import { useNavigate } from 'react-router-dom';
import { useTable, useSortBy } from 'react-table';
import { ReactComponent as ArrowDown } from '../../../assets/images/icons/ArrowDown.svg';
import { ReactComponent as ArrowUp } from '../../../assets/images/icons/ArrowUp.svg';
import { Utils, constants } from '../../../helpers/';
import './camAvailableListTable.scss';

const CamAvailableListTable = ({ columns, data, noHover}) => {
  // Use the useTable Hook to send the columns and data to build the table
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

  /*
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */

  return (
    <div className="table-responsive">
      {Utils.vmsLogger().log('headergroups--------', headerGroups)}
      <table className="table table-borderless" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} >
              {headerGroup.headers.map((column) => (
                <th
                  className={`${
                    column.isSorted
                      ? column.isSortedDesc
                        ? 'columnheader-asc'
                        : 'columnheader-desc'
                      : ''
                  }`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ArrowDown />
                      ) : (
                        <ArrowUp />
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows?.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className={noHover && "user-login"}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      style={{
                        color:
                          index === 3
                            ? getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--success_48')
                            : getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--greyscale_08'),
                      }}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          {rows?.length === 0 && (
            <tr className="no-data-found">
              <td colSpan={columns?.length}>
                {constants.NO_RECORDS_FOUND_TITLE}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CamAvailableListTable;
