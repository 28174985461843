import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { HiOutlineVideoCamera } from 'react-icons/hi2';
import { FiSettings } from 'react-icons/fi';
import moment from 'moment';
import { WiTime4 } from 'react-icons/wi';
import './LiveGridStructure.scss';
import {
  clearWSSConnections,
  getCDNInfo,
  getMQTTConnection,
  getMultiLiveStreamLoader,
  getPlatformInfo,
  getRecievedOffers,
  getRemoteStreamAudio,
  getRemoteStreams,
  getWSSConnections,
  removeMQTTPeerConnections,
  removeRemoteStreams,
  removeRemoteStreamsAudio,
  setIsReloadedStream,
  setMultiLiveStreamLoader,
  setRecievedOffers,
  setRemoteStreams,
  setRemoteStreamsAudio,
  setWSSConnection,
} from '../../../store/reducers/StreamingReducer';
import {
  disconnectWithMQTT,
  publishWithMQTTs,
} from '../../../utils/connection/mqttConnection';
import {
  getAccountId,
  setDeviceInformation,
} from '../../../store/reducers/AccountReducer';
import { ReactComponent as ThreeDotIcon } from '../../../assets/images/VerticalThreeDots.svg';
import videoProcessing from '../../../assets/images/cameras/FootageThumb.svg';
import OfflineCamera from './OfflineCamera';
import { constants, EntitlementsTypeEnum, Utils } from '../../../helpers';
import LoadingCamera from './LoadingCamera';
import NoContentScreen from './NoContentScreen';
import { disconnectWithWebSocket } from '../../multilive/components/playback/wssConnection/wssConnection';
import { usePoliciesStore } from '../../../store/policiesStore';
import { useDeviceSnapshots } from '../../../store/DeviceSnapshotsStore';
import NoLicenseData from './NoLicenseData';

const LiveGridItem = ({
  layout,
  deviceId,
  device,
  hubId,
  activeTime,
  timeZone,
  uniqueId,
  entitleData,
  metadataByDeviceId
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const remoteVideoRef = useRef();
  // const remoteAudioRef = useRef();
  const cdnInfo = useSelector(getCDNInfo);
  const remoteStream = useSelector(getRemoteStreams)?.[deviceId];
  // const remoteStreamAudio = useSelector(getRemoteStreamAudio)?.[deviceId];
  const mqttConnection = useSelector(getMQTTConnection);
  const accountId = useSelector(getAccountId);
  const platformDetails = useSelector(getPlatformInfo);
  const recievedOffers = useSelector(getRecievedOffers);
  const [loading, setLoading] = useState(false);
  const intervalRef = useRef();
  const tryAgainIntervalRef = useRef();
  const hideLoaderRef = useRef(null);
  const wssConnections = useSelector(getWSSConnections);
  const { getLoggedInUserPolicies } = usePoliciesStore();
  const userPolicies = getLoggedInUserPolicies();
  const [currentDevice, setCurrentDevice] = useState(device);
  const loaderData = useSelector(getMultiLiveStreamLoader);
  const imageRef = useRef();
  const [liveSnapshot, setLiveSnapshot] = useState();
  const deviceSnapshots = useDeviceSnapshots((state) => state.deviceSnapshots);
  const [isLicenseExpire, setIsLicenseExpire] = useState(true);
  const [showTryAgain, setShowTryAgain] = useState(false);
  const [metaEnd, setMetaEnd] = useState();
  const { setDeviceSnapshots } = useDeviceSnapshots();

  useEffect(() => {
    if (recievedOffers?.[deviceId]) {
      clearInterval(intervalRef?.current);
      clearInterval(tryAgainIntervalRef?.current);
    }
  }, [recievedOffers]);

  useEffect(() => {
    console.log("LiveStream view rendered for ", deviceId, '-', getTimesinmili())
    getSnapShotImageFromCache();
    return () => {
      clearInterval(intervalRef?.current);
      clearInterval(tryAgainIntervalRef?.current);
    };
  }, []);

  useEffect(() => {
    if (entitleData && Object.keys(entitleData).length > 0) {
      const checkLicense = entitleData?.[deviceId]?.find(
        (entitle) =>
          entitle.type === EntitlementsTypeEnum.LIVE_VIEW &&
          entitle.expiry < Utils.getUnixDate(new Date()) * 1000
      );
      if (checkLicense) {
        setIsLicenseExpire(true);
        setTimeout(() => {
          const loaderObj = {
            deviceId,
            isLoading: false
          };
          dispatch(setMultiLiveStreamLoader(loaderObj));
        }, 1500);
      } else {
        setIsLicenseExpire(false);
      }
    } else {
      setIsLicenseExpire(true);
      setTimeout(() => {
        if (entitleData && Object.keys(entitleData).length === 0) {
          const loaderObj = {
            deviceId,
            isLoading: false
          };
          dispatch(setMultiLiveStreamLoader(loaderObj));
        }
      }, 1500);
    }
  }, [JSON.stringify(entitleData), uniqueId]);

  useEffect(() => {
    if(metadataByDeviceId) {
      const findMeta = metadataByDeviceId.find((meta) => meta?.deviceId === deviceId);
      if (findMeta) {
        setMetaEnd(findMeta?.metadata?.[findMeta?.metadata?.length - 1]?.end);
      }
    };
  }, [metadataByDeviceId]);

  useEffect(() => {
    if(metaEnd) {
      getSnapshotImageForLastMetaEnd();
    }
  }, [metaEnd])

  const getSnapShotImageFromCache = () => {
    if(deviceSnapshots?.[deviceId]) {
      setLiveSnapshot(deviceSnapshots?.[deviceId])
    }
  }
  const getSnapshotImageForLastMetaEnd = () => {
    const lastMetaEndTime = Utils.getUnixDate(moment(metaEnd).toDate());
    const bucket = (cdnInfo?.bucket).replace('${deviceId}', deviceId);
    const date = Utils.fetchDateInUnix(lastMetaEndTime);
    if (deviceId) {
      fetch(
        `${cdnInfo?.protocol}://${cdnInfo?.host}/${bucket}/${date}/${lastMetaEndTime}.jpg`,
        {
          credentials: 'include',
        }
      ).then((response) => response.blob()).then((blob) => {
        setLiveSnapshot(URL.createObjectURL(blob));
        setDeviceSnapshots(deviceId, `${cdnInfo.protocol}://${cdnInfo.host}/${bucket}/${date}/${lastMetaEndTime}.jpg`)
      })
        .catch(() => {
          Utils.vmsLogger().log('Error got', deviceId);
        });
    }
  }

  const getCapabiltiesForDevice = async (deviceData, signal) => {
    if (deviceData?.capability) {
      try {
        const response = await fetch(deviceData?.capability?.url, {
          signal: signal,
        });
        if (response.status === 200) {
          const responseJson = await response.json();
          setCurrentDevice({ ...deviceData, capDetails: responseJson });
        } else {
          setCurrentDevice(deviceData);
        }
      } catch (error) {
        setCurrentDevice(deviceData);
      }
    } else {
      setCurrentDevice(deviceData);
    }
  };

  useEffect(() => {
    if(loaderData && Object.keys(loaderData)?.length > 0) {
      setLoading(loaderData?.[deviceId]);
    }
  }, [JSON.stringify(loaderData)]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getCapabiltiesForDevice(device, signal);

    return () => {
      controller.abort();
    };
  }, [JSON.stringify(device)]);

  useEffect(() => {
    if (
      currentDevice?.displayDeviceStatus !==
        constants.DEVICES_RETURN_OFFLINE_STATUS &&
      currentDevice?.displayDeviceStatus !==
        constants.DEVICES_RETURN_DEACTIVATED_STATUS
    ) {
      callTimeoutLoader();
    }
    return () => {
      clearTimeout(hideLoaderRef.current);
    };
  }, [uniqueId]);

  useEffect(() => {
    if (device?.displayDeviceStatus ===
      constants.DEVICES_RETURN_OFFLINE_STATUS ||
      device?.displayDeviceStatus ===
      constants.DEVICES_RETURN_DEACTIVATED_STATUS) {
      const loaderObj = {
        deviceId,
        isLoading: false
      };
      dispatch(setMultiLiveStreamLoader(loaderObj));
    }
  }, [JSON.stringify(device)]);

  useEffect(() => {
    dispatch(setRemoteStreams({ id: deviceId, stream: null }));
    dispatch(setRemoteStreamsAudio({ id: deviceId, audio: null }));
    const deviceDetails = {
      deviceId: deviceId,
      gatewayId: hubId,
    };
    if (mqttConnection) {
      if (
        !recievedOffers?.[deviceId] && 
        !isLicenseExpire && 
        currentDevice?.displayDeviceStatus?.toLowerCase() ===
          constants.DEVICES_ONLINE_CONNECTION_STATUS
      ) {
        publishWithMQTTs(
          platformDetails.mqtt,
          platformDetails.p2p_server,
          deviceDetails,
          accountId,
          currentDevice?.deviceName
        );

        const id = setInterval(() => {
          publishWithMQTTs(
            platformDetails.mqtt,
            platformDetails.p2p_server,
            deviceDetails,
            accountId
          );
        }, 10000);
        intervalRef.current = id;
      }
    }
    return () => {
      dispatch(removeRemoteStreams(deviceId));
      dispatch(removeRemoteStreamsAudio(deviceId));
      dispatch(removeMQTTPeerConnections(deviceId));
    };
  }, [mqttConnection, uniqueId, isLicenseExpire]);

  const getTimesinmili = () => {
    const time = new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() + ':' + new Date().getMilliseconds();
    return time;
  }

  // useEffect(() => {
  //   const handleFirstFrame = () => {
  //     Utils.vmsLogger().log('LiveStream: First frame render', deviceId, '-', getTimesinmili());
  //   }
  //   if (remoteStream?.active && !loading) {
  //     const remoteVideo = remoteVideoRef.current;
  //     if (remoteVideo) {
  //       remoteVideo.srcObject = remoteStream;
  //       remoteVideoRef?.current?.addEventListener('canplay', handleFirstFrame);
  //       clearTimeout(hideLoaderRef.current);
  //     }
  //   }

  //   return () => {
  //     remoteVideoRef?.current?.removeEventListener('canplay', handleFirstFrame);
  //   }
  // }, [remoteStream, loading]);

  // useEffect(() => {
  //   try {
  //     if (remoteStreamAudio) {
  //       const remoteAudio = remoteAudioRef.current;
  //       if (remoteAudio) remoteAudio.srcObject = remoteStreamAudio;
  //     }
  //   } catch (error) {}
  // }, [remoteStreamAudio]);

  const callTimeoutLoader = () => {
    setLoading(true);
    clearTimeout(hideLoaderRef.current);
    hideLoaderRef.current = setTimeout(() => {
      const loaderObj = {
        deviceId,
        isLoading: false
      };
      dispatch(setMultiLiveStreamLoader(loaderObj));
      setShowTryAgain(true);
    }, 60000);
  };

  const OnClickCameraDetails = () => {
    Utils.vmsLogger().log("Livestream OnClickCameraDetails start", Utils.getTimesinmili());
    // disconnectWithMQTT();
    if (wssConnections) {
      Object.keys(wssConnections).forEach((key) => {
        dispatch(removeRemoteStreams(key));
        dispatch(removeRemoteStreamsAudio(key));
        dispatch(removeMQTTPeerConnections(key));
        disconnectWithWebSocket(key);
      });
    }
    dispatch(clearWSSConnections());
    dispatch(setIsReloadedStream(false));
    dispatch(setWSSConnection(false));
    dispatch(setIsReloadedStream(false));
    dispatch(setDeviceInformation(currentDevice));
    navigate(`/cameras/dashboard.html`, {
      state: {
        id: deviceId,
        cdnInfo: cdnInfo ? cdnInfo : {},
      },
    });
    Utils.vmsLogger().log("Livestream OnClickCameraDetails end", Utils.getTimesinmili());
  };

  const handleDoubleClick = () => {
    Utils.vmsLogger().log("Livestream handle double clicked", Utils.getTimesinmili());
    OnClickCameraDetails();
  };

  const OnClickCameraSettings = () => {
    dispatch(setDeviceInformation(currentDevice));
    deviceId && navigate(`/devices/dashboard.html?deviceId=${deviceId}`);
  };

  const handleTryAgain = () => {
    dispatch(setRecievedOffers({ id: deviceId, value: false }));
    if (tryAgainIntervalRef?.current) {
      clearInterval(tryAgainIntervalRef?.current);
    }
    callTimeoutLoader();
    const deviceDetails = {
      deviceId: deviceId,
      gatewayId: hubId,
    };
    if (mqttConnection) {
      if (
        // !recievedOffers?.[deviceId] &&
        currentDevice?.displayDeviceStatus?.toLowerCase() ===
        constants.DEVICES_ONLINE_CONNECTION_STATUS
      ) {
        const loaderObj = {
          deviceId,
          isLoading: true
        };
        dispatch(setMultiLiveStreamLoader(loaderObj));
        publishWithMQTTs(
          platformDetails.mqtt,
          platformDetails.p2p_server,
          deviceDetails,
          accountId
        );
        const id = setInterval(() => {
          publishWithMQTTs(
            platformDetails.mqtt,
            platformDetails.p2p_server,
            deviceDetails,
            accountId
          );
        }, 10000);
        tryAgainIntervalRef.current = id;
      }
    }
  };

  return (
    <>
      {loading && 
        <div className='live-grid-snapshot-div'>
          <img
            ref={imageRef}
            id="img-snapshot"
            src={liveSnapshot}
            alt=""
            className="live-img-snapshot"
            onError={(e) => e.target.style.display = 'none'}
          />
          <LoadingCamera />
        </div>
      }
      {(device?.displayDeviceStatus ===
        constants.DEVICES_RETURN_OFFLINE_STATUS ||
        device?.displayDeviceStatus ===
          constants.DEVICES_RETURN_DEACTIVATED_STATUS) ? (
        <OfflineCamera
          deviceId={deviceId}
          device={currentDevice}
          activeTime={activeTime}
          timeZone={timeZone}
          onRefresh={handleTryAgain}
        />
      ) : !loading && isLicenseExpire ? (
            <NoLicenseData 
              deviceId={deviceId}
              device={currentDevice}
              activeTime={activeTime}
              timeZone={timeZone}
              onRefresh={handleTryAgain}
            />
      ) : (
        <>
          {/* {remoteStream?.active && !loading ? ( */}
            <>
            {remoteStream?.active ?
              <div
                className={`device-overlay hovered`}
                onDoubleClick={() => handleDoubleClick()}
              >
                <div className="device-title-container">
                  <div className="device-name">{currentDevice?.deviceName}</div>
                  <div className="device-location">
                    {currentDevice?.locationName} • {currentDevice?.areaName}
                  </div>
                </div>
                <div className="date-time-wrapper">
                  <div className="date-time">
                    <WiTime4 size={14} />
                    {moment
                      .tz(moment(activeTime), timeZone)
                      .format('hh:mm:ss A z')}
                  </div>
                </div>
                <div className="menu-icon">
                  <NavDropdown
                    className="devices-dropdown"
                    title={<ThreeDotIcon />}
                  >
                    <NavDropdown.Item
                      className="devices-dropdown-options"
                      onClick={() => OnClickCameraDetails()}
                    >
                      <HiOutlineVideoCamera size={20} />
                      <span className="devices-dropdown-options-label">
                        {constants.CAMERAS_VIDEO_CAMERA_DETAILS_LABEL}
                      </span>
                    </NavDropdown.Item>
                    {userPolicies.view_device_settings && (
                      <NavDropdown.Item
                        className="devices-dropdown-options"
                        onClick={() => OnClickCameraSettings()}
                      >
                        <FiSettings size={20} />
                        <span className="devices-dropdown-options-label">
                          {constants.CAMERAS_VIDEO_SETTINGS_LABEL}
                        </span>
                      </NavDropdown.Item>
                    )}
                  </NavDropdown>
                </div>
              </div>
            : null}
              <div className="primary-circle"></div>
              <div className="streaming-container">
                <video
                  id={`video${layout}${layout}`}
                  width="auto"
                  height="100%"
                  data-device-id={deviceId}
                  // ref={remoteVideoRef}
                  autoPlay={true}
                  playsInline={true}
                  muted={true}
                />
                <audio
                  id={`audio${layout}${layout}`}
                  // ref={remoteAudioRef}
                  autoPlay={true}
                  playsInline={true}
                  controls={false}
                  muted={true}
                />
                <canvas
                  id={`canvas${layout}${layout}`}
                  width="764"
                  height="450"
                  className="d-none"
                />
              </div>
              <img
                className="hide-image"
                src={videoProcessing}
                alt="video processing"
              />
            </>
          {/* // ) : ( */}
            <>
              {!loading && !remoteStream?.active && showTryAgain &&
                <NoContentScreen
                  deviceId={deviceId}
                  device={device}
                  activeTime={activeTime}
                  timeZone={timeZone}
                  onTryAgain={handleTryAgain}
                />
              }
            </>
          {/* // )} */}
        </>
      )}
    </>
  );
};

export default LiveGridItem;
