import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { constants, Utils } from '../../../helpers';
import { useLoggedInUserData } from '../../../store/LoggedInAccountStore';
import { devicesMQTTStore } from '../../../store/DevicesMQTTStore';
import {
  mqttPublish,
  mqttSubscribe,
} from '../../../utils/connection/mqttConnection';

import { FiCameraOff } from 'react-icons/fi';
import FakeSnapshot from '../../../assets/images/cameras/snapshots/fake-snapshot.png';
import './LatestSnapshot.scss';
import { DefaultDevice } from '../../../assets/images';
import _ from 'lodash';

const LatestSnapshot = ({
  orgId,
  cdnInfo,
  deviceId,
  deviceElemId,
  deviceName,
  locationAreaName = '',
  imageURL = FakeSnapshot,
  currentLayoutStatus = false,
  clickHandler = null,
  conStatus,
  hubId,
  displayDeviceStatus,
  isScrolled,
  scrollPosition,
  ...props
}) => {
  const currentTime = Math.round(new Date().getTime() / 1000);
  const [appTopic, setAppTopic] = useState(`a/notify/${hubId}`);
  const [latestSnapshots, setLatestSnapshots] = useState({});
  const [snapshotURL, setSnapshotURL] = useState(imageURL);

  const { getState, subscribe, unsubscribe } = devicesMQTTStore;
  const state = getState();
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );

  const accountId = loggedInUserData.accountId;
  const rowElement = document.getElementById(deviceId);

  useEffect(() => {
    if (state.getAccountId() !== accountId) {
      state.setAccountId(accountId);
    }

    if (!state.getSessionId()) {
      state.setSessionId(uuidv4());
    }

    subscribe((latestSnapshots) => setLatestSnapshots(latestSnapshots));

    return () => {
      Utils.vmsLogger().log('LatestSnapshot unmounted...');
    };
  }, []);

  useEffect(() => {
    const lastSnapshot = state.latestSnapshots[deviceId]
      ? state.latestSnapshots[deviceId]
      : 0;
    const lastEvent = state.latestEvents[deviceId]
      ? state.latestEvents[deviceId]
      : 0;
    let deviceSnapshot;

    if (lastSnapshot && currentTime - lastEvent > 300) {
      deviceSnapshot = lastSnapshot;
    } else if (lastEvent) {
      deviceSnapshot = lastEvent;
    }

    if (deviceSnapshot) {
      fetchLatestSnapshot(deviceSnapshot);
    }
  }, [JSON.stringify(latestSnapshots)]);

  const fetchLatestSnapshot = useCallback(async (lastSnapshot) => {
    if (!lastSnapshot || !cdnInfo) {
      return;
    }

    let bucket = cdnInfo?.bucket?.replace('${deviceId}', deviceId);

    const snapshotImage = new Image();

    // Assign the valid URL to the snapshot URL state
    // variable
    snapshotImage.onload = () => {
      setSnapshotURL(snapshotImage.src);
    };

    // Handle the image loading error (e.g. 404 or 403 error)
    snapshotImage.onerror = () => {
      setSnapshotURL(imageURL);
    };

    const date = Utils.fetchDateInUnix(lastSnapshot);
    snapshotImage.src = `${cdnInfo.protocol}://${cdnInfo.host}/${bucket}/${date}/${lastSnapshot}.jpg`;
  }, []);

  const publishSnapshotRequest = useCallback(() => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const sessionId = state.getSessionId();

    if (!accountId) {
      return;
    }

    // Send the request
    const context = {
      topic: appTopic,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${hubId}`,
        from: `${accountId}`,
        msg: {
          action: 'get',
          resource: `ch/${deviceId}/camera/last-snap-timestamp`,
          publish: `d/notify/${accountId}/${sessionId}`,
        },
      }),
      qos: 0,
    };
    if (deviceId) {
      mqttPublish(context);
    }
  }, []);

  const elementIsVisibleInViewport = () => {
    if (rowElement) {
      const { top, bottom } = rowElement?.getBoundingClientRect();
      const { innerHeight } = window;
      const isVisisble =
        (top > 50 && top < innerHeight) || (bottom > 0 && bottom < innerHeight);
      return isVisisble;
    } else {
      return false;
    }
  };
  const updateScrollValue = () => {
    if (
      elementIsVisibleInViewport() &&
      displayDeviceStatus === constants.DEVICES_RETURN_ONLINE_STATUS
    ) {
      publishSnapshotRequest();
    }
  };

  const debounceOnScroll = _.debounce(updateScrollValue, 500);

  useEffect(() => {
    debounceOnScroll();
  }, [isScrolled, JSON.stringify(rowElement?.getBoundingClientRect())]);

  return (
    <div key={deviceId} id={deviceElemId} className={`cam cam-1x`}>
      <div className="cam-content">
        {conStatus !== 'offline' ? (
          <div className={`cam-snapshot`}>
            <picture className="cam-snapshot-wrapper">
              <LazyLoadImage
                src={snapshotURL}
                className={`cam-snapshot-image`}
                alt={`Snapshot of ${deviceName}`}
                scrollPosition={scrollPosition}
                onError={(e) => (e.target.src = `${DefaultDevice}`)}
              />
            </picture>
          </div>
        ) : (
          <div className={`cam-offline`}>
            <div className={`cam-offline-content`}>
              <div className="camera-offline-icon">
                <FiCameraOff
                  stroke={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--greyscale_56')}
                  size={22}
                />
              </div>
              <div className="camera-offline-label">
                {constants.CAMERAS_VIDEO_WALL_CAMERA_OFFLINE_TITLE}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LatestSnapshot;
